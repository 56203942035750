import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { isNumber, KeyCode } from "src/common";

type AllProps = {
  position: number;
  autoFocus: boolean;
  input_id: string;
  value: string;
  handleAction: (code: string, position: number, new_position: number) => void;
}

function VerificationInput(props: AllProps) {
  const { value, input_id, position, autoFocus, handleAction } = props;
  const [_value, setValue] = useState(value);

  // Description: Handle on blur: validation, state change and propagation up
  function handleOnChange(e: React.FocusEvent<HTMLInputElement>) {
    const code = e.currentTarget.value.trim();
    if (isNumber(code)) {
      setValue(code);
      handleAction(code, position, position);
    }
  }

  function handleOnKeyEvent(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === KeyCode.Backspace) {
      setValue("");
      const new_position = !!e.currentTarget.value ? position - 1 : position - 2;
      handleAction("", position, new_position < 0 ? 0 : new_position);
      e.preventDefault(); // Prevent handleOnChange for backspaces
      e.stopPropagation();
    }
  }

  return <Form.Control
    id={`${input_id}_tb`}
    size="lg"
    data-testid={input_id}
    autoComplete="off"
    autoFocus={autoFocus}
    name={input_id}
    tabIndex={position}
    maxLength={1}
    required
    value={_value}
    onChange={handleOnChange}
    onKeyDown={handleOnKeyEvent} />;
}

export default VerificationInput;
