import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes, RegexHelper, findDuplicateAddress } from "src/common";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

type AllProps = {
  users_list: string[];
  handleActions: ActionHandlerFunction;
  org_users: any; // TODO: Fix this type.
}

// TODO: WIP for custom error messages.
const messages_for_existing_users = {
  default: "The email address already exists.",
  pending: "This user exists and is pending acceptance."
};

function AddSingleUserForm({ users_list, handleActions, org_users }: AllProps) {
  const invalidEmailMessage = "The email address is not in the correct format.";
  const userIdAlreadyExistsMessage = `${messages_for_existing_users.default}`;

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup
        .string()
        .required("The email address is required.")
        .test("user-email", invalidEmailMessage, (value) => {
          if (value) {
            return RegexHelper.testEmailAddress(value);
          }
          return !!value;
        })
        .test("user-email", userIdAlreadyExistsMessage, (value: any) => {
          if (value) {
            const _value = value.toLowerCase();
            return !findDuplicateAddress(users_list, [_value]);
          }
          return !!value;
        }),
      firstName: Yup
        .string()
        .required("The first name is required."),
      lastName: Yup
        .string()
        .required("The last name is required."),
      department: Yup
        .string()
        .required("The department is required.")
    }),
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      department: "",
    },
    onSubmit: () => { } // required for useFormik hook, but we are not using onSubmit.
  });

  function handleReset() {
    formik.resetForm();
  }

  function handleSubmit() {
    handleActions({ actionType: AdminUIActionTypes.SubmitUserData, params: formik.values });
    formik.resetForm();
  }
  return (
    <Form noValidate className="m-2">
      <Row>
        <Col xs="6">
          <Form.Group>
            <Form.Label htmlFor="email_tb">Email Address</Form.Label>
            <Form.Control
              id="email_tb"
              type="text"
              name="user-email"
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              value={formik.values.email}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
            {formik.touched.email && !!formik.errors.email ? (
              <p className="error-message">{formik.errors.email}</p>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="6">
          <Form.Group>
            <Form.Label htmlFor="first_name_tb">First Name</Form.Label>
            <Form.Control
              id="first_name_tb"
              type="text"
              name="first-name"
              onChange={formik.handleChange("firstName")}
              onBlur={formik.handleBlur("firstName")}
              value={formik.values.firstName}
              isInvalid={formik.touched.firstName && !!formik.errors.firstName}
            />
            {formik.touched.firstName && !!formik.errors.firstName ? (
              <p className="error-message">{formik.errors.firstName}</p>
            ) : null}
          </Form.Group>
        </Col>
        <Col xs="6">
          <Form.Group>
            <Form.Label htmlFor="last_name_tb">Last Name</Form.Label>
            <Form.Control
              id="last_name_tb"
              type="text"
              name="last-name"
              onChange={formik.handleChange("lastName")}
              onBlur={formik.handleBlur("lastName")}
              value={formik.values.lastName}
              isInvalid={formik.touched.lastName && !!formik.errors.lastName}
            />
            {formik.touched.lastName && !!formik.errors.lastName ? (
              <p className="error-message">{formik.errors.lastName}</p>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="6">
          <Form.Group>
            <Form.Label htmlFor="department_tb">Department</Form.Label>
            <Form.Control
              id="department_tb"
              type="text"
              name="user-department"
              onChange={formik.handleChange("department")}
              onBlur={formik.handleBlur("department")}
              value={formik.values.department}
              isInvalid={formik.touched.department && !!formik.errors.department}
            />
            {formik.touched.department && !!formik.errors.department ? (
              <p className="error-message">{formik.errors.department}</p>
            ) : null}
          </Form.Group>
        </Col>
      </Row>
      <footer className="mt-4">
        <Button
          id="create_account"
          disabled={!formik.isValid || Object.keys(formik.touched).length === 0}
          onClick={handleSubmit}
        >
          Create Account
        </Button>
        <Button
          id="reset_form"
          variant="no-outline-primary" disabled={!formik.touched} onClick={handleReset}>Reset</Button>
        <Button
          id="cancel_form"
          onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}
          variant="no-outline-primary"
        >
          Cancel
        </Button>
      </footer>
    </Form>
  );
}

export default React.memo(AddSingleUserForm);