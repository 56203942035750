import React, { useState, useEffect, useRef, FocusEvent, KeyboardEvent, useCallback, MouseEvent } from "react";
import { Dropdown, Form } from "react-bootstrap";
import { Country, KeyCode } from "src/common";
import { Icon } from "src/components";
import _ from "lodash";

type AllProps = {
  default_country: Country;
  options: Country[];
  handleAction: (country: Country) => void;
  disabled?: boolean;
}

function SelectCountryComponent(props: AllProps) {
  const { options, default_country, handleAction, disabled } = props;
  const input_element_ref = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<Country | undefined>(default_country);
  const [value, setValue] = useState<string>("");
  const [masked, setMaskedState] = useState<boolean>(true);
  const [filtered, setFiltered] = useState<Country[]>(options);
  // NOTE: required to reset mask on body or outside click
  const masked_ref = useRef<boolean>(masked);
  function setMasked(_masked: boolean) {
    setMaskedState(_masked);
    masked_ref.current = _masked;
  }

  useEffect(() => {
    !!selected && handleAction(selected);
  }, [selected]);

  // Description: Search Options for the term
  const onSearchHandler = useCallback(
    _.debounce((_searchTerm: string, submit = false) => {
      const _filtered = _.filter(options, (country: Country) => (country.country_abb.toLowerCase()).includes(_searchTerm.toLowerCase()));
      setFiltered(_filtered);
      if (submit && _filtered.length === 1) {
        handleSubmit(_filtered[0]);
        document.body.click();
      };
    }, 100), []);

  // Description: Add a mask to the Input
  function Mask() {
    return (!!selected) ?
      <span className="country-mask">
        <Icon className={`cicon-${selected.country_abb.toLowerCase()}`} />
        <span>{selected.country_abb}</span>
      </span> : <></>;
  }

  // Description: Set the current state for controlled form and propagate to store state
  function handleChange(e: FocusEvent<HTMLInputElement>) {
    const _value = e.currentTarget.value.trim();
    setValue(_value);
    onSearchHandler(_value);
  }

  // Description: Handle on enter and tab - do search
  function handleOnKeyEvent(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === KeyCode.Enter || e.key === KeyCode.Tab) {
      const _value = e.currentTarget.value.trim();
      setValue(_value);
      onSearchHandler(_value, true);
    }
  }

  // Description: handle blur and return the view with Mask in case nothing is selected
  const handleBlur = useCallback(
    _.debounce(() => {
      !masked_ref.current && setMasked(true);
    }, 200), [masked_ref.current]);

  // Description: Select a country
  function handleSubmit(country: Country) {
    setSelected(country);
    setValue(country.country_abb);
    setFiltered(options);
    setMasked(true);
  }

  // Description: Handle on focus - reset
  function reset() {
    setMasked(false);
    setValue("");
    setFiltered(options);
  }

  return <>
    <Dropdown
      className="countries"
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        if ((e.target as HTMLElement).id === "country_tb") {
          reset();
          input_element_ref.current?.focus();
        }
      }}>
      <Dropdown.Toggle as="div" className={masked ? "masked" : ""}>
        <Mask />
        <div className="input-icon-append">
          <Form.Control
            disabled={disabled}
            ref={input_element_ref}
            id="country_tb"
            maxLength={2}
            autoComplete="off"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleOnKeyEvent} />
          {!disabled && <Icon className="ficon-chevron-down" />}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" >
        {
          filtered.length > 0 ?
            _.map(filtered, (country: Country, i: number) => {
              return <Dropdown.Item
                key={`country_${country.country_abb}`}
                onClick={() => { handleSubmit(country); }} >
                <Icon className={`cicon-${country.country_abb.toLowerCase()}`} />
                <span>{country.country_abb}</span>
              </Dropdown.Item>;
            }) : <Dropdown.Item className="text-muted" onClick={reset} >No Options</Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown >
  </>;
}

export default React.memo(SelectCountryComponent);