import React, { useState, FocusEvent } from "react";
import { Form, Col, Button, Card } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function ConnectionEstablishedComponent(props: AllProps) {
  const { handleAction } = props;
  const [validated, setValidated] = useState<boolean>(false);
  const [_pin, setPin] = useState<string>("");

  // Description: Submit form
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      handleAction({ actionType: AccountUIActionTypes.TransferKeyRequest, params: { pin: form.pin.value } });
    }
    setValidated(true);
  };

  // Description: Reset form and handle reset action to parent
  const handleCancel = () => {
    setPin("");
    setValidated(false);
    handleAction({ actionType: AccountUIActionTypes.Destroy });
  };
  return <Card.Body>
    <h3 className="content-header">
      <i className="connected"></i> Connection Active
    </h3>
    <h4>Enter the 8-digit code from your other device.</h4>
    <Form noValidate validated={validated} onSubmit={handleSubmit} >
      <Form.Group as={Col} xs="8" className="mb-3">
        <Form.Label htmlFor="pin_tb" visuallyHidden>Device Code</Form.Label>
        <Form.Control required
          id="pin_tb"
          name="pin"
          autoComplete="off"
          autoFocus={true}
          placeholder="Enter 8-digit code"
          value={_pin}
          onChange={(e: FocusEvent<HTMLInputElement>) => setPin(e.currentTarget.value)} />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={_pin.length <= 0} className="me-2">
        Transfer Private Key</Button>
      <Button variant="outline-primary" onClick={handleCancel}>Cancel</Button>
      {/* NOTE: USE SAME BUTTON WITH CONDITIONAL */}
      {/* <Button className="btn btn-lg btn-primary btn-bounce loading">Transferring Key&hellip;</Button> */}
    </Form>
  </Card.Body>;
}
export default React.memo(ConnectionEstablishedComponent);
