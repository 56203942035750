import React, { useRef, useEffect, FocusEvent, MouseEvent } from "react";
import { CheckboxStates, CheckboxStatesTypes, UUID } from "src/common";

type AllProps = {
  className?: string;
  selected: CheckboxStatesTypes;
  value: unknown; // NOTE: Pass the id or value representing this checkbox
  onChange: (e: FocusEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean
}

function CheckBox(props: AllProps) {
  const uuid = new UUID().String();// NOTE: Create a random Id for the checkbox
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { onChange, label, selected, className, disabled } = props;

  useEffect(() => {
    if (!!checkboxRef.current) {
      if (selected === CheckboxStates.checked) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (selected === CheckboxStates.empty) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (selected === CheckboxStates.indeterminate) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [selected]);

  return <div
    onClick={(e: MouseEvent<HTMLInputElement>) => { e.stopPropagation(); }}
    className={`form-check ${!!className ? className : ""}`}>
    <input type="checkbox"
      ref={checkboxRef}
      id={uuid}
      className="form-check-input"
      onChange={onChange}
      disabled={disabled} />
    <label title={!!label ? label : "Select"}
      htmlFor={uuid}
      onClick={(e) => {
        // Description: Toggle checkbox if the label is clicked
        if (!!checkboxRef.current) {
          checkboxRef.current.checked = !checkboxRef.current.checked;
          // NOTE: need to convert to input event, so "any" type needed here to pass to on change function
          e.target = checkboxRef.current;
          onChange(e as any);
        }
      }}
      className="form-check-label">{!!label ? label : ""}</label>
  </div >;

}

export default React.memo(CheckBox);