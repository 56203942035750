import React, { useState, FocusEvent } from "react";
import { Card, Form, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountUIActionTypes } from "src/common";
type AllProps = {
  request_user_id: string;
  handleAction: ActionHandlerFunction;
}

function ConnectionEstablishedComponent(props: AllProps) {
  const { request_user_id, handleAction } = props;
  const [validated, setValidated] = useState<boolean>(false);
  const [_pin, setPin] = useState<string>("");

  // Description: Submit form
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      handleAction({ actionType: AccountUIActionTypes.TransferKeyRequest, params: { pin: form.pin.value } });
    }
    setValidated(true);
  };

  // Description: Reset form and handle reset action to parent
  const handleCancel = () => {
    setPin("");
    setValidated(false);
    handleAction({ actionType: AccountUIActionTypes.Destroy });
  };

  return <Card.Body>
    <h3 className="content-header"><span className="connected"></span> Connection Active</h3>
    <div className="max-700">
      <h4>Enter the 8-digit code from your other device.</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <Form.Group as={Col} xs="5" className="mb-3">
          <Form.Label for ="pin_tb" visuallyHidden>Device Code</Form.Label>
          <Form.Control required
            id="pin_tb"
            name="pin"
            autoFocus={true}
            placeholder="Enter 8-digit code"
            autoComplete="off"
            value={_pin}
            onChange={(e: FocusEvent<HTMLInputElement>) => setPin(e.currentTarget.value)} />
        </Form.Group>
        <p>Do you approve secure key recovery for <b>{request_user_id}</b>?</p>
        <div className="btn-panel">
          <Button type="submit" disabled={_pin.length <= 0}>
            Yes, I approve</Button>
          <Button variant="outline-primary" onClick={handleCancel}>Cancel</Button>
        </div>
        {/* NOTE: USE SAME BUTTON WITH CONDITIONAL */}
        {/* <Button className="btn btn-lg btn-primary btn-bounce loading">Transferring Key&hellip;</Button> */}
      </Form>
    </div>
  </Card.Body>;
}
export default React.memo(ConnectionEstablishedComponent);