import { useCallback, useEffect, useState } from "react";
import { ErrorStackDataType, MessageDisplayType } from "@preveil-api";
import { randomBytes } from "pvcryptojs";
import {
    Account, useAppDispatch, Message, MessageHandlerDisplayType, DriveErrorMessages,
    useAppSelector, filesyncSocketApi, DriveRequest, PermissionSet, Helpers, CollectionFilesyncAPI, RenameValidationErrorMessages, useGetPermissions, useGetGrants, GrantSetType,
    MessageAnchors, getEnumKey, ErrorStackItem, encryptName, GrantSet, EntryItem, DriveEntryType, DirectoryEntity, CollectionEntity,
    COLLECTION_PROTOCOL_VERSIONS, PermissionSetType, getDirSymmKey, useRenameItemMutation, UUID, AppConfiguration, RenameJob
} from "src/common";
import { SymmKey } from "src/common/keys";
import { FSMessage, SealedContent, FSRole, FSStatus, FSWrappedKey } from "src/common/keys/protos/collections_pb";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

interface ParentInfo {
    symmetric_key: SymmKey;
    wrapped_key: FSWrappedKey;
    version: string;
}

// Description: Hook used to rename a collection
export function useRename(current_account: Account, collection_info: CollectionEntity, current_directory: DirectoryEntity, entry: EntryItem) {
    const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
    const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
    const collection_id = entry.linked_collection_id ? entry.linked_collection_id : entry.collection_id;
    const [new_name, setNewName] = useState<string>();
    const [rename_error, setRenameError] = useState<boolean>();
    const [rename_success, setRenameSuccess] = useState<boolean>();
    const [parent_directory, setParentDirectory] = useState<FSMessage.Dir>();
    const [parent_grants, setParentGrants] = useState<GrantSetType>();
    const [sendRequest] = filesyncSocketApi.useSendRequestMutation();
    const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
    const { grants, getGrants, destroyGrants } = useGetGrants(current_account);
    const [app_rename] = useRenameItemMutation();
    const dispatch = useAppDispatch();

    // Description: Rename if permissions and grants are set for V2 collections
    useEffect(() => {
        if (!!permissions && !!new_name) {
            if (collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1) {
                handleInitRename(new_name, permissions);
            } else if (!!grants) {
                !parent_grants && setParentGrants(grants);
                !!parent_directory ? getKeyInformation(parent_directory, new_name, permissions, grants) : handleInitRename(new_name, permissions, grants);
            }
        }
    }, [permissions, grants]);

    // Description: Call renameCollection if its a collection and renameEntry if it's an entry
    // If the user opens the detail view from the dropdown menu, the current directory is passed correctly.
    // However, if the user opens the detail view from the toolbar, we need to fetch the parent folder 
    // before renaming the entry so that we obtain the correct version and symmetric key.
    function handleInitRename(_new_name: string, _permissions: PermissionSetType, _grants?: GrantSetType) {
        if (entry.type === DriveEntryType.LINK) {
            renameCollection(_new_name, _permissions, _grants);
        } else {
            if (entry.id !== current_directory.id && !!current_directory.symmetric_key && !!current_directory.wrapped_key) {
                const parent_info: ParentInfo = {
                    symmetric_key: current_directory.symmetric_key,
                    wrapped_key: current_directory.wrapped_key,
                    version: current_directory.version,
                };
                renameEntry(_new_name, parent_info, _permissions, _grants);
            } else if (!!entry.parent_id) {
                getParentInformation(_new_name, entry.parent_id, _permissions, _grants);
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_initializing_rename });
            }
        }
    }

    // Description: Renames collections and entries via FS
    function handleAppRename(_new_name: string) {
        const request_id = new UUID().String();
        const directory_id = (entry.id !== current_directory.id) ? current_directory.id : entry.parent_id;
        if (!!directory_id) {
            app_rename({
                user_id: current_account.user_id,
                collection_id: entry.collection_id,
                directory_id,
                entity_id: entry.id,
                type: entry.type,
                new_name: _new_name,
                request_id
            }).unwrap()
                .then(() => {
                    // Wait for rename job status if its a collection or ACL node - this 
                    if (entry.type === DriveEntryType.LINK || (!!collection_info.maintainer_id && entry.id === collection_info.maintainer_id)) {
                        const rename_job: RenameJob = {
                            id: request_id,
                            old_name: entry.name,
                            new_name: _new_name
                        };
                        dispatch(uiActions.setRenameJob(rename_job));
                    } else {
                        setRenameSuccess(true);
                    }
                })
                .catch(() => {
                    handlePageErrorMessage(
                        RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                        { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                        undefined,
                        MessageHandlerDisplayType.toastr
                    );
                });
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_app_rename });
        }
    }

    // Description: Used to rename collection via setCollectionAttributes
    async function renameCollection(_new_name: string, _permissions: PermissionSetType, _grants?: GrantSetType) {
        const encrypted_name = await getEncryptedName(_new_name, _permissions);
        const drive_request = !!encrypted_name ? await CollectionFilesyncAPI.setCollectionAttributes(current_account, collection_id, encrypted_name, _permissions, _grants) : null;
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                setRenameSuccess(true);
            } else {
                handlePageErrorMessage(
                    RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                    { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                    message,
                    MessageHandlerDisplayType.toastr
                );
            }
        }
        handleSendRequest(drive_request, callback, RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name));
    }

    // Description: Renames items (files/folders that aren't shared)
    async function renameEntry(_new_name: string, _parent_info: ParentInfo, _permissions: PermissionSetType, _collection_grants?: GrantSetType, _parent_grants?: GrantSetType) {
        let drive_request = null;
        if (!!entry.version && !!_parent_info.symmetric_key) {
            const encrypted_name = await encryptName(_new_name, _parent_info.symmetric_key);
            // Set the maintainer scope name if renaming an ACL Node
            const maintainer_grants = !!_parent_grants ? _parent_grants : _collection_grants;
            const maintainer_scoped_name = (entry.id === collection_info.maintainer_id) ? await getEncryptedMaintainerScopedName(_new_name, _parent_info, maintainer_grants) : undefined;
            const new_version = randomBytes(32);
            drive_request = await CollectionFilesyncAPI.renameEntry(current_account, _permissions, collection_id, entry.id, entry.version, new_version, _parent_info.version, encrypted_name, maintainer_grants, maintainer_scoped_name);
        }
        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                setRenameSuccess(true);
            } else {
                handlePageErrorMessage(
                    RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name),
                    { stack_message: RenameValidationErrorMessages.error_renaming.replace(MessageAnchors.file_name, _new_name) },
                    message,
                    MessageHandlerDisplayType.toastr
                );
            }
        }
        handleSendRequest(drive_request, callback, _new_name);
    }

    // Description: Encrypts the name using the ACLs reader key
    async function getEncryptedMaintainerScopedName(_new_name: string, _parent_info: ParentInfo, _grants?: GrantSetType): Promise<SealedContent | undefined> {
        const grant = (!!_grants && !!_parent_info) ? GrantSet.getGrantbyRole(_grants, _parent_info.wrapped_key.getKeyVersion() || 0) : undefined;
        const reader_user_key = !!grant ? await grant.key(current_account) : undefined;

        if (reader_user_key) {
            const maintainer_scoped_name = new SealedContent();
            const new_encrypted_name = await reader_user_key.encryption_key.seal(Helpers.utf8Encode(_new_name));
            maintainer_scoped_name.setKeyVersion(reader_user_key.key_version);
            maintainer_scoped_name.setContent(new_encrypted_name);
            return maintainer_scoped_name;
        }
    }

    // Description: Build encrypted name from new name and return null on failure
    async function getEncryptedName(_new_name: string, _permissions: PermissionSetType): Promise<SealedContent | undefined> {
        const permission_set = !!_permissions ? PermissionSet.init(_permissions) : null;
        const permission = permission_set?.permission(FSRole.READER) || permission_set?.permission(FSRole.ACCESS);
        const user_key = !!permission ? await permission.key(current_account) : null;
        const sealed_name = !!user_key ? (await user_key).encryption_key.seal(Helpers.utf8Encode(_new_name)) : null;

        const encrypted_name = new SealedContent();
        if (!!user_key && !!sealed_name) {
            encrypted_name.setKeyVersion(user_key.key_version);
            encrypted_name.setContent(await sealed_name);
            return encrypted_name;
        }
    }

    // Description: This function retrieves the parent information and fetches the grants for the parent
    // This is a necessary step if the parent is an ACL node
    async function getParentInformation(_new_name: string, _parent_id: string, _permissions: PermissionSetType, _grants?: GrantSetType) {
        const fetchDir = await CollectionFilesyncAPI.getDirectoryInformationRequest(collection_id, _parent_id);
        const drive_request = await DriveRequest.initializeRequest(fetchDir, current_account, _permissions);

        async function callback(message: FSMessage) {
            if (message.getStatus() === FSStatus.OK) {
                const dir = message.getDir();
                if (!!dir) {
                    setParentDirectory(dir);
                    // Get grants if it's a V2 collection and proceed with the rename if it's a V1 collection
                    collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 ? getGrants(dir.getMaintainerId_asB64(), collection_id, default_permissions, default_grants) : getKeyInformation(dir, _new_name, _permissions, _grants);
                    
                } else {
                    handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
                }
            } else {
                handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_maintainer_directory }, message);
            }
        }
        handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_maintainer_directory);
    }

    // Description: This function retrieves the parent node's key information and version and then calls renameEntry.
    async function getKeyInformation(dir: FSMessage.Dir, _new_name: string,  _permissions: PermissionSetType, _grants?: GrantSetType) {
        const wrapped_key = dir.getWrappedDirKey();
        if (!!wrapped_key) {
            const grant = !!_grants ? GrantSet.getGrantbyRole(_grants, wrapped_key.getKeyVersion()) : undefined;
            const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
            const symmetric_key = !!wrapped_key ? await getDirSymmKey(wrapped_key, current_account, _permissions, reader_user_key) : undefined;
            if (!!symmetric_key) {
                const parent_info: ParentInfo =
                {
                    symmetric_key,
                    version: dir.getVersion_asB64(),
                    wrapped_key
                };
                renameEntry(_new_name, parent_info, _permissions, _grants, parent_grants);
            }
        }
    }

    // -----------------------------
    // HOOK General
    // -----------------------------
    // Description: Sends Request and handles error when the request object is null, pass it custom stack message
    function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
        if (!!request) {
            request.setCallback(callback);
            sendRequest(request);
        } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
        }
    }

    // Description: Handles request errors and if permissions, user key or sealed name are not found
    function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
        const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
        const stack = new ErrorStackItem("[useRename Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
        dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
        setRenameError(true);
    }

    // Description: Reset state on complete
    function reset() {
        setNewName(undefined);
        setRenameError(undefined);
        setParentGrants(undefined);
        setRenameSuccess(undefined);
        setParentDirectory(undefined);
        destroyPermissions();
        destroyGrants();
    }

    // Description: Callback for collection rename
    const rename = useCallback((name: string) => {
        if (!AppConfiguration.buildForWeb()) {
            handleAppRename(name);
        } else {
            setNewName(name);
            getPermissions(collection_id, default_permissions);
            (collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 && !!collection_info.maintainer_id) &&
                getGrants(collection_info.maintainer_id, collection_id, default_permissions, default_grants);
        }
    }, []);

    return {
        rename,
        reset,
        rename_success,
        rename_error
    };
}
