import {
  Account, AppConfiguration, PrivateRoutes, DriveRouteType, Helpers, AppUserKey, PermissionSetType, PermissionSet, KeyFactory, DriveEntryTypes,
  DriveFileType, DriveEntryIcons, MIME_ICON_MAPPING, mimeLookup, EntryInfo, DriveEntryType, EntryItem, DefaultRoutes, UUID, DriveUrlParams,
  DriveReservedNames, DirectoryEntity, RenameValidationErrorMessages, MessageAnchors, TreeItemType, PathInfo, FolderIconSyncState, SharedFolderIconSyncState,
  LockInfo, UUIDStringType, DriveConstants, treeRoot, SELECTIVE_SYNC_STATES, TreeItem
} from "../";
import { FSRole, FSWrappedKey, FSMessage } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import { EncryptionStyle } from "src/common/keys/encryption";
import { SYMM_KEY_PROTOCOLS } from "src/common/keys/protocols";
import { FipsCrypto, NaclCrypto } from "pvcryptojs";
import _ from "lodash";


// ----------------------------------------------------------------------------
// HELPERS
// ----------------------------------------------------------------------------
// Description: Resolves Drive Pages
export function isRootPath(dir?: string, node_id?: string): boolean {
  return dir === PrivateRoutes.drive_root;
}

// Description: Resolves Invitation Page
export function isInvitationPath(dir?: string, node_id?: string): boolean {
  return dir === PrivateRoutes.invitations_route;
}

// Description: Resolves Shared Page
export function isSharedPath(dir?: string, node_id?: string): boolean {
  return dir === PrivateRoutes.shared_route;
}

// Description: Resolves Trash Page
export function isTrashPath(dir?: string, node_id?: string): boolean {
  return dir === PrivateRoutes.trash_route;
}

// Description: is  a diretory url
export function isFolder(item_type?: string, node_id?: string): boolean {
  return item_type !== DriveEntryType.FILE && item_type !== DriveRouteType.FILE;
}

// Description: Inspect is locked
export function isEntryLocked(lock_info?: LockInfo): boolean {
  return !!lock_info && !!lock_info.lockedByUserId && !!lock_info.lockedAt;
}

// Description: For App build mode the identifiers are url safe (filesync converts these to url safe)
export function isSameIdentifier(identifier?: string, match_id?: string): boolean {
  return AppConfiguration.buildForApp() ?
    !!identifier && !!match_id && Helpers.convertToURLSafeId(identifier) === Helpers.convertToURLSafeId(match_id) :
    !!identifier && !!match_id && identifier === match_id;
}

// Description:  For App build mode check to see if item is synced by its different local_sync_status 
export function isSynced(local_sync_status: SELECTIVE_SYNC_STATES): boolean {
  return local_sync_status === SELECTIVE_SYNC_STATES.ON || local_sync_status === SELECTIVE_SYNC_STATES.InheritedON;
}

// Description: Returns true if the directory passed is a disjointed ACL Node. 
// That is, it is a maintainer node, is root is false and has no parentId
export function isDisjointedACLNode(dir: FSMessage.Dir): boolean {
  const is_root = dir.getIsRoot() || false;
  const is_maintainer = dir.hasMaintainerId() && !!dir.getMaintainerId_asB64() && dir.getMaintainerId_asB64() === dir.getId_asB64();
  return !is_root && is_maintainer && !dir.getParentId_asB64();
}

// Description: Map EntryItem Type to the router types: l | d | f
export function getDriveRouteType(type: DriveEntryTypes | null): string {
  return !!type && Object.prototype.hasOwnProperty.call(DriveRouteType, type) ? DriveRouteType[type] : "";
}

// Description: Get Symmetric Key for the directory
export async function getDirSymmKey(fs_wrapped_dir_key: FSWrappedKey, current_account: Account, permissions: PermissionSetType, user_key?: AppUserKey):
  Promise<SymmKey | undefined> {
  try {
    const permission_set = PermissionSet.init(permissions);
    const wrapped_key = fs_wrapped_dir_key.getWrappedKey_asU8();
    const reader = permission_set.permission(FSRole.READER, fs_wrapped_dir_key.getKeyVersion());
    const key = !!reader ? await reader.key(current_account) : !!user_key ? user_key : null;
    if (!!key) {
      const unwrapped_dir_key = await key.encryption_key.unseal(wrapped_key);
      return KeyFactory.deserializeSymmKey(unwrapped_dir_key, EncryptionStyle.DRIVE);
    }
  } catch (error: unknown) {
    console.error("Error getting the Symmetric Key for the directory", error);
    return undefined;
  }
}

// Description: Return a new FSWrappedKey
export async function getNewWrappedDirKey(symm_key: SymmKey, current_account: Account, reader_key: AppUserKey): Promise<FSWrappedKey> {
  const wrapped_key = await reader_key.encryption_key.seal(symm_key.serialize());
  const fs_wrapped_key = new FSWrappedKey();
  fs_wrapped_key.setWrappedKey(wrapped_key);
  fs_wrapped_key.setKeyVersion(reader_key.key_version);
  return fs_wrapped_key;
}

// Description: Decrypt name and other using symmetric key
export async function decryptString(bytes: Uint8Array, key: SymmKey): Promise<string> {
  const utf8decoded = Helpers.utf8Decode(bytes);
  const b64decoded = Helpers.b64Decode(utf8decoded);
  const unwrapped = await key.decrypt(b64decoded);
  return Helpers.utf8Decode(unwrapped);
}

export async function encryptName(name: string, dir_key: SymmKey): Promise<Uint8Array> {
  const utf8_name = Helpers.utf8Encode(name);
  const sha256 = await Helpers.sha256Checksum(utf8_name);
  const iv = dir_key.protocol_version === SYMM_KEY_PROTOCOLS.V0 ? sha256.slice(-NaclCrypto.ivLength) : sha256.slice(-FipsCrypto.ivLength);
  const encrypted_name = await dir_key.encrypt(utf8_name, iv);
  const b64_string = Helpers.b64Encode(encrypted_name);
  return Helpers.utf8Encode(b64_string);
}

// Description: Decrypt name from CS - try decryptString first
export async function decryptItemName(entry_name: Uint8Array, key: SymmKey, user_key?: AppUserKey): Promise<string> {
  let item_name: string = "";
  try {
    item_name = await decryptString(entry_name, key) || "";
  } catch (error) {
    console.error(error);
    item_name = !!user_key ? Helpers.utf8Decode(await user_key.encryption_key.unseal(entry_name)) : "";
  }
  return item_name;
}

// Description: Decrypt name for collections, pass the reader key
// NOTE: Get root name using reader key - Will only work if key_version === 0 else ""
export async function decryptRootName(entry_name: Uint8Array, permissions: PermissionSetType, current_account: Account, key_version?: number): Promise<string> {
  const set = PermissionSet.init(permissions);
  const reader = set.permission(FSRole.READER, key_version);
  const access = set.permission(FSRole.ACCESS, key_version);
  const reader_key = await (!!reader ? reader.key(current_account) : access?.key(current_account));
  let item_name: string = "";
  try {
    item_name = !!reader_key ? Helpers.utf8Decode(await reader_key.encryption_key.unseal(entry_name)) : "";
  } catch (error) {
    console.error(error);
    item_name = "";
  }
  return item_name;
}

export async function encryptItemName(name: string, dir_key: SymmKey): Promise<Uint8Array> {
  // format the name
  const utf8_name = Helpers.utf8Encode(name);
  const sha256 = await Helpers.sha256Checksum(utf8_name);
  const iv =
    dir_key.protocol_version === SYMM_KEY_PROTOCOLS.V0
      ? sha256.slice(-NaclCrypto.ivLength)
      : sha256.slice(-FipsCrypto.ivLength);
  const encrypted_name = await dir_key.encrypt(utf8_name, iv);
  const b64_string = Helpers.b64Encode(encrypted_name);
  return Helpers.utf8Encode(b64_string);
}

// Description:  return the labels and icons for an entry
export function mapEntryType(fs_type: DriveEntryTypes, file_name: string = "", localSyncStatus: SELECTIVE_SYNC_STATES = SELECTIVE_SYNC_STATES.OFF):
  EntryInfo | null {
  let mapped_type;
  let type_class;
  let type_label;
  switch (DriveEntryType[fs_type]) {
    case DriveEntryType.DIR:
      mapped_type = DriveEntryType.DIR;
      type_class = getEntityIcon(DriveEntryType.DIR, localSyncStatus);
      type_label = DriveFileType.Folder;
      break;
    case DriveEntryType.LINK:
      mapped_type = DriveEntryType.LINK;
      type_class = getEntityIcon(DriveEntryType.LINK, localSyncStatus);
      type_label = DriveFileType.Shared_Folder;
      break;
    case DriveEntryType.FILE:
      const mapped_mime = mimeLookup(file_name);
      mapped_type = DriveEntryType.FILE;
      type_class = DriveEntryIcons.File;
      type_label = DriveFileType.File;
      // Some types won't map, do default for those
      if (!!mapped_mime) {
        if (MIME_ICON_MAPPING.code.test(mapped_mime)) {
          type_label = DriveFileType.Code;
          type_class = DriveEntryIcons.Code;
        } else if (MIME_ICON_MAPPING.word.test(mapped_mime)) {
          type_label = DriveFileType.Word;
          type_class = DriveEntryIcons.Word;
        } else if (MIME_ICON_MAPPING.excel.test(mapped_mime)) {
          type_label = DriveFileType.Excel;
          type_class = DriveEntryIcons.Excel;
        } else if (MIME_ICON_MAPPING.powerpoint.test(mapped_mime)) {
          type_label = DriveFileType.PowerPoint;
          type_class = DriveEntryIcons.PowerPoint;
        } else if (MIME_ICON_MAPPING.archive.test(mapped_mime)) {
          type_label = DriveFileType.Archive;
          type_class = DriveEntryIcons.Archive;
        } else if (MIME_ICON_MAPPING.text.test(mapped_mime)) {
          type_label = DriveFileType.Text;
          type_class = DriveEntryIcons.Text;
        } else if (MIME_ICON_MAPPING.image.test(mapped_mime)) {
          type_label = DriveFileType.Image;
          type_class = DriveEntryIcons.Image;
        } else if (MIME_ICON_MAPPING.audio.test(mapped_mime)) {
          type_label = DriveFileType.Audio;
          type_class = DriveEntryIcons.Audio;
        } else if (MIME_ICON_MAPPING.video.test(mapped_mime)) {
          type_label = DriveFileType.Video;
          type_class = DriveEntryIcons.Video;
        } else if (MIME_ICON_MAPPING.pdf.test(mapped_mime)) {
          type_label = DriveFileType.PDF;
          type_class = DriveEntryIcons.PDF;
        } else {
          type_label = DriveFileType.File;
          type_class = DriveEntryIcons.File;
        }
      }
      break;
    default:
      return null;
  }

  return {
    mapped_type,
    type_class,
    type_label
  };
}

// Description: returns icon based on entry type 
// NOTE: will be expanded to address other subfolder sync states with new Icons
export function getEntityIcon(entry_type: DriveEntryTypes, localSyncStatus: SELECTIVE_SYNC_STATES = SELECTIVE_SYNC_STATES.OFF): string {
  return entry_type === DriveEntryType.FILE ? "pv-icon-file-text" :
    entry_type === DriveEntryType.LINK ?
      (Object.prototype.hasOwnProperty.call(SharedFolderIconSyncState, localSyncStatus) ? SharedFolderIconSyncState[localSyncStatus] : DriveEntryIcons.Shared_Folder) :
      (Object.prototype.hasOwnProperty.call(FolderIconSyncState, localSyncStatus) ? FolderIconSyncState[localSyncStatus] : DriveEntryIcons.Folder);
}

export function encryptFileName(name: string, dir_key: SymmKey): Promise<Uint8Array> {
  const utf8_name = Helpers.utf8Encode(name);
  return Helpers.sha256Checksum(utf8_name)
    .then(sha256 => {
      let iv: Uint8Array;
      switch (dir_key.protocol_version) {
        case SYMM_KEY_PROTOCOLS.V0:
          iv = sha256.slice(-NaclCrypto.ivLength);
          break;
        default:
          iv = sha256.slice(-FipsCrypto.ivLength);
      }
      return dir_key.encrypt(utf8_name, iv);
    })
    .then(encrypted_name => {
      const b64_string = Helpers.b64Encode(encrypted_name);
      return Helpers.utf8Encode(b64_string);
    });
}

// Description: Based on a tracking_id check if upload is in progress for an entry
export function isUploadInProgres(entry: EntryItem, upload_folder_ids: string[]): boolean {
  return upload_folder_ids.includes(!!entry.linked_collection_id && entry.linked_collection_id.length > 0 ? entry.linked_collection_id : entry.id);
}

// ------------------------------------------------------------------------------
// VALIDATORS for Drive
// ------------------------------------------------------------------------------
// Description: Returns an error message (string) if the name is invalid and undefined if the name is valid.
export function validateFileFolderName(name: string, type: DriveEntryTypes, current_directory?: DirectoryEntity): string | undefined {
  const invalid_name = DriveReservedNames.find(reserved_word => name.toLowerCase() === reserved_word);
  const lastCharIsValid = name.slice(-1) !== ".";
  const escapeCharIsInvalid = name.includes("\\");
  const pattern = /^[^</\\:?"|*>]+$/;
  const type_name = type === DriveEntryType.FILE ? "File" : "Folder";
  if (name[0] === ".") {
    return RenameValidationErrorMessages.invalid_name_beginning.replace(MessageAnchors.message_content, type_name);
  } else if (!!invalid_name) {
    return RenameValidationErrorMessages.invalid_name.replace(MessageAnchors.file_name, name);
  } else if (!pattern.test(name) || escapeCharIsInvalid || !lastCharIsValid) {
    return RenameValidationErrorMessages.invalid_character.replace(MessageAnchors.message_content, type_name);
  } else if (
    current_directory &&
    current_directory.entries.find((entry) => entry.name.toLowerCase() === name.toLowerCase())
  ) {
    return RenameValidationErrorMessages.existing_name.replace(MessageAnchors.file_name, name);
  }
}

export function validateFileExtension(previous_extension?: string, new_extension?: string): string | undefined {
  if (previous_extension !== new_extension) {
    const title = (!!previous_extension && !!new_extension) ?
      RenameValidationErrorMessages.change_extension
        .replace(MessageAnchors.prev_extension, previous_extension)
        .replace(MessageAnchors.new_extension, new_extension) :
      !!previous_extension ? RenameValidationErrorMessages.remove_extension.replace(MessageAnchors.prev_extension, previous_extension) :
        RenameValidationErrorMessages.add_extension.replace(MessageAnchors.new_extension, new_extension || "");
    return title;
  }
}

// ------------------------------------------------------------------------------
// URL Building tools:
// ------------------------------------------------------------------------------
// Description: Parse Url params as UUID to B64 strings
export function getRouterParams(params: Partial<DriveUrlParams>, is_trash: boolean = false): DriveUrlParams {
  const { collection_id, id, file_id, item_type } = params;
  return (is_trash) ?
    {
      collection_id: getB64fromRouterParam(id) || PrivateRoutes.trash_route,
      id: getB64fromRouterParam(file_id),
      file_id: undefined,
      item_type,
    } : (!isRootPath(params.collection_id, params.id)) ?
      {
        collection_id: getB64fromRouterParam(collection_id) || PrivateRoutes.drive_root,
        id: getB64fromRouterParam(id),
        file_id: getB64fromRouterParam(file_id),
        item_type
      } : { collection_id: collection_id || PrivateRoutes.drive_root, id, file_id, item_type };
}

// Description: Get an identifier, validate it to discover if it is a uuid or a B64 string. 
// Returns:     B64 string OR Null
// NOTE: uuid needs to be converted from url safe Id for app mode if its alread a B64 string
export function getB64fromRouterParam(uuid?: string): string | undefined {
  if (!!uuid) {
    return Helpers.identifyUUIDStringType(uuid) === UUIDStringType.uuid ? new UUID({ uuid }).B64() :
      Helpers.identifyUUIDStringType(uuid) === UUIDStringType.base64 ?
        (AppConfiguration.buildForApp() ? Helpers.convertFromURLSafeId(uuid) : uuid) : undefined;
  }
}

// Description: Convert Base64 to UUID
export function convertB64toUUID(b64: string): string {
  return new UUID({ bytes: Helpers.b64Decode(b64) }).String();
}

// Description: safely build url for entries
//              For disjointed ACL Nodes (recipient side) use legacy format drive/collection_uuid/list/dl
export function buildNavigationLink(collection_id: string, id?: string, type?: DriveEntryTypes, file_id?: string): string {
  let url = DefaultRoutes.drive_root;
  if (!!id && !!type) {
    const _collection_id = new UUID({ bytes: Helpers.b64Decode(collection_id) }).String();
    const _id = id === DriveConstants.DIRECT_LINK_MASK_ID ? DriveConstants.DIRECT_LINK_MASK_ID : new UUID({ bytes: Helpers.b64Decode(id) }).String();
    if (!!file_id) {
      const _file_id = new UUID({ bytes: Helpers.b64Decode(file_id) }).String();
      url = `/drive/${encodeURIComponent(_collection_id)}/${encodeURIComponent(_id)}/${encodeURIComponent(_file_id)}/${getDriveRouteType(type)}`;
    } else {
      url = `/drive/${encodeURIComponent(_collection_id)}/${encodeURIComponent(_id)}/${getDriveRouteType(type)}`;
    }
  }
  return url;
}

// Description: Returns the direct link to the directory, collection or file
export function buildDirectLink(entry: EntryItem) {
  const _collection_id = !!entry.linked_collection_id ? entry.linked_collection_id : entry.collection_id;
  const path = buildNavigationLink(_collection_id, entry.id, entry.type);
  return path;
}

// Description: Build Tree array from flat DirectoryEntity
function buildPseudoTree(source: string, target: any, entry: EntryItem, index: number = 0) {
  const items = source.split("/");
  const item_name = items.shift();
  if (!!item_name) {
    const item = {
      type: TreeItemType.folder,
      name: item_name,
      children: undefined
    };
    if (items.length) {
      if (typeof target[item_name] !== "object") {
        !!target.children ? target.children.push(item) : (target.children = [item]);
        target[item_name] = item;
      }
      buildPseudoTree(items.join("/"), target[item_name], entry, index + 1);
    } else { // Final Piece of the name:
      const drive_item = Object.assign(item, { id: entry.id, url_identifier: entry.id, path: entry.name });
      !!target.children ? target.children.push(drive_item) : (target.children = [drive_item]);
      target[item_name] = item;
    }
  }
}

// Description: Removes excess object artifacts from tree creation
function cleanTreeObject(treeItem: TreeItem): TreeItem | undefined {
  const tree = _.pick(treeItem, ["type", "name", "path", "children", "type", "id", "url_identifier", "disabled", "active"]) as TreeItem;
  if (!!tree.children && tree.children.length) {
    const children: TreeItem[] = [];
    _.map(tree.children, (child: TreeItem) => {
      if (!!child.id) {
        const _child = cleanTreeObject(child);
        _child && children.push(_child);
      }
    });
    tree.children = children;
  }
  return tree;
}

// Description: Build Custom Drive tree
export function buildCustomDriveTree(directory: DirectoryEntity, selected: string[], parent_tree_item_path?: string, current_directory?: DirectoryEntity, current_maintainer_id?: string): TreeItem[] | undefined {
  const first_level: TreeItem[] = [];
  const entries = directory.entries.filter(e => e.type === DriveEntryType.DIR && !selected.includes(e.id) && (!e.maintainer_id && !current_maintainer_id || e.maintainer_id === current_maintainer_id));
  _.map(entries, (entry: EntryItem) => {
    const name_array = entry.name.split("/");
    const _expanded = !!current_directory ? current_directory.path?.find(p => new UUID({ b64: p.id }).B64WebSafe() === new UUID({ b64: entry.id }).B64WebSafe()) : undefined;
    const item = {
      id: entry.id,
      type: TreeItemType.folder,
      name: entry.name,
      path: parent_tree_item_path ? `${parent_tree_item_path}/${entry.name}` : entry.name,
      url_identifier: entry.id,
      children: undefined,
      children_loading: true,
      expanded: !!_expanded,
      disabled: !!current_directory && entry.id === current_directory.id
    };

    return (name_array.length === 1) ?
      first_level.push(item) :
      buildPseudoTree(entry.name, treeRoot, entry);
  });

  let tree = first_level;
  if (treeRoot.children.length > 0) {
    const children = _.values(_.merge(_.keyBy(treeRoot.children, "name"), _.keyBy(first_level, "name")));
    const custom_tree = cleanTreeObject(Object.assign({}, treeRoot, { children }));
    if (!!custom_tree && !!custom_tree.children) {
      tree = custom_tree.children;
    }
  }

  return tree;
}

// Description: Get path status from breadcrumbs PathInfo (used for shared page)
export function getStatusFromPath(path: PathInfo[], linked: boolean): string {
  if (!linked) {
    return "Hidden";
  } else {
    return !path || path.length === 1 || path.length === 0
      ? "In \"My PreVeil\""
      : `In "${"My PreVeil" +
      "/" +
      path
        .filter((p) => p.path !== "defaultCollection")
        .map((p) => p.path)
        .join("/")
      }"`;
  }
}

// Description: Builds the directory tree for the selective sync modal
export function buildSelectiveSyncTree(current_directory: DirectoryEntity, selected: string[], parent_tree_item_path?: string, parent?: TreeItem): TreeItem[] | undefined {
  const tree: TreeItem[] = [];
  let entries;
  if (!!parent)
    entries = current_directory.entries.filter(e => e.type !== DriveEntryType.FILE);
  else
    entries = current_directory.entries.filter(e => selected.includes(e.id) && e.type !== DriveEntryType.FILE);
  _.map(entries, (entry: EntryItem) => {
    const name_array = entry.name.split("/");
    const item = {
      id: entry.id,
      type: TreeItemType.folder,
      name: entry.name,
      path: parent_tree_item_path ? `${parent_tree_item_path}/${entry.name}` : entry.name,
      url_identifier: entry.id,
      children: undefined,
      children_loading: true,
      parent,
      collection_id: entry.collection_id,
      linked_collection_id: entry.linked_collection_id,
      current_sync_status: entry.localSyncStatus,
      should_sync: entry.localSyncStatus,
    };

    return (name_array.length === 1) ?
      tree.push(item) :
      buildPseudoTree(entry.name, treeRoot, entry);
  });

  return tree;
}
