/*
 *   File:           constants.ts
 *   Description:    Collection of utility constants, helpers and misc
 *                   constants for storing static data
 *   Author:         PreVeil, LLC
 */
import keyMirror from "keymirror";
import { Variant } from "react-bootstrap/types";
import {
  Platform, AccountType, AccountSessionsType, MessageDisplayType, OrgRolesType, MailboxItem, UserListStatusType, PaginationItem,
  JSONOrgInfo, CollectionServerUser, EventType
} from "@preveil-api";
import { capitalize } from "../helpers/utility";
import { ApprovalGroup, SELECTIVE_SYNC_STATES, UserRequest } from "src/common";


// Description: Application Build Modes:
export const BuildMode = keyMirror({
  app: null,
  web: null,
  express: null,
});

export const TinyMceCSPHeaders = "default-src 'self'; script-src 'self' 'unsafe-inline'; connect-src 'self' blob:; img-src 'self' data: blob:; style-src 'self' 'unsafe-inline'; font-src 'self';";

export const RunMode = keyMirror({
  development: null,
  production: null,
  test: null,
});

export const account_types: { [key: string]: AccountType } = {
  full: "full",
  express: "express",
  nouser: null,
};

// Description: Where the CS websocket is routed WebsocketConnectionHosts
export const WebsocketConnectionRoutes = keyMirror({
  relay_ws: null,
  notify_ws: null,
  collection_ws: null, // CS Filesync connection 
  subscribe_all: null // direct filesync connection
  // ... more connection types
});

// Description: Status codes from responses need to separate by api
export const StatusCode = {
  UNAUTHORIZED_ERROR_CODE: 401,
  FORBIDDEN_DOMAIN: 403,
  OUTDATED_API_VERSION: 406,
  CONFLICT_CODE: 409,
  CODE_EXPIRED_ERROR: 410,
  DEVICE_LOCKED_CODE: 423,
  EXPIRED_KEY_VERSION: 498,
  NO_EMAIL_SIGNATURE_CODE: 404,
  DRIVE_CLIENT_ERROR_CODE: 400,
  DRIVE_GENERIC_ERROR_CODE: 500,
  DRIVE_INITIALIZING_ERROR_CODE: 503,
  DIR_NOT_FULLY_SYNCED_ERROR_CODE: 512,
  LOGS_CACHING_ERROR_CODE: 503
};

// Description: watched for Status  for collection server ws responses FSStatus
export const CollectionServerStatusCode = keyMirror({
  EXPIRED_DEVICE_KEY: null,
  UNAUTHORIZED: null,
  DEVICE_LOCKED_CODE: null,
  LOCKED_DEVICE: null
});

// Description: Filesync Returned Response strings 
// TODO: To add more...
export const FilesyncStatusCode = keyMirror({
  OK: null,
  UNAUTHORIZED: null,
  ERROR: null,
  FILESYNC_WEBSOCKET_ERROR: null
});

export const Regex_Patterns = {
  // eslint-disable-next-line
  EMAIL_REGEX: /^([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  DOMAIN_REGEX: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i,
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!*]).{10,}$/,
  OLD_PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
  MAILBOX_NAME_REGEX: /^[a-zA-Z0-9 ]*$/,
  SEARCH_RECIPIENTS_REGEX: /<s;;(.*?);;e>$/g,
  SEARCH_BODY_HILIGHTED: /(?:\w*\s\w*(?=<span class="highlighted-text">)|\w*(?=<span class="highlighted-text">))/,
  SPECIAL_CHARACTERS_REGEX: /[!@#$%^&*(),.?":{}|<>]/
};

// Legacy DOCS_PERMISSIONS_TYPES
export const DOCS_PERMISSIONS_TYPES = keyMirror({
  edit_and_share: null,
  edit: null,
  read_only: null,
  view_only: null,
  blind_drop: null,
  data_room: null,
  unshare: null,
  access: null,
});

export enum ContentLoadStatus {
  NONE,
  LOADED,
  LOADING,
  FAILED,
}

export const MIME_ICON_MAPPING = {
  code: /(text\/(html|css|x-c|x-csh|x-python|x-typescript|x-script.*|.*python|x-scss)|application\/(json|javascript|ecmascript|x-sh))/,
  word: /application\/.*(ms-?word|wordprocessing).*/,
  excel: /application\/.*(ms-excel|spreadsheet).*/,
  powerpoint: /application\/.*(ms-powerpoint|presentation).*/,
  archive: /application\/(.*diskimage.*|g?zip)/,
  text: /text\/.*/,
  image: /image\/.*/,
  audio: /audio\/.*/,
  video: /video\/.*/,
  pdf: /application\/pdf/
};

// TODO: find other potential exceptions - Legacy
export const LINK_EXCEPTIONS: string[] = [
  "href",
  "src",
  "background",
  "xmlns",
  "http://www.w3.org",
  "schemas.microsoft.com",
  "schema.org",
];

/* ---------------------------------------------------------------
 * Description: ROUTES Default public and account  Routes
 * --------------------------------------------------------------- */
// Add default routes
export const DefaultRoutes = {
  mail_loading: "/mail/loading",
  mail_default: "/mail/inbox/1",
  remote_webapp_url: "https://www.preveil.com",
  drive_root: "/drive/root",
  drive_trash: "/drive/trash",
  settings_approvals: "/settings/approvals",
  admin_default: "/admin/users"
};

export const SystemRoutes = {
  create_organization_route: "create-organization",
  create_org_route: "create-org",
  page_not_found_route: "page-not-found",
};

export const PublicRoutes = {
  login_route: "login",
  get_started_route: "get-started",
  choose_account_route: "choose-account",
  create_account_route: "create-account",
  connect_account_route: "connect-account",
  claim_account_route: "claim-account",
  copy_account_route: "copy-account",
  add_account_route: "add-account",
  account_recovery_route: "account-recovery",
  account_backup_recovery_route: "backup-recovery",
  approval_group_route: "approval-group",
  upgrade_account_route: "upgrade-account",
  upgrade_information_route: "upgrade-information",
  upgrade_route: "upgrade", // legacy
  system_error_route: "system-error",
  device_locked_route: "device-locked",
  update_required_route: "update-required",
  logout_route: "logout",
  expired_key_route: "expired-key",
  forgot_password_route: "forgot-password",
  certificate_not_found_route: "certificate-not-found",
  account_locked_route: "account-locked"
};

/*
 * Description: Private Routes
 */
export const PrivateRoutes = {
  // MAIL NOTE: ***** These may get deprecated (dynamically added)
  mail_route: "mail",
  mail_inbox_route: "mail/inbox/1",
  mail_drafts_route: "mail/drafts/1",
  mail_sent_route: "mail/sent/1",
  mail_trash_route: "mail/trash/1",
  mail_starred_route: "mail/starred/1",
  mail_outbox_route: "mail/outbox/1",
  mail_unread_route: "mail/unread/1",
  mail_search_route: "mail/search/1",
  // ADMIN
  admin_route: "admin",
  users_route: "users",
  approval_groups_route: "approval-groups",
  trusted_community_route: "trusted-community",
  groups_route: "groups",
  requests_route: "requests",
  activity_logs_route: "activity-logs",
  data_export_route: "data-export",
  email_gateway_route: "email-gateway",
  device_settings: "device-settings",
  // SETTINGS
  settings_route: "settings",
  profile_route: "profile",
  add_device_route: "add-device",
  approvals_route: "approvals",
  activity_route: "activity",
  install_profile_route: "install-profile",
  device_management_route: "device-management",
  recovery_route: "recovery",
  recovery_requests_route: "recovery/requests",
  approve_recovery_route: "approve-recovery",
  account_backup_route: "account-backup",
  password_recovery_route: "password-recovery",
  change_password_route: "change-password",
  change_authentication_route: "change-authentication",
  // DRIVE mail_inbox_route
  drive_route: "drive",
  drive_root: "root", // My PreVeil
  shared_route: "shared",
  invitations_route: "invitations",
  trash_route: "trash",
  // CHAT
  chat_route: "chat",
  // DEVELOPER
  ui_guide_route: "ui-guide",
  sandbox_route: "sandbox",
  diagnostics: "diagnostics",
};

export const support_portal = "https://preveil.atlassian.net/wiki/spaces/ESD/pages";

// support routes
export const SupportRoutes = {
  getting_started: `${support_portal}/3204022274/Video+Getting+Started+Downloading+PreVeil`,
  using_drive: `${support_portal}/3204349961/Video+Intro+to+Drive`,
  accounts_recovery_settings: `${support_portal}/3379265537/PreVeil+Express+Account+Recovery+Guide`,
  security_privacy: `${support_portal}/2713026718/Optional+Organization+Security+Settings`,
  cookieless_download: `${support_portal}/2461794326/PreVeil+Express#%5BhardBreak%5DUpgrade%5BhardBreak%5D`,
  system_requirements: `${support_portal}/439812241/Minimum+System+Requirements+Supported+Operating+Systems+and+Browsers`,
  account_recovery_requirements: `${support_portal}/2218917889/Creating+a+Recovery+Code+File+for+Your+PreVeil+Encryption+Key`,
  mannually_add_apple_mail: `${support_portal}/1424588931/Manually+Adding+PreVeil+to+Apple+Mail`,
  manually_add_google: "https://chrome.google.com/webstore/detail/iidnmhhnpbahlmclnhhgdpfbpohjiepi",
  whats_new: `${support_portal}/3051782145/What+s+New+with+PreVeil+5.0.1`,
  handle_exe: `${support_portal}/2904457224/Desktop+PreVeil+Version+4.23.0`,
  recovery_group: `${support_portal}/316899338/Set+up+a+recovery+group+on+your+computer`,
  browser_password_management: `${support_portal}/3460726803/Browser+Password+Management+Instruction`,
  what_is_recovery_group: `${support_portal}/313655487/What+is+a+Recovery+Group`,
  about_data_export: `${support_portal}/1525645349/Admin+Console+-+Data+Export`,
  restarting_preveil_services: `${support_portal}/2388557829/Restarting+PreVeil+Services+via+the+Key+Menu+on+Mac`,
  troubleshooting_preveil: "https://preveil.atlassian.net/wiki/external/OTljOWMyMGM3YjA3NDM4YmEzZDM3NjViMzZhY2Q0NzA",
  device_certificate: `${support_portal}/3571712019/Trusted+Root+Certificates`,
  approval_groups: `${support_portal}/2425913346/Admin+Console+-+Approval+Groups`,
  email_groups: `${support_portal}/1483603988/Admin+Console+-+Email+Groups`,
  trusted_community: `${support_portal}/1481834516/Admin+Console+-+Trusted+Community`,
  terms_of_use: "https://www.preveil.com/terms-of-use/",
  privacy_policy: "https://www.preveil.com/privacy-policy/"
};

// Used for logo switching
// export const expressPublicUrls = ["/", "/login", "/create-account", "/upgrade-information", "/upgrade-account"];
export const defaultWebPublicUrls = ["/get-started", "add-account"]; // Add public pages here that should have the default logo for web

// Cross Platform Querystring param keys 
export const QueryParamKeys = {
  USER_ID_QUERY_KEY: "user_id",
  REDIRECT_URL_QUERY_KEY: "redirect_url",
  SECRET_QUERY_KEY: "secret",
  KEY_VERSION_KEY: "key_version",
  PAGE_ACTION_QUERY_KEY: "action",
  PAGE_NAME_QUERY_KEY: "name",
  PAGE_VIEW_QUERY_KEY: "view",
  PAGE_SHARE_QUERY_KEY: "share",
  PAGE_NONCE_QUERY_KEY: "nonce",
  PAGE_FEEDBACK_QUERY_KEY: "feedback",
  MANUAL_ADD_OPEN_QUERY_KEY: "manual_add",
  DEVICE_DELETED_QUERY_KEY: "device_deleted",
  APPROVAL_GROUP_IGNORE_STEP_QUERY_KEY: "ignore",
  SEARCH_RESULT_QUERY_KEY: "term",
  EXTERNAL_LINK_PARAM: "#external_link=",
};

export const QueryParamValues = keyMirror({
  new: null,
  recovered: null,
  added: null,
  existing: null,
  nonce: null,
  save: null,
  switch: null
});

// Settings constants for all modules
// Alert's self-close is based on time, so if a negative number then shouldn't close on its own and should prompt a close button
const GLOBAL_PAGINATION_PAGE_SIZE = 25;
export const GlobalConstants = {
  CRYPTO_PING_INTERVALS: process.env.NODE_ENV !== "production" ? 50000 : 5000, // Determines intervals for crypto check
  CRYPTO_PING_RETRY_MAX: 3,
  EXPRESS_REKEY_RETRY_MAX: 3,
  LOGIN_VERIFICATION_CODE_VALID: 15,
  EXPRESS_IDLE_TIMEOUT: 1800000, // time in ms - set to 30 mins = 30*60000
  EXPRESS_IDLE_MODAL_TIMEOUT: 5, // time in mins
  EXPRESS_AUTH_TOKEN_EXPIRATION: 900000, // time in ms - set to 15 mins = 15*60000
  CREATE_ACCOUNT_VERIFICATION_CODE_MAX: 6,
  EXPRESS_VERIFICATION_SMS_CODE_MAX: 6,
  TOASTR_DEFAULT_DELAY: 5000, // Set default delay for message toasts
  LEFT_NAV_MIN_WIDTH: 64,
  LEFT_NAV_EXPANDED_WIDTH: 250,
  LEFT_NAV_TOGGLE_WIDTH: 100,
  LIST_VIEW_MIN_WIDTH: 550,
  LIST_VIEW_TOGGLE_WIDTH: 400,
  PAGINATION_LINKS_MAX: 5,
  LIMIT_NUMBER_THREAD_TO_FETCH: GLOBAL_PAGINATION_PAGE_SIZE, // Pagination Limit for Mail  25 
  MAX_MAIL_SENDERS_PER_ROW: 2, // Number of senders in mail row to display 
  MAX_MOBILE_MAIL_COUNT: 99,
  MAX_USERS_WITHOUT_RECOVERY_COUNT: 99,
  MAX_GROUP_MEMBERS_TOOLTIP: 10,
  REQUESTS_PER_PAGE_COUNT: GLOBAL_PAGINATION_PAGE_SIZE,
  ACTIVITY_PER_PAGE_COUNT: 50
};


// New Constants to set limit in file handling, upload, etc
export const FileSizeLimits = {
  MAIL_UPLOAD_FILE_MAX_SIZE: 52428800, // Set to 50MB = 52428800 Bytes (binary) - 50*1024*1024  can set file size max in this options object
  MAIL_MAX_BLOCKS_PER_REQUEST: 5, // the max number of blocks to request at one time to CS when fetching blocks
  MAIL_MAX_BLOCKS_PER_UPLOAD: 1, // the max number of blocks to upload to CS when saving drafts
  DOC_VIEWER_IMAGE_SIZE_CAP: 60000,
  DRIVE_MAX_CONCURRENT_UPLOAD_REQUESTS: 1,
  DRIVE_UPLOAD_FILE_MAX_SIZE: 5368709120, // Set to 5GB = 5368709120 Bytes (binary) - 5*1024*1024*1024
  FILE_CHUNK_SIZE: 2097152, // Set to 2MB = 2097152 Bytes (binary) - 2*1024*1024
};

export const DriveLimits = {
  DRIVE_PAGINATION_PAGE_SIZE: 50,
  DRIVE_BULK_UPDATE_LIMIT_PER_CALL: 500
};

/*
 * Description: auth and account constants 
 */
export const AuthenticationConstants: { [key: string]: AccountSessionsType } = {
  USER_ID_SESSION_KEY: "pv_account",
  USER_ID_LOCALSTORAGE_KEY: "most_recent_user_id",
  USER_KEY_SESSION_KEY: "most_recent_user_key",
  DEVICE_SESSION_KEY: "most_recent_user_device",
  ACCOUNT_TYPE_SESSION_KEY: "account_type"
};

// Platform Specs - legacy DEVICE_PLATFORM
export const DevicePlatform: { [key: string]: Platform } = {
  macos: {
    text: "Mac OS",
    class: "mac",
  },
  ios: {
    text: "iOS",
    class: "mac",
  },
  android: {
    text: "Android",
    class: "android",
  },
  windows: {
    text: "Windows",
    class: "windows",
  },
  linux: {
    text: "Linux",
    class: "laptop",
  },
  session: {
    text: "Browser",
    class: "laptop",
  },
  express: {
    text: "Express",
    class: "express",
  },
  default: {
    text: "",
    class: "laptop",
  },
};

// Organization Roles
export const OrgUserRoles: { [key: string]: OrgRolesType } = keyMirror({
  admin: null,
  standard: null
});

export const OrgUserStatus: { [key: string]: OrgRolesType } = keyMirror({
  active: null,
  pending: null,
  suspended: null,
  deleted: null,
  invited: null,
});

export const UserListStatus: { [key: string]: UserListStatusType } = keyMirror({ // Legacy: USER_LIST_STATUS
  error: null,
  claimed: null,
  unclaimed: null,
  server_error: null,
  group: null,
  external: null,
  pending: null // NOTE: For users that have been invited to join an org but not accepted the invite and do not have an account
});

// Description: Store Collection Error codes for specific actions
// KSS Server codes:
export const KeystorageResponseErrorCodes = keyMirror({
  bad_password: null,
  bad_sms_code: null,
  bad_totp_code: null,
  bad_auth_header: null,
  no_sms_auth_header: null,
  no_totp_auth_header: null,
  no_email_auth_header: null,
  bad_account_claim_receipt: null,
  no_email_auth_header_and_prior_code_still_active: null,
  locked_out: null,
  phone_number: null,
  unauthorized: null,
  bad_email_code: null
});

// Collection Server codes:
export const CollectionResponseErrorCodes = keyMirror({
  unauthorized: null,
  //  more...
});

// FileSync Server codes:
export const CryptoResponseErrorCodes = keyMirror({
  unauthorized: null,
  //  more...
});

export const FilesyncResponseErrorCodes = keyMirror({
  unauthorized: null,
  //  more...
});

// Description: Type the  for  "/users/keyval" calls
export const CollectionKeyValCodes = {
  email_signature: "email-signature",

};

export const UUIDStringType = keyMirror({
  uuid: null,
  base64: null,
  unknown: null
});
export type UUIDStringTypes = keyof typeof UUIDStringType;

/* ------------------------------------------------------------------------------
 * Description: Mail
* ---------------------------------------------------------------------------- */
export const USER_IS_SELF_LABEL = "Me"; // Label User Email when is self

// Mailboxes
export const MailConstants: Record<string, string> = {
  DEFAULT_MAILBOX: "Inbox", // Legacy code DEFAULT_MAILBOX 
  DRAFTS_MAILBOX: "Drafts",
  SENT_MAILBOX: "Sent",
  TRASH_MAILBOX: "Trash",
  STARRED_MAILBOX: "Starred",
  OUTBOX_MAILBOX: "Outbox",
  UNREAD_MAILBOX: "Unread",
  SEARCH_MAILBOX: "Search"
};

export const DefaultMailboxes = keyMirror({
  inbox: null,
  drafts: null,
  sent: null,
  trash: null,
  starred: null,
  unread: null,
  outbox: null,
  custom: null,
  search: null,
  deleted: null // Collection role for Trash
});
export type DefaultMailboxesType = keyof typeof DefaultMailboxes | string;

export const MailboxIcons: { [key: string]: string } = {
  inbox: "ficon-mail",
  drafts: "ficon-file",
  sent: "ficon-send",
  trash: "ficon-trash-2",
  deleted: "ficon-trash-2",
  starred: "ficon-star",
  unread: "ficon-inbox",
  outbox: "ficon-package",
  custom: "ficon-folder"
};

// Custom mailboxes and folder tree
// Description: Additional Default  Mailboxes created by filters:  ["outbox", "starred", "unread"];
// NOTE: Outbox is not wired to be displayed in the UI as the mailbox calls are going directly to CS
export const ClientDefaultMailboxesType = ["outbox", "starred", "unread", "search"];
export const ClientDefaultMailboxes: { [key: string]: MailboxItem } = {
  outbox: {
    is_deleted: false,
    mailbox_id: DefaultMailboxes.outbox,
    name: capitalize(DefaultMailboxes.outbox),
    rev_id: 0,
    role: DefaultMailboxes.outbox
  },
  starred: {
    is_deleted: false,
    mailbox_id: DefaultMailboxes.starred,
    name: capitalize(DefaultMailboxes.starred),
    rev_id: -1,
    role: DefaultMailboxes.starred
  },
  unread: {
    is_deleted: false,
    mailbox_id: DefaultMailboxes.unread,
    name: capitalize(DefaultMailboxes.unread),
    rev_id: 0,
    role: DefaultMailboxes.unread
  },
  search: {
    is_deleted: false,
    mailbox_id: DefaultMailboxes.search,
    name: capitalize(DefaultMailboxes.search),
    rev_id: 0,
    role: DefaultMailboxes.search
  }
};

export const MailTreeIcon: { [key: string]: string } = {
  opened: "ficon-chevron-down",
  closed: "ficon-chevron-right",
};

export const FolderSyncIcon: { [key: string]: string } = {
  synced: "pv-icon-desktop",
  partially_synced: "pv-icon-desktop",
  unsynced: "pv-icon-desktop",
};

// Description: Virtual folders a concept in crypto for "mailboxes" created out of filters and flags
export const VIRTUAL_MAILBOXES_SERVER_IDS: { [key: string]: string } = {
  starred: "__STARREDBOX__",
  unread: "__UNREADBOX__",
  outbox: "__OUTBOX__",
  search: "__SEARCHBOX__"
};

// Mail Lists
export const MailFlags = {
  STAR_FLAG: "\\Flagged",
  DELETED_FLAG: "\\Deleted",
  SEEN_FLAG: "\\Seen",
  DRAFT_FLAG: "\\Draft",
  CS_SEEN_FLAG: "Seen",
  CS_STAR_FLAG: "Flagged",
};

// General UI 
// Pagination
export const SimplePaginationAction: { [key: string]: string } = keyMirror({
  first: null,
  last: null,
  previous: null,
  next: null
});
export type SimplePaginationActionType = keyof typeof SimplePaginationAction;

export enum SORT_DIRECTION {
  ascending = "asc",
  descending = "desc"
}

// NOTE: DragEvent types "DROP" | "DRAG_OVER" | "DRAG_LEAVE"
export const DragEventType = keyMirror({
  DROP: null,
  DRAG_OVER: null,
  DRAG_LEAVE: null,
  // DRAG_END: null // Add as needed
});
export type DragEventTypes = keyof typeof DragEventType;

// Mail Search
// Filters - Legacy: search-filters.class
export const SearchFilters: { [key: string]: string } = keyMirror({
  FullTextSearchFilter: null,
  TimeFilter: null,
  MailboxFilter: null,
  AttachmentFilter: null,
  RecipientFilter: null
});
export type SearchFiltersType = keyof typeof SearchFilters;

export const SearchResultFilter: { [key: string]: string } = keyMirror({
  all: null,
  to: null,
  from: null,
  message: null
});
export type SearchResultFilterType = keyof typeof SearchResultFilter;
export type SearchMatchesType = { [key: SearchResultFilterType]: string[] };

export const AttachmentContentDisposition: { [key: string]: string } = keyMirror({
  attachment: null,
  inline: null,
  attachline: null,
});

// Compose Mail Constants
export const ComposeModalSize = keyMirror({
  lg: null,
  fullscreen: null,
  minimized: null
});
export type ComposeModalSizeType = keyof typeof ComposeModalSize;

export const ComposeTypes = keyMirror({ // Legacy: COMPOSE_TYPES
  new: null,
  reply: null,
  reply_all: null,
  forward: null,
  draft: null
});
export type ComposeTypesType = keyof typeof ComposeTypes;

export const RecipientKeys = keyMirror({ // Legacy: COMPOSE_TYPES
  tos: null,
  ccs: null,
  bccs: null,
});
export type RecipientKeysType = keyof typeof RecipientKeys;

// ---------------------------------------------------------------
// Websocket Actions and Transfer Types
// ---------------------------------------------------------------
// Transfer Account types - Websocket communication
export enum TransferAccountTypes {
  Recovery = "key_recovery",
  TransferKey = "key_transfer",
  Export = "export_approval",
}

// Account Server Action types
export enum TransferAccountActions {
  ConnectHandshake = "connect_handshake",
  Connect = "connect",
  KeyTransfer = "key_transfer",
  Ok = "ok",
}

// Description: connect account steps of messages:
export enum TransferAccountSteps {
  DISCONNECTED,
  INITIALIZE_CONNECTION, // 1. sends: actions: "connect_handshake" ...
  GET_ACCEPTED_PROTOCOL_VERSIONS, // 2. gets data: accept_protocols
  SEND_ACTION_CONNECT, // 3. send: action: "connect" ...
  GET_HASH, // 4. gets data: protocol_version, public_key_hash
  GET_PUBLIC_KEY, // 5. gets data: message, user_id
  SEND_KEY_TRANSFER, // 6.  sends: action: "key_transfer" ...
  SET_PIN_CONFIRMATION_KEY_TRANSFER, // 7. gets: data: user_id
  GET_VALIDATED_KEY_TRANSFER, // 8. gets: data:  device,	device_signature,	message, signing_device_id,	user_id, user_signature
  SEND_COMPLETE_LOGIN, // 9. Complete Login 
  ERROR
};

// Description: General WS steps and process: 
// in pages like: add device, approve recover, copy account steps of messages:
export enum AccounWStSteps {
  ERROR,
  DISCONNECTED,
  INITIALIZE_CONNECTION, // 1. sends: actions:"init" - 
  GET_ACCEPTED_INIT_OK, // 2. Gets action: "ok"
  GET_CONNECTION_ESTABLISHED,// 3. Gets action: "ok"
  GET_KEY_TRANSFER_COMPLETE, // 4. Gets action: key_transfer_complete === success
};

// Description: copy account steps of messages:
export enum ApprovalGroupAccountRecoverySteps {
  LOADING,
  SUBMIT_EMAIL,
  SUCCESS,
  ERROR
};

// Description: General WS steps and process for Notify
export enum NotifyWStSteps {
  ERROR,
  DISCONNECTED,
  INITIALIZE_CONNECTION,
  PROCESS_CHALLENGE,
  EXPECTING_RESULT,
  PROCESS_NOTIFICATIONS
};

/*
 * UI Specific constants
 */
export const InitialPaginationState: PaginationItem = {
  pageIndex: 0,
  pageSize: GLOBAL_PAGINATION_PAGE_SIZE,
  pageCount: 0,
  totalRows: 0,
  offset: 0,
  isLastPage: true
};

export const UIGridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};
export const MobileMenuDisplay: boolean = window.innerWidth <= UIGridBreakpoints.md;
export const DesktopMenuDisplay: boolean = window.innerWidth > UIGridBreakpoints.md;

// Description: Set UI navigation types
export const PanelState = keyMirror({
  on: null,
  off: null
});
export type PanelStateType = keyof typeof PanelState;

export const NavigationPath = keyMirror({
  MailNavigation: null,
  DriveNavigation: null,
  SettingsNavigation: null,
  AdminNavigation: null,
  ChatNavigation: null,
  DefaultNavigation: null // catch all for independent / public components
});
export type NavigationPathTypes = keyof typeof NavigationPath;

// Description: Split Panes
export const SplitPaneFullWidth = "full";
export type SplitPaneWidth = number | typeof SplitPaneFullWidth | undefined;

// Description: Checkbox states in tables and lists
export const CheckboxStates = keyMirror({
  checked: null,
  indeterminate: null,
  empty: null,
});
export type CheckboxStatesTypes = keyof typeof CheckboxStates;

// Description: Handle message handler types - might get moved to messaging store //***** WORKING *****//
export const MessageHandlerDisplayType: { [key: string]: MessageDisplayType } = keyMirror({
  dialog: null,
  confirm: null,
  toastr: null, // User toatr to show error
  inline: null, // write message inline
  logger: null, // write message in console.log
  muted: null // pass message but not to user - stub for logging later
});

// Description: Handle message handler types - might get moved to messaging store //***** WORKING *****//
// NOTE: type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
export const MessageToastTypes: { [key: string]: Variant } = keyMirror({
  primary: null,
  secondary: null,
  success: null,
  danger: null,
  warning: null,
  info: null,
  dark: null,
  light: null,
  error: "danger"
});

// Description: Account States in process of creating account (web and app)
export enum CreateAccountSteps {
  LOADING,
  SUBMIT_EMAIL,
  SUBMIT_VERIFICATION_CODE,
  SUBMIT_USER_INFORMATION,
  SUBMIT_PASSWORD,
  PICK_2FA_METHOD,
  SUBMIT_PHONE,
  SUBMIT_SMS_CODE,
  SUBMIT_TOTP_CODE,
  SUBMIT_ERROR,
  CERTIFICATE_NOT_FOUND,
}

// Description: Account States in process of creating account (web and app)
export enum ChangeAuthenticationSteps {
  LOADING,
  SUBMIT_PASSWORD,
  CURRENT_AUTHENTICATION
}

export enum ChangeAuthenticationModalSteps {
  LOADING,
  SUBMIT_PHONE_NUMBER,
  SUBMIT_SMS_CODE,
  SUBMIT_TOTP_CODE,
  SUBMIT_TOTP_CURRENT_CODE,
}

// Description: Account States in process of creating account (web and app)
export enum ChangePhoneNumberSteps {
  LOADING,
  SUBMIT_PASSWORD,
  SUBMIT_SMS_CODE,
  SUBMIT_PHONE_NUMBER
}

// Description: Account States in process of creating account (web and app)
export enum LoginWebSteps {
  LOADING,
  SUBMIT_EMAIL,
  SUBMIT_PASSWORD,
  SUBMIT_SMS_CODE,
  SUBMIT_TOTP_CODE,
}

export enum ForgotPasswordSteps {
  LOADING,
  SUBMIT_EMAIL,
  SUBMIT_EMAIL_CODE,
  SUBMIT_SMS_CODE,
  SUBMIT_TOTP_CODE,
  PASTE_RECOVERY_CODE,
  CREATE_NEW_PASSWORD,
  NO_RECOVERY_SETUP
}

export enum RecoverAccountSteps {
  LOADING,
  SUBMIT_RECOVER_CODE,
  SUBMIT_PASSWORD,
  PASSWORD_SUBMITTED,
  PAGE_ERROR
}

export enum ApprovalGroupSteps {
  LOADING,
  SUBMIT_ACKNOWLEDGE_INFO,
  PROCESS_APPROVERS,
  GET_SHARD,
  SUBMIT_RECOVERY,
  SUBMIT_SUCCESS,
  PAGE_ERROR
}

// Description:  Form validation constants
export const FormValidationLimits = {
  EMAIL_MAX_LENGTH: 254,
  FIRST_NAME_MAX_LENGTH: 100,
  LAST_NAME_MAX_LENGTH: 100,
  VERIFICATION_CODE_LENGTH: 6,
  PASSWORD_MIN_LENGTH: 10,
  PASSWORD_OLD_MIN_LENGTH: 8,
  PHONE_MIN_LENGTH: 5,
  PHONE_MAX_LENGTH: 20
};

// Description: Welcome Modal Constants
export const WelcomeModalStates = keyMirror({
  page_one: null,
  page_two: null,
  close: null,
});
export type WelcomeModalType = keyof typeof WelcomeModalStates;


export const SupportedPlatform = {
  OSX: "osx",
  WIN: "win",
};


// Key Codes being used for UI actions
export const KeyCode = keyMirror({
  Tab: null,
  ArrowDown: null,
  ArrowUp: null,
  Enter: null,
  Backspace: null
});

const pdftron_licenseKey = "PreVeil, Inc. (preveil.com):OEM:Prevail Legal::B+:AMS(20230420):B3A54E720427480AF360B13AC982537860617F85DF102A239D12ECC2C7ED60B2329431F5C7";
const pdftron_path = "/webviewer/lib";
export const PDFNETJS_WEBVIEWER_OPTIONS = process.env.REACT_APP_BUILD_MODE !== BuildMode.app ? {
  licenseKey: pdftron_licenseKey,
  path: pdftron_path,
  fullAPI: true,
  disableObjectURLBlobs: true,
  backendType: "asm"
} : {
  licenseKey: pdftron_licenseKey,
  path: pdftron_path,
  fullAPI: true
};


/* ------------------------------------------------------------------------------
 * Description: Settings
 * ---------------------------------------------------------------------------- */
export const RequestTypes = {
  change_approval_group: "change_approval_group",
  rekey_and_change_approval_group: "rekey_and_change_approval_group",
  set_member_approval_group: "set_member_approval_group",
  member_rekey_and_set_approval_group: "member_rekey_and_set_approval_group",
  change_org_approval_group_role: "change_org_approval_group_role",
  change_admin_status: "change_admin_status",
  delete_user: "delete_user",
  export: "export",
  subsume_account: "subsume_account",
};

export const DataExportClosedStates: string[] = ["deleted", "completed"];

export const ApprovalRequestStatus = keyMirror({
  pending: null,
  approved: null,
  failed: null,
  denied: null,
});
export type ApprovalRequestStatusType = keyof typeof ApprovalRequestStatus;

export const ApprovalRequestStatusMapping = {
  null: "All",
  pending: "Pending Approval",
  approved: "Approval Approved",
  failed: "Approval Failed",
  denied: "Approval Denied",
};

export const RequestTab = keyMirror({
  active: null,
  pending: null,
  history: null,
});

export const AdminApprovalGroupRoleMapping = {
  admin_approval_group: {
    name: "Admin Management",
    all_admin: true,
    details: `
      <p><b>Admin management</b> is a trusted group to approve Organization level actions. These actions include setting user recovery group and assigning approval groups to activities.</p>
      <p><b>NOTE:</b> All approvers assigned to Admin Management must be administrators.</p>
    `
  },
  export_approval_group: {
    name: "Data Export",
    all_admin: false,
    details: `
      <p><b>Data Export</b> is a feature of PreVeil to export the data of your organization.</p>
      <p>Data Exports require approval to initiate, this assigns the group in charge of overseeing that export.</p>
    `
  }
};

export const AdminApprovalGroupRoles = ["admin_approval_group", "export_approval_group"];

export const DeviceStatus = {
  current_device: {
    text: "Current Device",
    class: "current",
  },
  active: {
    text: "Active",
    class: "active",
  },
  inactive: {
    text: "Active",
    class: "active",
  },
  locked: {
    text: "Locked",
    class: "locked",
  },
  pending_unlocked: {
    text: "Pending Unlocked",
    class: "locked",
  },
};

export const DeviceAction = keyMirror({
  lock: null,
  delete: null
});

export const DeviceActionInfo = {
  lock: {
    title: "Lock Your PreVeil Device",
    warning: "WARNING: Locking a device will disable access to your account from that device.",
    info: "You may unlock at any time from an unlocked device with PreVeil installed."
  },
  delete: {
    title: "Delete Your PreVeil Device",
    warning:
      "WARNING: Deleting a device will permanently disable access to your account from that device.",
    info: "You may have to recover your current account from one of your devices that has access to this account."
  },
  disconnect: {
    title: "Disconnect Device",
    warning:
      "WARNING: Disconnecting your account will permanently disable access to your account from that device.",
    info: "After disconnecting, you will need recover your current account from one of your other devices that has access to this account."
  }
};

/* ------------------------------------------------------------------------------
 * Description: Admin
 * ---------------------------------------------------------------------------- */
export const TopicMap = {
  All: {
    name: "",
    action_list: [],
    action_map: {},
  },
  Admin: {
    name: "admin",
    action_list: [
      "All",
      "Create Organization",
      "Users",
      "Approval Group",
      "Approval Request",
      "Whitelist",
    ],
    action_map: {
      All: [],
      "Create Organization": ["create_organization"],
      Users: ["invite_users_to_org", "delete_user", "update_account"],
      "Approval Group": ["create_approval_group", "apply_approval_group"],
      "Approval Request": [
        "create_approval_request",
        "approver_responded_to_request",
        "delete_approval_request",
      ],
      Whitelist: ["add_to_whitelist", "remove_from_whitelist", "enable_whitelist"],
    },
  },
  System: {
    name: "system",
    action_list: [
      "All",
      "Users",
      "Approval Group",
      "Approval Request",
      "Approval Recovery",
      "Device",
    ],
    action_map: {
      All: [],
      Users: ["claim_account", "invite_to_preveil", "invitee_joined_preveil"],
      "Approval Group": [
        "set_approval_group",
        "added_to_approval_group",
        "rekey_and_set_approval_group",
      ],
      "Approval Request": [
        "create_approval_request",
        "approval_requested",
        "approver_responded_to_request",
        "responded_to_request",
        "delete_approval_request",
        "delete_pending_approval",
      ],
      "Approval Recovery": [
        "begin_account_recovery",
        "rg_member_retrieved_shard",
        "retrieve_users_shard",
      ],
      Device: ["register_device", "lock_device", "unlock_device", "delete_device"],
    },
  },
  Email: {
    name: "email",
    action_list: [],
    action_map: {},
  },
  Drive: {
    name: "drive",
    action_list: ["All", "Edit", "Share"],
    action_map: {
      All: [],
      Edit: [
        "create_collection",
        "create_collection_node",
        "delete_collection_node",
        "update_collection",
        "rename_file",
        "move_file",
        "rename_directory",
        "move_directory",
      ],
      Share: ["share_collection", "respond_to_share_invitation", "share_response"],
    },
  },
  Group: {
    name: "group",
    action_list: [],
    action_map: {},
  },
};

export type OrganizationInformation = {
  org_info: JSONOrgInfo;
  approval_groups: ApprovalGroup[];
  org_users: CollectionServerUser[];
  total_request_rows: number;
  org_requests: UserRequest[];
  export_request?: any; // TO DO: leaving this untyped for now as it will be used while working on the Data Export ticket (NEW-123)
  approver_shards?: string[];
};

export const OrganizationLevelSettings = keyMirror({
  storage_backend: null,
  require_biometrics: null,
  disable_file_export: null,
  present_cui_notice: null,
  sync_collections_by_default: null,
});

/* ------------------------------------------------------------------------------
 * Description: Misc Drive and Docs for admin
 * ---------------------------------------------------------------------------- */
// NOTE: Drive constants for settings
export const DriveLogTypeMapping = {
  directory: "Folder",
  file: "File",
  link: "Shared Folder",
};
export type DriveLogEntryType = "directory" | "link" | "file";

export const EVENT_TYPES: { [key: string]: EventType } = {
  SET_APPROVAL_GROUP: "set_approval_group",
  REKEY_AND_SET_APPROVAL_GROUP: "rekey_and_set_approval_group",
  SUBMIT_SHARDS_TO_EXPORT_GROUP: "submit_shards_to_export_group",
  RENAME_COLLECTION: "rename_collection"
};

export const DRIVE_INVALID_CHARACTERS: string[] = [
  // Browser restraints
  "&",
  // Windows & OSX restraints
  "<",
  ">",
  ":",
  // eslint-disable-next-line quotes
  `"`,
  "/",
  "\\",
  "|",
  "?",
  "*"
];
// -------------------------------------- //

export const AuthenticationTypes = keyMirror({
  sms: null,
  totp: null,
});
/* ------------------------------------------------------------------------------
 * Description: TREE VIEWS: This serves the TreeComponent used in Mail and Drive
 * ---------------------------------------------------------------------------- */
export interface TreeItem {
  type: "folder" | "file" | string;
  name: string;
  path: string;
  id?: string;
  url_identifier?: string;
  children?: TreeItem[];
  disabled?: boolean;
  active?: boolean;
  children_loading?: boolean;
  parent?: TreeItem;
  current_sync_status?: SELECTIVE_SYNC_STATES;
  should_sync?: SELECTIVE_SYNC_STATES;
  collection_id?: string;
  linked_collection_id?: string;
  expanded?: boolean;
}

// This serves the TreeComponent used in Mail and Drive
export interface TreeModalActions {
  type: string;
  selected?: TreeItem;
  new_name?: string;
}

export const TreeItemType: { [key: string]: string } = keyMirror({
  folder: null,
  file: null
});

export const treeRoot = {
  id: "root",
  name: "root",
  type: TreeItemType.folder,
  path: "",
  children: []
};

// ---------------------------------------------------------------
// Admin - Data Export: Different states of the data export request
// ---------------------------------------------------------------
export const DataExportRequestStatus = keyMirror({
  not_init: null, // not approval groups assigned yet.
  not_started: null, // no data export request has been made.
  init: null, // data export request has been made and is pending approval.
  pending: null, // data export request is pending approval (at least an approved has been made).
  started: null, // data export request has been approved and started.
  running: null, // data export download is running.
  complete: null, // data export download is complete.
  cancelled: null // data export download is cancelled.
});
