import * as React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { uiActions } from "src/store";
import {
  findInvalidAddress,
  findDuplicateAddress,
  AdminUIActionTypes,
  useAppDispatch,
  Message,
  MessageHandlerDisplayType,
  EmailGatewayEntity,
  EmailGateway
} from "src/common";
import { Icon, AddressListModalPanel } from "src/components";
import { Form, Row, Button, Col } from "react-bootstrap";
import _ from "lodash";
import * as yup from "yup";
import { Formik, FormikState } from "formik";

type IndividualFormComponentProps = {
  savedEmailGatewayAddressList?: string[];
  handleActions: ActionHandlerFunction;
}

function IndividualFormComponent({ savedEmailGatewayAddressList, handleActions }: IndividualFormComponentProps) {
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const [emailGatewayAddressList, setEmailGatewayList] = React.useState<EmailGateway[]>([]);
  const dispatch = useAppDispatch();
  const errorMessage = "The entry should be in an email or domain format, e.g. john@gmail.com, outlook.com";
  const schema = yup.object().shape({
    alias: yup
      .string()
      .required("The entry (email address or domain) is required.")
      .test("alias-field", errorMessage, (value) => {
        if (value) {
          return findInvalidAddress([value]).length === 0;
        }
        return !!value;
      })
  });

  // Description: Handles and Validates user input address/domain
  // If address is a duplicate (either from saved data or already present in the array) it will warn the user.
  function handleAddAddress(value: { alias: string, name: string }, resetForm: (nextState?: Partial<FormikState<any>>) => void) {
    const { alias, name } = value;
    const currentListOfAddresses = _.map(emailGatewayAddressList, "email_address");
    const totalAddresses = savedEmailGatewayAddressList && savedEmailGatewayAddressList.length >= 1 ? [...savedEmailGatewayAddressList, ...currentListOfAddresses] : currentListOfAddresses;
    const isDuplicateFound = findDuplicateAddress(totalAddresses, [alias]);
    if (isDuplicateFound) {
      // Warn user address is duplicated.
      dispatch(uiActions.handleRequestErrors(new Message(`${alias} is already in the list`, MessageHandlerDisplayType.toastr)));
      setIsFormValid(false);
    } else {
      const gatewayAddress = new EmailGatewayEntity(alias, name);
      setEmailGatewayList(prevArray => [...prevArray, gatewayAddress]);
      setIsFormValid(true);
      resetForm();
    }
  }

  function handleDeleteAction(id?: string) {
    const filteredAddressList = emailGatewayAddressList.filter((address) => address.email_address !== id);
    setEmailGatewayList(filteredAddressList);
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();
    const formattedEmailGatewayList = emailGatewayAddressList.map((address) => {
      return address.aliasFormat();
    });
    handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: formattedEmailGatewayList });
  }

  const isEmailGatewayListEmpty = emailGatewayAddressList.length === 0;
  return (
    <Formik
      validationSchema={schema}
      onSubmit={() => { }}
      initialValues={{
        alias: "",
        name: "",
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, resetForm }) => (
        <Form noValidate className="m-2 gateway-aliases-form">
          <Row className="align-items-baseline">
            <Form.Group as={Col} xs="6" className="mb-4">
              <Form.Label>
                <p className="m-0">
                  Address/Domain
                </p>
              </Form.Label>
              <Form.Control
                id="alias_tb"
                type="text"
                name="alias-field"
                onChange={handleChange("alias")}
                value={values.alias}
                onBlur={handleBlur("alias")}
                isInvalid={touched.alias && !!errors.alias}
              />
              {touched.alias && !!errors.alias ? (
                <p className="error-message">{errors.alias}</p>
              ) : null}
            </Form.Group>
            <Col xs="5" className="mb-4">
              <Form.Group>
                <Form.Label htmlFor="name_tb">
                  Name <span className="text-muted gateway-extra-info-label">(optional)</span>
                </Form.Label>
                <Form.Control
                  id="name_tb"
                  type="text"
                  name="name-field"
                  onChange={handleChange("name")}
                  value={values.name}
                />
              </Form.Group>
            </Col>
            <Col xs="1" className="add-address-btn">
              <Form.Label className="opacity-0 mt-1">add</Form.Label>
              <Button
                id="add_alias"
                variant="secondary"
                onClick={() =>
                  handleAddAddress({ alias: values.alias, name: values.name }, resetForm)
                }
                className="btn-icon mt-auto mb-auto font-size-sm"
                disabled={!!errors.alias || !touched.alias} >
                <Icon className="icon-plus2" />
              </Button>
            </Col>
          </Row>
          {!isEmailGatewayListEmpty && (
            <Row className="mt-4">
              <AddressListModalPanel
                collection={emailGatewayAddressList}
                handleDeleteAction={handleDeleteAction}
                validation_state="valid_gateway"
              />
            </Row>
          )}
          <footer>
            <Button
              id="submit_button"
              type="submit"
              className="me-2"
              disabled={!isFormValid || isEmailGatewayListEmpty}
              onClick={(e) => {
                handleSubmit(e);
                resetForm();
              }}
            >
              Add
            </Button>
            <Button
              id="cancel_form"
              onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}
              variant="no-outline-primary"
            >
              Cancel
            </Button>
          </footer>
        </Form>
      )}
    </Formik>
  );
}

export default React.memo(IndividualFormComponent);
