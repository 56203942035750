import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, Dropdown, Tabs, Tab, Form, Row, Col, Alert, Button, Toast, Badge } from "react-bootstrap";
import { useFormik } from "formik";
import { ActionHandlerFunction, AdminUserEntity, DeviceBase } from "@preveil-api";
import {
  Account, AdminUIActionTypes, PrivateRoutes, account_types, OrgUserStatus, GetUserDevicesApiResponse, useGetUserDevicesMutation, Message,
  SettingsErrorMessages, useAppDispatch, useAppSelector, OrgUserRoles,
  isSameUser,
  usePostUsersOrgsByEntityIdAllowAddDeviceMutation, AdminRecoveryGroup
} from "src/common";
import { InfoPopover, Icon, AlertWithSwitch } from "src/components";
import { DeviceListPanel, ApproversInfoCard } from ".";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import * as Yup from "yup";
import _ from "lodash";

type AllProps = {
  total_admin_users: number;
  currentSelectedUser: AdminUserEntity;
  show: boolean;
  currentRecoveryGroup: AdminRecoveryGroup | null;
  recovery_groups: AdminRecoveryGroup[];
  handleActions: ActionHandlerFunction;
  allow_add_devices?: boolean;
  add_device_overrides: string[];
};

// Note: Invited and Pending status all fields are disabled.
const readOnlyStates: Record<string, boolean> = {
  pending: true,
  admin: true,
  invited: true
} as const;

function EditUserSidePanel({ total_admin_users, currentSelectedUser, currentRecoveryGroup, show, recovery_groups, handleActions, allow_add_devices, add_device_overrides }: AllProps) {
  const admin_account = useAppSelector((state: RootState) => state.account.current_account);
  const invalidMessage = "The department is required";
  const { userName, userEmail, role, devices, status, department, accountType, updatesPending, subsume_request_id } = currentSelectedUser;
  const [userDevices, setUserDevices] = useState<DeviceBase[] | []>([]);
  const [assignedRole, setAssignedRole] = useState<string>("");
  const [loading_devices, setLoadingDevices] = useState<boolean>(false);
  const [selectedRecoveryGroup, setSelectedRecoveryGroup] = useState<AdminRecoveryGroup | null>(null);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const isReadOnly = readOnlyStates[role] || readOnlyStates[status];
  // If there is only one admin user in the org and the current user is an admin, disable the role change.
  const disable_role_change = total_admin_users === 1 && currentSelectedUser.role === OrgUserRoles.admin;
  const disabled = isReadOnly && role.toLowerCase() !== "admin";
  const devicesTotal = !!devices && _.isArray(devices) ? devices.length : 0;
  const devicesTotalLabel = devicesTotal <= 1 ? "Device" : "Devices";
  const iconTooltip = accountType === account_types.full ? "Full Account User" : accountType === account_types.express ? "Express User" : "User has not accepted the invitation to join";
  const updatesPendingMessage = updatesPending && updatesPending > 1 ? `${updatesPending} updates are awaiting approval` : `${updatesPending} update is awaiting approval`;
  const [postUsersOrgsByEntityIdAllowAddDevice] = usePostUsersOrgsByEntityIdAllowAddDeviceMutation();
  const [add_device_allowed_user, setAddDeviceAllowedUser] = useState<boolean>(false);

  useEffect(() => {
    const allowed = allow_add_devices || !!add_device_overrides.find(user => isSameUser(user, currentSelectedUser.userEmail));
    setAddDeviceAllowedUser(allowed);
  }, [allow_add_devices, add_device_overrides, currentSelectedUser]);

  // Formik setup for department field, for easier validation.
  const formik = useFormik({
    validationSchema: Yup.object().shape({
      departmentField: Yup.string().required(invalidMessage)
    }),
    initialValues: {
      departmentField: department,
    },
    enableReinitialize: true,
    onSubmit: () => { }
  });

  useEffect(() => {
    getUserDevices();
    setAssignedRole(role);
  }, [currentSelectedUser]);

  useEffect(() => {
    if (currentRecoveryGroup) {
      setSelectedRecoveryGroup(currentRecoveryGroup);
    }
  }, [currentRecoveryGroup]);

  useEffect(() => {
    const departmentFieldChanged = formik.dirty && !!formik.values.departmentField;
    const roleChanged = assignedRole !== currentSelectedUser?.role;
    const recoveryGroupChanged = selectedRecoveryGroup?.name !== currentRecoveryGroup?.name;
    if (departmentFieldChanged || roleChanged || recoveryGroupChanged) {
      setEnableSave(true);
    } else if (enableSave) {
      setEnableSave(false);
    }
  }, [formik.values.departmentField, assignedRole, selectedRecoveryGroup]);

  // Note: handle dropdown change for user role.
  function handleUserRoleDropdownChange(event: React.MouseEvent<HTMLElement>) {
    const { id: roleId } = event.target as HTMLElement;
    const selectedRole = roleId;
    setAssignedRole(OrgUserRoles[selectedRole]);
  }
  // NOTE: handle dropdown change for recovery group.
  function handleDropdownChange(event: React.MouseEvent<HTMLElement>) {
    const { id: groupId } = event.target as HTMLElement;
    const selectedGroup = recovery_groups.filter((group) => group.groupId === groupId);
    if (selectedGroup.length === 1) {
      setSelectedRecoveryGroup(selectedGroup[0]);
    }
  }

  // Note: handle hide modal.
  function handleHideModal() {
    handleActions({ actionType: AdminUIActionTypes.ShowSideNavMenu });
    // Reset fields to original values.
    if (currentRecoveryGroup && currentRecoveryGroup?.name !== selectedRecoveryGroup?.name) {
      setSelectedRecoveryGroup(currentRecoveryGroup);
    } else if (!currentRecoveryGroup) {
      setSelectedRecoveryGroup(null);
    }
    if (assignedRole !== currentSelectedUser?.role) {
      setAssignedRole(currentSelectedUser.role);
    }
    if (formik.dirty) {
      formik.resetForm();
    }
  }

  // Description: handles submit for updating user role, department and recovery group.
  // if any or all of the fields are changed, it will send back an object with the updated fields params.
  // the field will be set to null inside the object (updatedUser) if it's not changed.
  function handleSubmit() {
    const { departmentField } = formik.values;
    const { role: currentRole } = currentSelectedUser;
    const role = assignedRole;
    const recoveryGroupChanged = currentRecoveryGroup?.name !== selectedRecoveryGroup?.name;
    const roleChanged = role !== currentRole;
    const departmentChanged = departmentField !== department;

    if (recoveryGroupChanged || roleChanged || departmentChanged) {
      const updatedUser = {
        ...currentSelectedUser,
        role: roleChanged ? role : null,
        department: departmentChanged ? departmentField : null,
        recoveryGroup: recoveryGroupChanged ? selectedRecoveryGroup : null
      };
      setEnableSave(false);
      handleActions({ actionType: AdminUIActionTypes.UpdateUser, params: updatedUser });
    }
  }

  const userRole = _.capitalize(OrgUserRoles[assignedRole]) || "";
  const recoveryGroupName = selectedRecoveryGroup?.name ? selectedRecoveryGroup.name : "";
  const recoveryGroupApprovers = selectedRecoveryGroup?.approvers ? selectedRecoveryGroup.approvers : [];
  const dispatch = useAppDispatch();
  const [getDevices] = useGetUserDevicesMutation();

  async function getUserDevices() {
    // Preveil/subsume_accounts return 403 when on invited status and trying to fetch devices.
    // Non Preveil/external accounts return an empty array.
    const is_invited_status = currentSelectedUser.status === OrgUserStatus.invited;
    if (!!admin_account && !!currentSelectedUser.userEmail && !is_invited_status) {
      setLoadingDevices(true);
      await getDevices({ userId: admin_account.user_id, forUser: currentSelectedUser.userEmail })
        .unwrap()
        .then((response: GetUserDevicesApiResponse) => {
          setUserDevices(response);
          setLoadingDevices(false);
        })
        .catch((msg: string) => {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_fetching_devices),
              msg,
            ),
          );
          setLoadingDevices(false);
        });
    } else if (is_invited_status) {
      // If the user is in invited status, we set the devices to an empty array.
      // Otherwise it could keep the devices from the last user selected.
      setUserDevices([]);
    }
  }

  // Description: Overrides the add device setting for this user.
  async function toggleAddDeviceOverride() {
    if (!!admin_account && !!admin_account.org_info) {
      const { org_info } = admin_account;
      const is_allowed = !add_device_allowed_user;
      await postUsersOrgsByEntityIdAllowAddDevice({
        account_ids: Account.getAccountIdentifiers(admin_account),
        body: {
          entityId: org_info.org_id,
          is_allowed,
          userId: currentSelectedUser.userEmail
        }
      })
        .unwrap()
        .then(() => {
          setAddDeviceAllowedUser(!add_device_allowed_user);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  function deviceInfoMessage(): string {
    return add_device_allowed_user ?
      "Disabling this option will prevent the user from adding new devices to their account." :
      "Enabling this option will allow this user to add new devices to their account.";
  }

  return (
    <Offcanvas
      className="sidebar wide-panel user-sidebar"
      show={show}
      placement="end"
      backdrop={false}
      onHide={handleHideModal}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title as="h2">
          <Icon
            tooltip={iconTooltip}
            className={`${accountType === account_types.full ?
              "icon-user-plus" : accountType === account_types.express ? "icon-user" : "icon-user-plus inactive"}`} />
          {userName}

        </Offcanvas.Title>
        {
          (currentSelectedUser.status === OrgUserStatus.invited || currentSelectedUser.status === OrgUserStatus.pending) &&
          <Badge bg="info">{currentSelectedUser.status}</Badge>
        }
      </Offcanvas.Header>
      <Offcanvas.Body>
        {
          !!updatesPending ?
            <Link to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.requests_route}`}
              className="user-update">{updatesPendingMessage}</Link> :
            (currentSelectedUser.status === OrgUserStatus.invited &&
              <Link to={`/${PrivateRoutes.admin_route}/${PrivateRoutes.requests_route}/${subsume_request_id}`}>
                <Toast bg="warning" show={true} className="mt-0 mb-3">
                  <Toast.Body>
                    This user has not yet accepted the invitation to join your organization.
                  </Toast.Body>
                </Toast></Link>
            )
        }
        <Tabs defaultActiveKey="account" id="user-account">
          <Tab eventKey="account" title="Account">
            <div className="user-sidenav-container">
              <div className="mb-3">
                <p>
                  <span>Name</span> {userName}
                </p>
                <p>
                  <span>Email Address</span> {userEmail}
                </p>
                <p className={`${readOnlyStates ? "border-top" : ""} text-capitalize`}>
                  <span>Status</span> {status}
                </p>
                {(disabled || disable_role_change) &&
                  <p className="text-capitalize">
                    <span>Role</span> {role}
                  </p>
                }
              </div>
              <Form className="user-form">
                {(!disabled && !disable_role_change) &&
                  <Form.Group as={Row}>
                    <Col sm={2}>
                      <Form.Label className="h5">Role</Form.Label>
                    </Col>
                    <Col sm={10} className="ps-sm-5">
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary">
                          {!!role ? `${userRole} User` : "Select User Role"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item id="standard" onClick={handleUserRoleDropdownChange}>Standard User</Dropdown.Item>
                          <Dropdown.Item id="admin" onClick={handleUserRoleDropdownChange}>Admin User</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Form.Group>
                }
                <Form.Group as={Row} className="align-items-start">
                  <Col sm={2}>
                    <Form.Label className="h5">Department</Form.Label>
                  </Col>
                  <Col sm={10} className="ps-sm-5">
                    <Form.Control
                      type="text"
                      name="department-field"
                      placeholder="Department"
                      disabled={disabled}
                      onChange={formik.handleChange("departmentField")}
                      onBlur={formik.handleBlur("departmentField")}
                      value={formik.values.departmentField}
                      isInvalid={formik.touched.departmentField && !!formik.errors.departmentField}
                    />
                    {
                      formik.touched.departmentField && !!formik.errors.departmentField &&
                      <Form.Text className="invalid-feedback">
                        {invalidMessage}
                      </Form.Text>
                    }
                  </Col>
                </Form.Group>
              </Form>
              <section>
                <div className="recovery-group-dropdown">
                  <header>
                    <h5>Recovery Group</h5>
                  </header>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary" disabled={disabled}>
                      {!!recoveryGroupName ? `${recoveryGroupName}` : "Select Recovery Group"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      {recovery_groups.map((recoveryGroup) => {
                        const { groupId } = recoveryGroup;
                        return (
                          <Dropdown.Item key={groupId} id={groupId} onClick={handleDropdownChange}>
                            {recoveryGroup.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  {recoveryGroupApprovers.length >= 1 && recoveryGroupName &&
                    <ApproversInfoCard approvers={recoveryGroupApprovers} groupName={recoveryGroupName} />
                  }
                </div>
              </section>
              <footer>
                <Button className="btn btn-primary" onClick={handleSubmit} disabled={!enableSave}>Save Changes</Button>
                <Button
                  variant="no-outline-primary"
                  onClick={handleHideModal}
                >Cancel</Button>
              </footer>
            </div>
          </Tab>
          <Tab eventKey="devices" title="Devices" className="user-sidenav-container">
            <div className="account-devices">
              {allow_add_devices ? (
                <Alert variant="info" className="admin-alert admin-switch mw-100">
                  <div className="switch-preveil-container">
                    <p>Adding devices is allowed for all users. You can disable adding devices from Settings.</p>
                    <InfoPopover message={deviceInfoMessage()} />
                  </div>
                </Alert>
              ) :
                <AlertWithSwitch
                  popover={true}
                  toggleState={add_device_allowed_user}
                  handleChange={toggleAddDeviceOverride}
                  popover_message={deviceInfoMessage()}
                >
                  <span>{`User ${add_device_allowed_user ? "allowed to add" : "blocked from adding"} devices`}</span>
                </AlertWithSwitch>
              }
              <Row className="toolbar m-0">
                <Col className="text-end">
                  <span className="total-addresses">
                    <b>{devicesTotal} </b>
                    {devicesTotalLabel}
                  </span>
                </Col>
                <Col xs="auto" className="px-0 refresh">
                  <Button
                    className="action-toolbar-buttons"
                    variant="icon"
                    size="sm"
                    onClick={getUserDevices}
                    title="Refresh Devices"
                    data-tooltip-id="pv-tooltip"
                    data-tooltip-content="Refresh Devices"
                  >
                    {loading_devices ? <i className="spinner"></i> : <Icon className="ficon-rotate-cw" />}
                  </Button>
                </Col>
              </Row>
              <DeviceListPanel devices={userDevices} org_user={currentSelectedUser} refresh={getUserDevices} loading={loading_devices} />
            </div>
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(EditUserSidePanel);
