import React, { useState } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Form, Button } from "react-bootstrap";
import _ from "lodash";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

// Component use to enter individual addresses/domains for Trusted Community and Gateway.
function EnterAddressesFormComponent({ handleAction }: AllProps) {
  const [address_field_valid, setAddressFieldValid] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address_field_valid = !!e.target.value.trim();
    setAddressFieldValid(address_field_valid);
  };

  const submitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const addresses = e.currentTarget.addresses.value;
    // we are removing any duplicate address/domain entered by the user.
    const addressCollection: string[] = _.uniq(addresses.split(",").map((address: string) => address.trim().toLowerCase()));
    handleAction({ actionType: AdminUIActionTypes.SubmitAddresses, params: addressCollection });
  };

  return (
    <Form noValidate onSubmit={submitData}>
      <Form.Group className="mb-2" >
        <Form.Label htmlFor="addresses_ta">Addresses or Domains</Form.Label>
        <Form.Control
          as="textarea" rows={3}
          id="addresses_ta"
          name="addresses" onChange={handleChange} />
      </Form.Group>
      <Form.Text className="text-muted">
        Separate multiple entries with commas, e.g. john@gmail.com, outlook.com
      </Form.Text>
      <div className="mt-4">
        <Button className="me-2" type="submit" disabled={!address_field_valid}>
          Add to List
        </Button>
        <Button onClick={() => handleAction({ actionType: AdminUIActionTypes.ShowModal })} variant="no-outline-primary">
          Cancel
        </Button>
      </div>
    </Form>
  );
}

export default React.memo(EnterAddressesFormComponent);
