import { useCallback } from "react";
import { uiActions } from "src/store";
import { cryptoApi, Message, GlobalErrorMessages, MessageHandlerDisplayType, useAppDispatch } from "src/common";

// Description: Verify if the desktop app is running.
export function useVerifyAppStatus() {
  const dispatch = useAppDispatch();
  const [pingVerifyDesktop] = cryptoApi.endpoints.pingVerifyDesktop.useLazyQuery();
  const verifyDesktopAppIsRunning = useCallback(async () => {
    try {
      return !!(await pingVerifyDesktop().unwrap());
    } catch (error) {
      // Note: if the desktop app is not running then it will display the error in the console and return false.
      dispatch(uiActions.handleRequestErrors(new Message(GlobalErrorMessages.default, MessageHandlerDisplayType.logger), { error }));
      return false;
    }
  }, []);

  return {
    verifyDesktopAppIsRunning
  };
}