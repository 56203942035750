import * as React from "react";
import { Card } from "react-bootstrap";
import { Icon } from "..";

function AccountLockedComponent() {
  return <Card className="mx-auto max-600 mt-5">
    <Card.Body>
      <h2 className="border-bottom pb-3">
        <Icon className="ficon-alert-triangle error-icon" />
        Your account was locked out!</h2>
      <p>There has been too many attempts to authenticate your account and your account has been locked out for a few hours. Please try to login at a later time.</p>
      <p>If you have a question, please contact us at <a href="mailto:support@preveil.com"
        target="_top">support@preveil.com</a>. </p>
    </Card.Body>
  </Card>;
}

export default React.memo(AccountLockedComponent);
