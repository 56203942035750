import React, { FocusEvent } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import _ from "lodash";
import { Checkbox, Icon, Loading } from "src/components";
import { ApprovalGroupRow } from "..";
import { ApprovalGroup, CheckboxStates, CheckboxStatesTypes, AdminUIActionTypes, SupportRoutes } from "src/common";
import { Sort, ActionHandlerFunction } from "@preveil-api";

type AllProps = {
  loading: boolean;
  approval_groups: ApprovalGroup[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  checkedList: ApprovalGroup[];
  setCheckedList: React.Dispatch<React.SetStateAction<ApprovalGroup[]>>;
  show: boolean;
  setCreateModalShow: React.Dispatch<React.SetStateAction<boolean>>;
  sort: Sort<keyof ApprovalGroup>;
  handleAction: ActionHandlerFunction;
};

/* Description: List View component for activity logs. Using grids (Rows and Cols) rather than Table for 
better responsiveness since there are 4 headers - Event, Timestamp, Topic and Device. */
function ApprovalGroupListViewComponent(props: AllProps) {
  const {
    loading,
    approval_groups,
    setShow,
    checkedList,
    setCheckedList,
    show,
    setCreateModalShow,
    sort,
    handleAction
  } = props;

  /* Description: Handles Checkbox change - opens side panel if one request or adds to checked list. */
  function handleCheckboxChange() {
    if (checkedList.length > 0 && checkedList.length < approval_groups.length) {
      setCheckedList(approval_groups);
    } else if (checkedList.length === approval_groups.length) {
      setShow(false);
      setCheckedList([]);
    } else if (checkedList.length === 0 && approval_groups.length === 1) {
      setCheckedList(approval_groups);
      setShow(true);
    } else if (checkedList.length === 0) {
      setCheckedList(approval_groups);
    }
  }

  /* Descriptions: Sets the field for the sort (calls handle Action depending on which component is using this shared component) */
  function setSort(field: string) {
    handleAction({
      actionType: AdminUIActionTypes.SetSort,
      params: field,
    });
  }

  /* Descriptions: Shareable react fragment - for each field returns the header with sort icon
  if applicable */
  function headerWithSortIcon(name: string, field: string) {
    return (
      <>
        {name}
        {!!sort && sort.field === field && approval_groups.length > 1 && (
          <Icon
            className={`${
              sort.direction === "desc" ? "ficon-arrow-down" : "ficon-arrow-up"
            } small-icon ps-1 d-none d-sm-inline`}
          />
        )}
      </>
    );
  }

  /* Description: Get the selected all checkbox state */
  function getSelectedState(): CheckboxStatesTypes {
    let ck_state: CheckboxStatesTypes = CheckboxStates.empty;
    ck_state =
      checkedList.length > 0 && checkedList.length < approval_groups.length
        ? CheckboxStates.indeterminate
        : checkedList.length === approval_groups.length && approval_groups.length > 0
        ? CheckboxStates.checked
        : CheckboxStates.empty;
    return ck_state;
  }

  return (
    <>
      {approval_groups.length > 0 && !loading && (
        <div className="admin-list">
          <Row className="header">
            <Col xs={1} className="pe-3">
              <Checkbox
                className="sr-only"
                onChange={(e: FocusEvent<HTMLInputElement>) => handleCheckboxChange()}
                value={getSelectedState()}
                label="Select All"
                selected={getSelectedState()}
              />
            </Col>
            <Col xs={4} onClick={() => setSort("name")}>
              {headerWithSortIcon("Name", "name")}
            </Col>
            <Col className="text-truncate" xs={3} onClick={() => setSort("approvers")}>
              {headerWithSortIcon("Approvers", "approvers")}
            </Col>
            <Col className="text-truncate" xs={3} onClick={() => setSort("required_approvers")}>
              {headerWithSortIcon("Required Approvers", "required_approvers")}
            </Col>
          </Row>
          {_.map(approval_groups, (ag: ApprovalGroup, i: number) => {
            return (
              <ApprovalGroupRow
                key={i}
                approval_group={ag}
                show={show}
                setShow={setShow}
                checkedList={checkedList}
                setCheckedList={setCheckedList}
              ></ApprovalGroupRow>
            );
          })}
        </div>
      )}
      {!!loading && <Loading className="in-place mt-5"></Loading>}
      {approval_groups.length === 0 && !loading && (
        <div className="centered-card">
          <Card className="admin-card">
            <Icon className="approval-group-image" />
            <Card.Body>
              <Card.Title as="p">Create your first approval group</Card.Title>
              <Card.Text as="p">
                <span className="text-muted">Learn More:</span>{" "}
                <a target="_blank" href={SupportRoutes.approval_groups} rel="noreferrer">Approval Groups</a>
              </Card.Text>
              <Button onClick={() => setCreateModalShow(true)} variant="primary">
                <Icon className="icon-plus2 text-white" />
                Create Approval Group
              </Button>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}

export default React.memo(ApprovalGroupListViewComponent);
