import React, { useState, useRef } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, dayjs, GlobalConstants } from "src/common";
import { CountdownDisplay, VerificationCode } from "src/components";

type AllProps = {
  expires: string;
  handleAction: ActionHandlerFunction;
}

function SMSCodeVerificationFormComponent(props: AllProps) {
  const { expires, handleAction } = props;
  const [expired, setExpired] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  let secret = "";
  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const params = secret;// e.currentTarget.userSmsCode.value;
    handleAction({ actionType: CreateAccountUIActionTypes.SubmitSMSVerificationCode, params });
  }

  // Description: Validate and set secret code on finished
  function handleSetSecretCode(code: string) {
    secret = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  // Description: Expiration Message Panel
  function VerificationCodeExpired() {
    return <>
      <p>
        The verification code that we sent in a SMS message has expired. Please click <b>Back</b> to restart the process.
      </p>
      <div className="btn-panel">
        <Button variant="outline-secondary" type="button"
          onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Back</Button>
      </div>
    </>;
  }

  // Description: Render the enter code
  function VerificationForm() {
    return <>
      <h3 className="border-bottom">Check your phone!</h3>
      <p>We sent you an SMS message with a verification code. Please enter it below.</p>
      <p>This code will expire in <b><CountdownDisplay expires={expires} handleTimeExpired={() => setExpired(true)} /></b>.</p>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="mb-3" >
          <Form.Label>Verification Code</Form.Label>
          <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleSetSecretCode} />
          <Form.Text>
            Did not get an SMS message with your code? <Button type="button" variant="link" size="sm" className="fs-6"
              onClick={() => handleAction({
                actionType: CreateAccountUIActionTypes.SubmitPhone,
                params: { expires: dayjs().add(15, "minutes") }
              })}>Resend code</Button>
          </Form.Text>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" ref={buttonRef} disabled={true} >Verify</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
        </div>
      </Form>
    </>;
  }

  return <>
    <Card.Body>
      {
        expired ? <VerificationCodeExpired /> : <VerificationForm />
      }
    </Card.Body>
  </>;
}
export default React.memo(SMSCodeVerificationFormComponent);
