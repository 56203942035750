import React, { useState } from "react";
import { Modal, Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { MailUIActionTypes, Helpers } from "src/common";
import ExternalLinkImage from "src/assets/images/external-link.svg";
type AllProps = {
  external_link: string;
  handleAction: ActionHandlerFunction;
}

function ExternalLinkModalComponent(props: AllProps) {
  const { external_link, handleAction } = props;
  const [formatted_url] = useState<string>(Helpers.getValidUrl(external_link));

  // Description: Handle states of modal. If is a close, dismiss the message too
  function handleHideModal() {
    handleAction({ actionType: MailUIActionTypes.ResetExternalLink });
  };

  return <Modal
    size="lg"
    dialogClassName="external-link-modal"
    show={true}
    onHide={() => handleHideModal()}
    aria-labelledby="Move Modal">
    <Modal.Header closeButton>
      <Modal.Title as="h2">You've clicked an external link</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="external-link">{external_link}</p>
      <Card className="pv-card">
        <Card.Img variant="top" src={ExternalLinkImage} />
        <Card.Body>
          <Card.Title as="p">
            If you trust the sender and the destination you can continue with the button below.
          </Card.Title>
        </Card.Body>
      </Card>
    </Modal.Body>
    <Modal.Footer>
      <Button href={formatted_url} target="_blank" onClick={handleHideModal}>Open Link</Button>
      <Button variant="no-outline-primary" onClick={handleHideModal}>Don't Open</Button>
    </Modal.Footer>
  </Modal>;
}

export default React.memo(ExternalLinkModalComponent);
