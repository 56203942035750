import React, { useRef, useState, FocusEvent, useEffect, Dispatch, SetStateAction } from "react";
import { Form } from "react-bootstrap";
import { DeviceBase } from "@preveil-api";
import { Account, Message, useAppDispatch, useRenameDeviceMutation } from "src/common";
import { uiActions } from "src/store";

type AllProps = {
  device: DeviceBase;
  current_account: Account;
  setEditingDevice: Dispatch<SetStateAction<DeviceBase | undefined>>;
  devices: DeviceBase[];
  setDevices: Dispatch<SetStateAction<DeviceBase[]>>;
};

// Description: Rename Device Form Component. 
function RenameFormComponent(props: AllProps) {
  const { device, current_account, setEditingDevice, devices, setDevices } = props;
  const [name, setName] = useState<string>(device.device_name);
  const input_element_ref = useRef<HTMLInputElement>(null);
  const [renameDevice] = useRenameDeviceMutation();
  const dispatch = useAppDispatch();

  // Description: Added to this to make sure the text box is focused when the user clicks rename. (on initial load)
  useEffect(() => {
    if (!!input_element_ref.current) {
      setTimeout(() => input_element_ref.current?.focus());
    }
  }, []);

  // Description: If they clicked enter without changing, it will just return but otherwise, will send the new name to the backend.
  async function RenameDevice() {
    if (name === device.device_name) {
      setEditingDevice(undefined);
      return;
    }
    await renameDevice({
      userId: current_account.user_id,
      deviceId: device.device_id,
      newName: name,
    })
      .unwrap()
      .then(() => {
        setEditingDevice(undefined);
        const d = devices.find((d) => d.device_id === device.device_id);
        if (!!d) {
          const updated_device = { ...d };
          updated_device.device_name = name;
          const updated_devices = devices.filter((d) => d.device_id !== device.device_id);
          updated_devices.push(updated_device);
          setDevices(updated_devices);
        }
        dispatch(uiActions.handleSetMessage(new Message(`Successfully renamed ${device.device_name} to ${name}`)));
      })
      .catch((stack) => {
        setEditingDevice(undefined);
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Error renaming ${device.device_name} to ${name}`), stack
          ),
        );
      });
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        RenameDevice();
      }}
    >
      <Form.Group>
        <Form.Control
          id="rename_tb"
          ref={input_element_ref}
          type="text"
          placeholder={device.device_name}
          aria-label="rename device input form"
          value={name}
          onChange={(e: FocusEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
          onBlur={(e) => {
            e.preventDefault();
            RenameDevice();
          }}
        />
      </Form.Group>
    </Form>
  );
}

export default React.memo(RenameFormComponent);
