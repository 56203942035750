import * as React from "react";
import { Routes, Route, Params, Navigate } from "react-router-dom";
import { AppConfiguration, NavigationPath, PrivateRoutes } from "src/common";
import {
  AccountProfile, AddDevice, Wrapper, Approvals, SettingsActivityLogs, AddToMailClient, DeviceManagement, ApproveRecovery, AccountBackup,
  RecoveryGroup, DefaultTemplate, ExpressAccountRecovery, PasswordReset
} from "src/components";

function SettingsPage() {
  return (
    <Wrapper type={NavigationPath.SettingsNavigation}>
      <DefaultTemplate>
        {AppConfiguration.buildForExpress() ? <SettingsPagesExpressRoutes /> : <SettingsPagesRoutes />}
      </DefaultTemplate>
    </Wrapper>
  );
}

export function SettingsPagesRoutes(props: Readonly<Params<string>>) {
  return (
    <Routes>
      <Route path={PrivateRoutes.profile_route} element={<AccountProfile />} />
      <Route path={PrivateRoutes.add_device_route} element={<AddDevice {...props} />} />
      <Route path={`${PrivateRoutes.approvals_route}/:request_id`} element={<Approvals {...props} />} />
      <Route path={PrivateRoutes.approvals_route} element={<Approvals {...props} />} />
      <Route path={PrivateRoutes.activity_route} element={<SettingsActivityLogs {...props} />} />
      <Route path={PrivateRoutes.install_profile_route} element={<AddToMailClient {...props} />} />
      <Route path={PrivateRoutes.device_management_route} element={<DeviceManagement {...props} />} />
      <Route path={PrivateRoutes.recovery_route} element={<RecoveryGroup {...props} />} />
      <Route path={PrivateRoutes.approve_recovery_route} element={<ApproveRecovery {...props} />} />
      <Route path={PrivateRoutes.account_backup_route} element={<AccountBackup {...props} />} />
      <Route path={PrivateRoutes.recovery_requests_route} element={<RecoveryGroup {...props} />} />
      <Route path="*" element={<Navigate replace to={`/${PrivateRoutes.settings_route}/${PrivateRoutes.profile_route}`} />} />
    </Routes>
  );
}

// Description: Handle Settings pages Express routing
export function SettingsPagesExpressRoutes() {
  return <Routes>
    <Route path={PrivateRoutes.profile_route} element={<AccountProfile />} />
    <Route path={PrivateRoutes.password_recovery_route} element={<ExpressAccountRecovery />} />
    <Route path={PrivateRoutes.change_password_route} element={<PasswordReset />} />
  </Routes>;
}

export default React.memo(SettingsPage);
