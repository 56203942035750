import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Image, Modal } from "react-bootstrap";
import { IActionHandler } from "@preveil-api";
import {
  Account, Helpers, KeyStorageAPI, KeyStorageData, useAppSelector, GlobalErrorMessages, Message, MessageHandlerDisplayType,
  MessageAnchors, useAppDispatch, ChangeAuthenticationSteps, ChangeAuthenticationModalSteps, KeystorageResponseErrorCodes, StatusCode, SettingsSuccessMessages, SettingsMessages,
  AccountErrorMessages, SettingsErrorMessages, AuthenticationTypes, PublicRoutes,
  keystorageApi, LocalAccountStorage
} from "src/common";
import { AlertWithSwitch, Loading, PageHeader, SharedSmsCodeVerificationForm, PhoneNumberForm, SharedCurrentPasswordForm } from "src/components";
import authenticator_image from "src/assets/images/account/authenticator.svg";
import sms_image from "src/assets/images/account/sms.svg";
import { CurrentTOTPCodeVerificationForm, TOTPForm } from ".";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";
import { authenticator } from "otplib";
import _ from "lodash";

type AuthenticatorMethod = {
  image: string;
  popover_message: string;
  text: string;
  active: boolean;
}

/* Description: Component in settings to allow an express user to change their authentication method
  they can change it to either sms or totp (not both at once) */
function ChangeAuthenticationComponent() {
  const navigate = useNavigate();
  const current_account = useAppSelector((state) => state.account.current_account);
  const auth_token_store = useAppSelector((state: RootState) => state.account.auth_token);
  const [expires, setExpires] = useState<string>();
  const [is_sms, setIsSMS] = useState<boolean>();
  const [auth_token, setAuthToken] = useState<string>();
  const [modal_show, setModalShow] = useState<boolean>(false);
  const [authenticator_methods, setAuthenticatorMethods] = useState<AuthenticatorMethod[]>();
  const [step, setStep] = useState<number>(ChangeAuthenticationSteps.LOADING);
  const [modal_step, setModalStep] = useState<number>(ChangeAuthenticationModalSteps.LOADING);
  // Query to get the current authentication method
  const [getAuthMethod] = keystorageApi.endpoints.getAuthenticationMethod.useLazyQuery();
  const dispatch = useAppDispatch();

  const totp_secret_ref = useRef<string>();
  const current_totp_ref = useRef<string>();
  const current_sms_ref = useRef<string>();
  const new_totp_ref = useRef<string>();
  const new_sms_ref = useRef<string>();
  const phone_number_ref = useRef<string>();
  const sms_verification_title_ref = useRef<string>("");

  // Description: Triggered on load to check if the auth token is stored in the store
  // if not - set the step to submit password to direct the user to re-enter their password
  useEffect(() => {
    if (!!auth_token_store) {
      setAuthToken(auth_token_store);
      getAuthenticationMethod(auth_token_store);
    } else {
      setStep(ChangeAuthenticationSteps.SUBMIT_PASSWORD);
    }
    return () => {
     reset(); // reset state
    };
  }, []);

  // Description: When is_sms is set, set the authenticator methods and the step to the current authentication method
  useEffect(() => {
    if (is_sms !== undefined) {
      setAuthenticatorMethods([{
        image: sms_image, popover_message: SettingsMessages.sms_popover_message,
        text: "Text messaging authentication is turned", active: is_sms
      }, {
        image: authenticator_image,
        popover_message: SettingsMessages.authenticator_popover_message,
        text: "Authenticator application authentication is turned",
        active: !is_sms
      }]);
      setStep(ChangeAuthenticationSteps.CURRENT_AUTHENTICATION);
    }
  }, [is_sms]);

  // Description: Function that calls keystorage server to get the current authentication method
  async function getAuthenticationMethod(auth_token: string) {
    await getAuthMethod({ user_id: current_account?.user_id || "", auth_token: auth_token || "" })
      .unwrap()
      .then((response) => {
        const is_sms = response.authentication_method === AuthenticationTypes.sms;
        setIsSMS(is_sms);
      })
      .catch((error) => {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_password), error));
        setStep(ChangeAuthenticationSteps.SUBMIT_PASSWORD);
      });
  }

  // Description: Function that calls keystorage server to change the authentication method
  function toggleAuthentication() {
    const two_factor_codes = [KeystorageResponseErrorCodes.no_sms_auth_header, KeystorageResponseErrorCodes.no_totp_auth_header];
    if (!!auth_token && !!current_account) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      KeyStorageAPI.changeAuthenticationMethod(account_ids, auth_token, phone_number_ref.current, totp_secret_ref.current, new_sms_ref.current, new_totp_ref.current, current_sms_ref.current, current_totp_ref.current)
        .then((response) => {
          const message_code = response.data.error || response.data.bad_parameter || "";
          if (!response.isError) {
            setStep(ChangeAuthenticationSteps.LOADING);
            setModalShow(false);
            setIsSMS(!!phone_number_ref.current);
            LocalAccountStorage.updatePVAccountSession(phone_number_ref.current);
            dispatch(uiActions.handleSetMessage(new Message(SettingsSuccessMessages.succesfully_changed_authentication_methods)));
            ChangeAuthenticationRequests.handleResetForms();
          } else if (response.status === StatusCode.UNAUTHORIZED_ERROR_CODE && !!message_code && two_factor_codes.includes(message_code)) {
            if (message_code === KeystorageResponseErrorCodes.no_sms_auth_header) {
              sms_verification_title_ref.current = (is_sms && !current_sms_ref.current) ? "Verify Your Current Authentication Method" : "Verify New Phone Number";
              setModalStep(ChangeAuthenticationModalSteps.SUBMIT_SMS_CODE);
            } else {
              (!is_sms && !!new_sms_ref.current) ? setModalStep(ChangeAuthenticationModalSteps.SUBMIT_TOTP_CURRENT_CODE) : setModalStep(ChangeAuthenticationModalSteps.SUBMIT_TOTP_CODE);
            }
          } else if (message_code === KeystorageResponseErrorCodes.bad_totp_code) {
            dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.bad_totp_code), message_code));
            !is_sms &&
              setModalStep(ChangeAuthenticationModalSteps.SUBMIT_TOTP_CURRENT_CODE);
          } else if (message_code === KeystorageResponseErrorCodes.bad_sms_code) {
            dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_sms_code), message_code));
            reset();
            is_sms ? toggleAuthentication() : setModalStep(ChangeAuthenticationModalSteps.SUBMIT_PHONE_NUMBER);
          } else if (message_code === KeystorageResponseErrorCodes.locked_out) {
            ChangeAuthenticationRequests.handleResetForms();
            navigate(`/${PublicRoutes.logout_route}`);
          } else {
            setStep(2);
            ChangeAuthenticationRequests.handleResetForms();
            dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_changing_authentication), message_code));
          }
        }).catch((error) => {
          setStep(2);
          ChangeAuthenticationRequests.handleResetForms();
          dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_changing_authentication), error));
        });
    }
  }

  // Description: Functions in handleAction for child components to use.
  const ChangeAuthenticationRequests = {
    // Description: Sets the auth token after directing the user to enter their password (if the auth token is not stored as a session cookie)
    handleSubmitPassword: async (password: string) => {
      if (!!current_account) {
        const _auth_token = Helpers.b64Encode(await KeyStorageData.authenticationToken(current_account.user_id, password));
        setAuthToken(_auth_token);
        getAuthenticationMethod(_auth_token);
      }
    },
    // Description: Submit the phone number and call toggleAuthentication which should trigger an sms challenge
    handleSubmitPhone: (params: { expires: string; phone_number?: string; }) => {
      const _phone_number = params.phone_number || phone_number_ref.current;
      setExpires(params.expires);
      phone_number_ref.current = _phone_number;
      !!_phone_number && toggleAuthentication();
    },
    // Description: handles submitting the sms verification code. 
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      if (is_sms && !current_sms_ref.current) {
        current_sms_ref.current = sms_code;
        totp_secret_ref.current = authenticator.generateSecret(20);
        setModalStep(ChangeAuthenticationModalSteps.SUBMIT_TOTP_CODE);
      } else {
        new_sms_ref.current = sms_code;
        toggleAuthentication();
      }
    },
    // Description: handles submitting the totp verification code.
    handleSubmitTOTPVerificationCode: (totp_code: string) => {
      if (!!new_sms_ref.current) {
        current_totp_ref.current = totp_code;
      } else {
        new_totp_ref.current = totp_code;
      }
      toggleAuthentication();
    },
    // Description: Reset all form states and go back to first step
    handleResetForms: () => {
      reset();
      setStep(ChangeAuthenticationSteps.CURRENT_AUTHENTICATION);
      setModalStep(ChangeAuthenticationModalSteps.LOADING);
      setModalShow(false);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    },
  };

  // Description: Reset all refs and states
  function reset() {
    setExpires(undefined);
    totp_secret_ref.current = undefined;
    current_sms_ref.current = undefined;
    current_totp_ref.current = undefined;
    phone_number_ref.current = undefined;
    new_sms_ref.current = undefined;
    new_totp_ref.current = undefined;
    sms_verification_title_ref.current = "";
  }

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((ChangeAuthenticationRequests as any)[callback] instanceof Function) {
      (ChangeAuthenticationRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      ChangeAuthenticationRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: This function is triggered when the user clicks on either of the switches. It opens to modal
  // and sets the correct step based on the switch that was clicked and what the current authentication method is.
  function handleChange() {
    setModalShow(true);
    setModalStep(ChangeAuthenticationModalSteps.LOADING);
    if (is_sms) {
      toggleAuthentication();
    } else {
      setModalStep(ChangeAuthenticationModalSteps.SUBMIT_PHONE_NUMBER);
    }
  }

  // Description: build the switches containers
  function alertComponent() {
    return <>
      {
        _.map(authenticator_methods, (method: AuthenticatorMethod, i: number) => {
          return <span className="authentication-alert" key={i}>
            <Image className={`${!method.active ? "invisible" : ""}`} src={method.image} />
            <AlertWithSwitch popover={true} toggleState={method.active} handleChange={handleChange} popover_message={method.popover_message}>
              <span>{method.text} {method.active ? <b>on</b> : "off"}.</span>
            </AlertWithSwitch>
          </span>;
        })}
    </>;
  }

  // Description: Render the correct form
  function ChangeAuthenticationForms() {
    switch (step) {
      case ChangeAuthenticationSteps.SUBMIT_PASSWORD:
        return <SharedCurrentPasswordForm submit={ChangeAuthenticationRequests.handleSubmitPassword} />;
      case ChangeAuthenticationSteps.CURRENT_AUTHENTICATION:
        return <>
          <h4 className="border-bottom">Choose one type of authentication for your login security protection.</h4>
          {alertComponent()}
        </>;
      default:
        return <Loading message="Changing Authentication" />;
    }
  }

  // Description: Render the correct form in the modal
  function ModalForms() {
    switch (modal_step) {
      case ChangeAuthenticationModalSteps.SUBMIT_PHONE_NUMBER:
        return <PhoneNumberForm handleAction={handlePageActions} title="Switch to SMS Verification"></PhoneNumberForm>;
      case ChangeAuthenticationModalSteps.SUBMIT_TOTP_CODE:
        return <>{!!current_account && !!totp_secret_ref.current ? <TOTPForm handleAction={handlePageActions} totp_secret={totp_secret_ref.current} user_id={current_account?.user_id}></TOTPForm> : <></>}</>;
      case ChangeAuthenticationModalSteps.SUBMIT_SMS_CODE:
        return <SharedSmsCodeVerificationForm expires_param={expires} handleAction={handlePageActions} title={sms_verification_title_ref.current} />;
      case ChangeAuthenticationModalSteps.SUBMIT_TOTP_CURRENT_CODE:
        return <CurrentTOTPCodeVerificationForm handleAction={handlePageActions} />;
      default:
        return <Loading className="position-absolute" />;
    }
  }

  return <>
    <PageHeader>
      <h1>Change Authentication</h1>
    </PageHeader>
    <Card className="card-section">
      <ChangeAuthenticationForms />
      <Modal className="change-auth-modal" show={modal_show} onHide={() => ChangeAuthenticationRequests.handleResetForms()} centered aria-labelledby="change authentication modal">
        <Modal.Body>
          <ModalForms />
        </Modal.Body>
      </Modal>
    </Card>
  </>;
}

export default React.memo(ChangeAuthenticationComponent);
