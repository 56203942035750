import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { AppConfiguration, AuthenticationConstants, Countries, Country, LocationService, useAppSelector } from "src/common";
import { Icon } from "src/components";
import { ChangePhoneNumber } from ".";
import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";

const locationService = new LocationService();
function ProfileFormComponent() {
  const session_pv_account = sessionStorage.getItem(AuthenticationConstants.USER_ID_SESSION_KEY);
  const current_profile = useAppSelector((state) => state.account.current_account)?.profile;
  const name = current_profile?.name?.split(/\s+/g) || [];
  const [modal_show, setModalShow] = useState<boolean>(false);
  const [country, setCountry] = useState<Country>();
  const [phone_mask, setPhoneMask] = useState<string>();
  const [parsed_number, setParsedNumber] = useState<PhoneNumber>();
  const is_express = AppConfiguration.buildForExpress();

  useEffect(() => {
    if (!!session_pv_account) {
      const phone_number = JSON.parse(session_pv_account).phone_number;
      const _phone_number = !!phone_number ? parsePhoneNumberFromString(phone_number) : undefined;
      if (!!_phone_number) {
        setParsedNumber(_phone_number);
        setPhoneMask(_phone_number.nationalNumber.toString());
        setCountry(Countries.find((country) => country.country_abb === _phone_number.country));
      }
    }
  }, [session_pv_account]);

  return <>
    <h2 className="content-header border-bottom">Name and Email</h2>
    <div className="profile">
      <Row>
        <Col md={4}>First Name</Col>
        <Col>{name[0] || ""}</Col>
      </Row>
      <Row>
        <Col md={4}>Last Name</Col>
        <Col>{name[1] || ""}</Col>
      </Row>
      <Row>
        <Col md={4}>Email</Col>
        <Col>{current_profile?.address || "Email"}</Col>
      </Row>
      {is_express && !!phone_mask && !!country && <Row>
        <Col md={4}>Mobile Phone Number</Col>
        <Col className="change-phone">
          <span className="country-mask">
            <Icon className={`cicon-${country.country_abb.toLowerCase()}`} />
            <span>{country.country_abb}</span>
          </span>
          <span className="phone-code">{country.country_number_code} -</span>
          <div className="phone-input">{locationService.phoneMaskChange(phone_mask)}</div>
          <Button variant="icon" size="sm" onClick={() => setModalShow(true)} data-tooltip-place="bottom" data-tooltip-id="pv-tooltip" data-tooltip-content={"Change Phone Number"} ><Icon className="pv-icon-compose" /></Button>
          {!!parsed_number && <ChangePhoneNumber modal_show={modal_show} setModalShow={setModalShow} current_phone_number={parsed_number}></ChangePhoneNumber>}
        </Col>
      </Row>}
    </div>
  </>;
}

export default React.memo(ProfileFormComponent);
