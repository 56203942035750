/*
* Description: User class on the client side
*
*/
import { LocalUser, CollectionServerUser, CollectionServerExpressUser, MuaInfo, OrgInfo, UserProfile, JSONHistoricalKey } from "@preveil-api";
import { Helpers, RemoteUserKey, User, Device, RemotePublicUserKey, AppUserKey, AppPublicUserKey, KeyFactory, LocalAccountStorage, MailRecipient } from "..";
import _ from "lodash";

export type AccountUserKey = AppUserKey | RemoteUserKey;
export type AccountUserPublicKey = AppPublicUserKey | RemotePublicUserKey;
// Description: Minimum Required for Collection Server calls and authentication in web / express logins
export interface AccountIdentifiers {
  user_id: string;
  user_key: AppUserKey;
  device_id: string;
  device_key: AppUserKey;
  user_key_serialized?: string;
  device_key_serialized?: string;
  phone_number?: string;
}

export class Account extends User {
  account_version: number;
  current_device_id: string = "";
  mail_cid: string;
  mua_info?: MuaInfo;
  org_info: OrgInfo | null;
  recovery_group?: any;
  constructor(
    local_user: LocalUser,
    user_keys: AccountUserKey[],
    public_user_keys: AccountUserPublicKey[],
    public devices: Device[]
  ) {
    super(local_user, user_keys, public_user_keys);
    this.mail_cid = local_user.mail_cid;
    this.mua_info = local_user.mua_info;
    this.current_device_id = local_user.device.device_id;
    this.account_version = local_user.account_version !== undefined ? local_user.account_version : 0;
    this.org_info = local_user.org_info;
  }

  get profile(): UserProfile {
    return {
      address: this.user_id,
      name: this.display_name,
      org_id: this.org_info?.org_id,
      role: this.org_info?.role,
      phone_number: this.phone_number
    };
  }

  get mail_recipient(): MailRecipient {
    return {
      user_id: this.user_id,
      key_version: this.public_user_key.key_version,
      mail_cid: this.mail_cid,
      public_user_key: this.public_user_key
    };
  }

  // Description: Get current device
  get current_device(): Device {
    const device = _.find(this.devices, (_device: Device) => _device.device_id === this.current_device_id);
    if (!device) {
      throw new Error("Devices not fetched!");
    }
    return device;
  }

  // Description: Retrieve user Key by version number
  public getUserKeyWithVersion(key_version: number): AccountUserKey | null {
    const key = !!this.user_keys ? this.user_keys.find(k => k.key_version === key_version) : null;
    return key || null;
  }

  // Description: Update user key arrays using historic key data
  public updateUserKeys(user_keys: AccountUserKey[]) {
    this._user_keys = user_keys.sort((a, b) => b.key_version - a.key_version);
  }

  // STATIC METHODS: 
  // Description: Build a user friendly profile for AuthenticationContext
  static getAccountProfile(acct: Account): UserProfile {
    return {
      address: acct.user_id,
      name: acct.display_name,
      org_id: acct.org_info?.org_id,
      role: acct.org_info?.role
    };
  }

  // Description: Build an AccountIdentifiers from Account 
  static getAccountIdentifiers(account: Account): AccountIdentifiers {
    return {
      user_id: account.user_id,
      user_key: account.user_keys[0] as AppUserKey,
      device_id: account.current_device_id,
      device_key: account.devices[0].device_key as AppUserKey,
      phone_number: account.phone_number
    };
  }

  /*
 * Description: Initialize class, resolving async calls
 *      GET the user_key and device_public_key
 *      Optional: Pass pre-existing user_key and device_key to use instead of remote keys
*/
  static async initAccount(local_user: LocalUser, _user_key?: AppUserKey, _device_key?: AppUserKey): Promise<Account> {
    const user_key: AccountUserKey = !!_user_key ? _user_key : await this._initKey(local_user.user_id, local_user.public_user_key, local_user.user_key);
    const public_user_key: AccountUserPublicKey = user_key.public_user_key;
    const device_key: AccountUserKey = !!_device_key ? _device_key : await Device.initDeviceKey(local_user.user_id, local_user.device);
    const devices = new Device(local_user.user_id, local_user.device, device_key);

    return new Account(
      local_user,
      new Array(user_key),
      new Array(public_user_key),
      new Array(devices) // Legacy type: { state: ContentLoadStatus.LOADED, list: [device] };
    );
  }

  /*
   * Description: Initialize key (user_key and device_key)
   *      GET the user_key from either the local_user.user_key or build remote from user_id & public_user_key (key_version)
   */
  static async _initKey(user_id: string, public_user_key: string, key?: string): Promise<AccountUserKey> {
    let account_key: AccountUserKey;
    try {
      if (!!key) {
        account_key = await KeyFactory.deserializeUserKey(Helpers.b64Decode(key));
      } else {
        const _public_user_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(public_user_key));
        account_key = new RemoteUserKey(user_id, _public_user_key.key_version, _public_user_key);
      }
      return account_key;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  // Description: returns an account with userId if not found pick first one
  static getCurrentAccountbyUserId(accounts: Account[], user_id?: string): Account | undefined {
    return !!user_id ? _.find(accounts, ["user_id", user_id]) : accounts[0];
  }

  // Description: Convert Collection Server user to Crypto / Local User
  // Need to pass the local device when getting the current user, cs will return devices: null
  static parseCollectionServerUser(user: CollectionServerUser, account_ids?: AccountIdentifiers): LocalUser {
    // Get Local Device from Ids
    const device = !!account_ids ? Device.buildLocalBaseDevice(account_ids) :
      user.devices && user.devices.length > 0 ? user.devices[0] : null;
    if (!!device) {
      return {
        account_version: user.account_version !== undefined ? user.account_version : 0,
        display_name: user.display_name,
        external_email: user.external_email,
        user_id: user.user_id,
        device,
        mail_cid: user.mail_collection_id,
        org_info: !!user.entity_id ? {
          org_id: user.entity_id,
          dept_name: user.entity_metadata.department,
          role: user.entity_metadata.role,
          approval_group_id: user.entity_metadata.recovery_group_id,
          approval_group_version: user.entity_metadata.recovery_group_version
        } : null,
        password: "",
        public_user_key: user.public_key,
        user_key_versions: user.key_version !== undefined && !isNaN(user.key_version) ? [user.key_version] : [-1],
        phone_number: account_ids?.phone_number
      };
    } else {
      throw new Error("A CollectionServerUser requires a Device Object");
    }
  }

  // Description: Take the list of CollectionServerUser objects returned from postFindUsers and
  // convert it into a dictionary such that the keys are the user_id and values are the CollectionServerUser
  static parseCollectionServerUserList(users: CollectionServerUser[]): { [key: string]: CollectionServerUser } {
    const users_by_id: { [key: string]: CollectionServerUser } = {};
    for (const user of users) {
      users_by_id[user.user_id.toLowerCase()] = user;
    }
    return users_by_id;
  }

  // Description: Parses the response from CS to a LocalUser object
  static parseCollectionServerExpressUser(user: CollectionServerExpressUser, account_ids: AccountIdentifiers, public_user_key: string): LocalUser {
    return {
      user_id: account_ids.user_id,
      display_name: user.display_name,
      external_email: null,
      device: user.device, // Device.buildLocalBaseDevice(account_ids),
      mail_cid: user.mail_collection_id,
      org_info: null,
      password: "",
      public_user_key,
      account_version: user.account_version !== undefined && !isNaN(user.account_version) ? user.account_version : -1,
      user_key_versions: [-1],
      phone_number: account_ids.phone_number
    };
  }

  // Retrieve AccountIdentifiers from sessions (WEB MODE)
  static async getExpressAccountIdentifiersFromSessions(storage: LocalAccountStorage): Promise<AccountIdentifiers | null> {
    if (!!storage.pv_account && !!storage.most_recent_user_device && storage.most_recent_user_key) {
      const user_key = await KeyFactory.deserializeUserKey(Helpers.b64Decode(storage.most_recent_user_key));
      const device_key = await KeyFactory.deserializeUserKey(Helpers.b64Decode(storage.most_recent_user_device.device_key));
      return {
        user_id: storage.pv_account.address,
        user_key,
        device_id: storage.most_recent_user_device.id,
        device_key,
        phone_number: storage.pv_account.phone_number
      };
    } else {
      return null;
    }
  }


  // Description: Gets historical keys and unwraps them user previous versions
  // NOTES: Successively accumulate keys, each key being available to unwrap the next.
  // The key set itself must be an observable as any given deserialization could be asynchronous making each subsequent step asynchronous.
  static async initKeyHistory(current_user_key: AppUserKey, historical_keys: JSONHistoricalKey[]): Promise<AppUserKey[]> {
    const app_keys = Promise.resolve([current_user_key]);
    const keys = historical_keys.sort((k1, k2) => k2.version - k1.version);

    // Note: Decrypt Historical Key using last wrapped key in array and the correct versioned AppUserKey
    async function unwrapHistoricalKey(wrapped_last_key: string, versioned_key: AppUserKey): Promise<AppUserKey> {
      const decoded_last_key = Helpers.b64Decode(wrapped_last_key);
      const unwrapped_key = await versioned_key.encryption_key.unseal(decoded_last_key);
      const key = await KeyFactory.deserializeUserKey(unwrapped_key);
      return key;
    }

    return await _.reduce(keys, async (result: Promise<AppUserKey[]>, historical_key) => { // Promise<AppUserKey[]>
      const wrapped_last_key = historical_key.wrapped_last_key;
      return await result.then(async (keys: AppUserKey[]) => {
        const versioned_key = _.find(keys, (k: AppUserKey) => k.key_version === historical_key.version);
        !!versioned_key &&
          keys.push(await unwrapHistoricalKey(wrapped_last_key, versioned_key));
        return keys;
      });
    }, app_keys);
  }

  // Description: From user current_account org_inf check if show_mua_prompt is set to false
  static isShowMUAPropt(organization?: OrgInfo | null): boolean {
    return !!organization && !!organization.org_id && !!organization?.show_mua_prompt;
  }

}
