import { PathNode } from "./types";
import { FSMessage, FSWrappedKey, Node, FSRole } from "src/common/keys/protos/collections_pb";
import { SymmKey } from "src/common/keys";
import {
  Account, DriveEntryType, PathInfo, MaintainerPathNode, DriveTypeMapping, getDirSymmKey, GrantSet, decryptItemName, Helpers, ActiveGrantSet, PermissionSetType,
  PermissionSet, buildNavigationLink
} from "src/common";
import _ from "lodash";


// Description: builds the set of paths for a node 
export class PathList {
  path_list!: PathNode[];
  // readonly _acl_tree?: FSMessage.Dir[];
  constructor(
    public collection_id: string,
    path_list: FSMessage.Dir.PathNode[],
  ) {
    this.path_list = this._buildPathNodes(path_list);
  }

  // Description: DecryptPathNode 
  private _buildPathNodes(path_list: FSMessage.Dir.PathNode[]): PathNode[] {
    return _.map(path_list, (path_node: FSMessage.Dir.PathNode) => {
      return new Path(this.collection_id, path_node).node;
    });
  }

  // Description: Build breadcrumbs PathInfo[]
  static async buildPathInfoFromNodes(list: PathNode[], current_account: Account, permissions: PermissionSetType, active_grants: ActiveGrantSet):
    Promise<PathInfo[]> {
    const permission_set = PermissionSet.init(permissions);
    const paths = await Promise.all(_.map(list, async (node: PathNode, i: number) => {
      const wrapped_name = node.wrapped_name;
      const parent_node = i > 0 ? list[i - 1] : null;
      const parent_wrapped_dir_key = !!parent_node ? parent_node.wrapped_dir_key : null;
      const maintainer = !!parent_node ? parent_node : node;
      const current_grants = !!maintainer.maintainer_id && Object.prototype.hasOwnProperty.call(active_grants, maintainer.maintainer_id) ?
        active_grants[maintainer.maintainer_id] : null;
      const key_version = !!node.maintainer_node ? node.maintainer_node.key_version : maintainer?.key_version;
      const grant = !!current_grants ? GrantSet.getGrantbyRole(current_grants, key_version, Node.ACLRole.READER) : null;
      const reader_user_key = !!grant ? await grant.key(current_account) : undefined;
      let path = DriveTypeMapping.DIR;
      try {
        if (!!parent_node) {
          const symmetric_key = !!parent_wrapped_dir_key ? await getDirSymmKey(parent_wrapped_dir_key, current_account, permissions, reader_user_key) : null;
          path = !!symmetric_key ? await decryptItemName(wrapped_name, symmetric_key, reader_user_key) : DriveTypeMapping.DIR;
        } else {
          const permission = permission_set.permission(FSRole.READER, key_version) || permission_set.permission(FSRole.ACCESS, key_version);
          const user_key = !!permission ? await permission.key(current_account) : null;
          // NOTE: Handle root node names (This is important when a collection is renamed because other names will be stale)
          path = (!!node.root_dir_name?.content && !!user_key) ? Helpers.utf8Decode(await user_key.encryption_key.unseal(node.root_dir_name.content)) :
            // NOTE: Handle maintainer nodes 
            (!!node.maintainer_node?.content && !!reader_user_key) ? Helpers.utf8Decode(await reader_user_key.encryption_key.unseal(node.maintainer_node.content)) :
              !!user_key ? Helpers.utf8Decode(await user_key?.encryption_key.unseal(wrapped_name)) : DriveTypeMapping.DIR;
        }

        return {
          path,
          url: node.url,
          id: node.id
        };
      }
      catch (error: unknown) {
        return {
          path: DriveTypeMapping.DIR,
          url: node.url,
          id: node.id
        };
      }
    }));
    return paths;
  }

  // Description: Build breadcrumbs PathInfo[] for Collection Server
  static buildPathInfoFromFilesyncPaths(list: PathInfo[], collection_id: string): PathInfo[] {
    const path_list = _.map(list, (info: PathInfo, i: number) => {
      return {
        ...info,
        ...{ url: buildNavigationLink(collection_id, info.id, DriveEntryType.DIR) }
      };
    });
    return path_list;
  }
}

// Description: builds the single path in the Path_list
export class Path implements PathNode {
  id!: string;
  path!: string;
  wrapped_name: Uint8Array;
  wrapped_dir_key?: FSWrappedKey;
  key_version?: number;
  deleted?: boolean;
  url!: string;
  maintainer_id: string;
  maintainer_node?: MaintainerPathNode;
  root_dir_name?: MaintainerPathNode;
  _key!: SymmKey;
  constructor(
    public collection_id: string,
    path_node: FSMessage.Dir.PathNode
  ) {
    this.id = path_node.getId_asB64();
    this.maintainer_id = path_node.getMaintainerId_asB64();
    this.deleted = path_node.getIsDeleted();
    this.wrapped_dir_key = path_node.getWrappedDirKey();
    this.key_version = this.wrapped_dir_key?.getKeyVersion();
    this.wrapped_name = path_node.getName_asU8();
    this.path = path_node.getName_asB64();
    this.url = buildNavigationLink(collection_id, this.id, DriveEntryType.DIR);
    // NOTE: This node is the maintainer node (acl nodes) -> this.id === this.maintainer_id
    if (!!path_node.hasMaintainerScopedName()) {
      const scoped_name = path_node.getMaintainerScopedName();
      this.maintainer_node = {
        scoped_name,
        key_version: scoped_name?.getKeyVersion(),
        content: scoped_name?.getContent_asU8()
      };
    }
    if (!!path_node.hasRootDirName()) {
      const scoped_name = path_node.getRootDirName();
      this.root_dir_name = {
        scoped_name,
        key_version: scoped_name?.getKeyVersion(),
        content: scoped_name?.getContent_asU8()
      };
    }
  }

  get node(): PathNode {
    return {
      id: this.id,
      path: this.path,
      url: this.url,
      deleted: this.deleted,
      wrapped_name: this.wrapped_name,
      wrapped_dir_key: this.wrapped_dir_key,
      key_version: this.key_version,
      maintainer_id: this.maintainer_id,
      maintainer_node: this.maintainer_node,
      root_dir_name: this.root_dir_name
    };
  }
}
