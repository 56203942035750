import React, { useState, FocusEvent } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import { Helpers, KeyExchangeManager, LoginWebUIActionTypes, PublicRoutes, RecoverAccountUIActionTypes, combine } from "src/common";
import { Icon, InfoPopover } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
  server_shard: string;
}

/* Description: Component to paste the recovery code when resetting a express users password. */
function PasteRecoveryCodeComponent(props: AllProps) {
  const { handleAction, server_shard } = props;
  const [validated, setValidated] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [show_modal, setShowModal] = useState<boolean>(false);
  const placeholder_text = "Paste your Recovery Code here.";
  const popover_message = "<p>This code can be found in the <b>Account Recovery File</b> that you downloaded from settings.</p><img src='common/password-recovery-example.png' alt='Example Code' width='250' height='300'>";

  // Description: Submit Backup Recovery code
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try { // uses a try catch block to catch any errors that may occur when trying to deserialize the user key (the recovery code might be incorrect or not a valid base64 string)
        const key_exchange_manager = KeyExchangeManager.initKeyExchangeManager();
        const _server_shard = Helpers.utf8Decode(Helpers.b64Decode(server_shard));
        const _user_shard = Helpers.utf8Decode(Helpers.b64Decode(form.backup_code.value.trim()));
        const user_key_string = combine([_server_shard, _user_shard]);
        const user_key = await key_exchange_manager.getDeserializeUserKey(user_key_string);
        setValidated(true);
        handleAction({ actionType: RecoverAccountUIActionTypes.SubmitRecoverCode, params: user_key });
      } catch (error) {
        setCode(undefined);
        handleAction({ actionType: RecoverAccountUIActionTypes.SubmitRecoverCode, params: undefined });
      }
    }
  };

  return <Card.Body className="p-3 m-1">
    <p>
      Open your <b>Account Recovery File</b> and then copy and paste the recovery code in the box below.
    </p>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="backup_code_tb" className="text-muted fs-5">Secret Code <InfoPopover message={popover_message} /></Form.Label>
        <Form.Control as="textarea" rows={5}
          id="backup_code_ta"
          autoFocus={true}
          required
          placeholder={placeholder_text}
          value={code}
          onChange={(e: FocusEvent<HTMLInputElement>) => setCode(e.currentTarget.value)}
          name="backup_code" autoComplete="off" />
      </Form.Group>
      <div className="btn-panel">
        <Button disabled={!code && !validated} type="submit">Continue</Button>
        <Button variant="outline-primary" onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Cancel</Button>
        <Button variant="link" type="button" className="ps-0 fs-6 float-end" onClick={() => setShowModal(true)}>Missing Recovery File?</Button>
      </div>
    </Form>
    <Modal show={show_modal} onHide={() => setShowModal(false)} centered aria-labelledby="missing recovery file">
      <Modal.Header>
        <Modal.Title className="alert-icon"><Icon className="ficon-alert-triangle" /> Missing Recovery File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>For enhanced security, PreVeil requires a recovery file to change your password. You can either...</p>
        <ul className="check-list">
          <li>
            <Icon className="icon-circle-small" />
            <p>
              Try to login again or
            </p>
          </li>
          <li>
            <Icon className="icon-circle-small" />
            <p>
              Create a new account, or
            </p>
          </li>
          <li>
            <Icon className="icon-circle-small" />
            <p>
              Cancel and find your recovery file
            </p>
          </li>
        </ul>
        <div className="btn-panel">
          <Button type="submit" onClick={() => { setShowModal(false); handleAction({ actionType: LoginWebUIActionTypes.ResetLogin }); }}>Login</Button>
          <Link className="btn btn-primary" to={`/${PublicRoutes.create_account_route}`}>Create Account</Link>
          <Button variant="outline-primary" onClick={() => setShowModal(false)}>Cancel</Button>
        </div>
      </Modal.Body>
    </Modal>
  </Card.Body>;
}
export default React.memo(PasteRecoveryCodeComponent);
