import { all, fork, call, put, takeEvery, select } from "redux-saga/effects";
import { ApproversList, CollectionServerUser, ExportShard, IFetchResponse, JSONOrgInfo, LogKey, RoledApprovalGroup } from "@preveil-api";
import {
  CryptoAPI, KeyStorageAPI, CollectionAPI, Account, PublicRoutes, KeyStorageData, Helpers, StatusCode, Message,
  KeystorageResponseErrorCodes, AccountIdentifiers, CreateAccountSteps, LoginWebSteps, AccountErrorMessages, MessageAnchors,
  MessageHandlerDisplayType, AppConfiguration, RequestTypes, PutUsersCollectionGrantApiArg, ExpressSubsumeErrorMessages,
  ExpressSubsumeSuccessMessages, createExportShards, wrappedLogKeys, DRIVE_MANAGER_STATE,
  LocalAccountStorage
} from "src/common";
import { accountActions, AccountActionTypes } from "./slice";
import { uiActions, appActions, driveActions } from "src/store";
import _ from "lodash";

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get local Accounts from Crypto (/account/list/local) on login / reload / refresh
// It takes an optional Param in action.payload for user_id?
// NOTES: - If the user_id is defined then call crypto to updateAndListLocalUsers
//            else just getLocalAccounts - both calls return same data        
//        - APP / CRYPTO SAGA 
// -----------------------------------------------------------------------------------------------------------------------------
function* getLocalAccountsRequest(action: ReturnType<typeof accountActions.getLocalAccounts>): Generator {
  const user_id = action.payload;
  const response = (yield call(CryptoAPI.getLocalAccounts, user_id)) as IFetchResponse;
  if (!response.isError) {
    const accounts = response.data.users;
    yield put(accountActions.getLocalAccountsSuccess(accounts));
    // Set Current account if user_id is passed to saga (if not then set first obj fetched)
    if (!!accounts && accounts.length) {
      const current_account = Account.getCurrentAccountbyUserId(accounts, user_id);
      (!!current_account && current_account instanceof Account) ?
        yield put(accountActions.setCurrentAccountSuccess(current_account)) :
        yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.get_started_route}`));
    } else { // No accounts then redirect to get_started pass url if there
      yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.get_started_route}`));
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_login_in, MessageHandlerDisplayType.logger), response));
  }
}

function* watchGetLocalAccounts() {
  yield takeEvery(AccountActionTypes.GET_LOCAL_ACCOUNTS, getLocalAccountsRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get Collection Server Current Accounts (CS) - for account set up and Login
//  NOTES:      - Collection Server call 
//              - Only need the current account information for this saga - DO NOT SET Accounts state (only 1 returned)
// -----------------------------------------------------------------------------------------------------------------------------
function* findCSCurrentUserRequest(action: ReturnType<typeof accountActions.findCollectionServerCurrentUser>): Generator {
  const isWeb = AppConfiguration.buildForWeb();
  const user_id = action.payload.user_id;
  const response = (yield call(CollectionAPI.postFindUsers, action.payload)) as IFetchResponse;
  if (!response.isError) {
    const current_account = Account.getCurrentAccountbyUserId(response.data.users);
    if (!!current_account && current_account instanceof Account) {
      if (isWeb) { // NOTE: Fetch historic keys if web
        yield put(accountActions.fetchKeyHistoryRequest(current_account));
      } else {
        yield put(accountActions.setCurrentAccountSuccess(current_account));
      }
    } else {
      yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.choose_account_route}${user_id ? `?user_id=${encodeURIComponent(user_id)}` : ""}`));
    }
  } else {
    yield put(uiActions.handleRequestErrors(new Message(AccountErrorMessages.default, MessageHandlerDisplayType.logger), response));
    yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.get_started_route}`));
  }
}

function* watchFindCSCurrentUserRequest() {
  yield takeEvery(AccountActionTypes.GET_CS_CURRENT_ACCOUNT, findCSCurrentUserRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description:  Get Key History 
//  NOTES:      - Collection Server call only for web accounts when user_key_version > 0
//              - On get keys update current account with all historic keys
// -----------------------------------------------------------------------------------------------------------------------------
function* fetchKeyHistoryRequest(action: ReturnType<typeof accountActions.fetchKeyHistoryRequest>): Generator {
  const current_account = action.payload;
  const account_ids = Account.getAccountIdentifiers(current_account);
  const response = (yield call(CollectionAPI.getKeyHistory, account_ids)) as IFetchResponse;
  // NOTE: Pass it to Fetch Key history before setting to state (otherwise it will all fall happen in a race condition without proper key)
  if (!response.isError && !!response.data) {
    current_account.updateUserKeys(response.data);

    yield put(accountActions.setCurrentAccountSuccess(current_account));
  } else {
    yield put(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_fetching_historic_keys, MessageHandlerDisplayType.logger), response));
  }
}

function* watchFetchKeyHistoryRequest() {
  yield takeEvery(AccountActionTypes.GET_KEY_HISTORY, fetchKeyHistoryRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get KSS User for Express Login from Keystorage Server
// Gets KSS User and unwraps the data using token and version (unwrapKSSData)
//    on Success calls findUsersRequest (collection Find User) to get data
//  NOTES:  - Keystorage Server call
//          - After verification is complete, we call appActions.getEvents(...) to check for user events.
//            This is required because a new recovery group event means new account_ids will be generated and so we must delay
//            calling accountActions.getKssUserSuccess(...) until that has completed.
//          - In the case we are upgrading the account, we can set the existing account_ids without fetching events
// -----------------------------------------------------------------------------------------------------------------------------
function* getKSSUserRequest(action: ReturnType<typeof accountActions.getKSSUser>): Generator {
  const user_id = action.payload.user_id;
  const auth_token = !!action.payload.auth_token ? action.payload.auth_token :
    Helpers.b64Encode((yield call(KeyStorageData.authenticationToken, user_id, action.payload.password)) as Uint8Array);
  const keystorage_user = Object.assign(action.payload, { auth_token });
  const response = (yield call(KeyStorageAPI.getUser, keystorage_user)) as IFetchResponse;
  const message_code = response.data.error || response.data.bad_parameter || "";
  const two_factor_codes = [KeystorageResponseErrorCodes.no_sms_auth_header, KeystorageResponseErrorCodes.no_totp_auth_header];
  if (!response.isError) { // Final step 2: SMS Verification
    yield put(accountActions.handleSetAuthToken(auth_token));
    const _account_ids = (yield call(KeyStorageData.unwrapKSSData, keystorage_user, response.data.wrapped_data)) as AccountIdentifiers;
    const phone_number = response.data.phone_number;
    const account_ids = {..._account_ids, phone_number};
    LocalAccountStorage.updatePVAccountSession(phone_number);
    if (!!action.payload.is_upgrade) {
      yield put(accountActions.handleSetAuthToken(undefined));
      yield put(accountActions.getKssUserSuccess(account_ids));
    } else {
      yield put(appActions.getEvents(account_ids, [account_ids.user_key], action.payload.password, auth_token));
    }
  } else if (response.status === StatusCode.UNAUTHORIZED_ERROR_CODE && !!message_code && two_factor_codes.includes(message_code)) {
    if (message_code === KeystorageResponseErrorCodes.no_sms_auth_header) {
      yield put(accountActions.setKssUserCredsSuccess({ keystorage_user, status: LoginWebSteps.SUBMIT_SMS_CODE }));
    } else if (message_code === KeystorageResponseErrorCodes.no_totp_auth_header) {
      yield put(accountActions.setKssUserCredsSuccess({ keystorage_user, status: LoginWebSteps.SUBMIT_TOTP_CODE }));
    }
  } else {
    const message = _.has(AccountErrorMessages, message_code) ? (AccountErrorMessages as any)[message_code] : AccountErrorMessages.default;
    const status = getLoginWebStepOnServerError(message_code);
    yield put(accountActions.setComponentStatus(status));
    yield put(uiActions.handleRequestErrors(new Message(message), response)); // Pass custom message here
  }
}

function* watchGetKSSUser() {
  yield takeEvery(AccountActionTypes.GET_KSS_USER, getKSSUserRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Set up a New local Account from Crypto (/put/account/[user_id])
// It takes an optional Param in action.payload for user_id? but not for Claim account
// NOTES: - Need to get all local accounts after success, because they are not returned with this call      
//        - APP / CRYPTO SAGA 
// -----------------------------------------------------------------------------------------------------------------------------
function* setNewLocalAccountRequest(action: ReturnType<typeof accountActions.setNewLocalAccount>): Generator {
  const user_id = action.payload.user_id;
  const response = (yield call(CryptoAPI.setupNewAccount, action.payload)) as IFetchResponse;
  if (!response.isError) {
    const current_account = response.data.current_account;
    if (!!current_account && current_account instanceof Account) {
      // This needs to be set before current_account to make sure proper hook state flow
      yield put(driveActions.setDriveState(DRIVE_MANAGER_STATE.NEW_APP_ACCOUNT));
      yield put(accountActions.setCurrentAccountSuccess(current_account));
      yield put(accountActions.getLocalAccounts(user_id));
    } else {
      yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.choose_account_route}${user_id ? `?user_id=${encodeURIComponent(user_id)}` : ""}`));
    }
  } else {
    const step = (response.data?.errors?.length > 0 && response.data.errors[0].cause === AccountErrorMessages.certificate_not_found) 
    ? CreateAccountSteps.CERTIFICATE_NOT_FOUND
    : CreateAccountSteps.SUBMIT_ERROR;
    const msg = AccountErrorMessages.account_activation_error.replace(MessageAnchors.user_id, user_id);
    yield put(accountActions.setComponentStatus(step));
    yield put(uiActions.handleRequestErrors(new Message(msg), response)); // Pass custom message here
  }
}

function* watchSetNewLocalAccount() {
  yield takeEvery(AccountActionTypes.SET_NEW_LOCAL_ACCOUNT, setNewLocalAccountRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Set a New KSS User for Express Create account from Keystorage Server
//  Returns:   Error 401 code with error: bad_sms_code when the user sends the first request (step 1 - phone number) 
//             Success 200 code when user adds the correct SMS code (step 2 - SMS code verification)
//  NOTES:  - Kestorage Server call
// -----------------------------------------------------------------------------------------------------------------------------
function* setNewKSSUserRequest(action: ReturnType<typeof accountActions.setNewKSSUser>): Generator {
  const keystorage_user = action.payload;
  const kss_user = keystorage_user.getKSSUser();
  const response = (yield call(KeyStorageAPI.createUser, kss_user, keystorage_user.sms_code)) as IFetchResponse;
  const message_code = response.data.error || response.data.bad_parameter || "";
  if (!!kss_user.phone_number) {
    if (!response.isError) { // Final step 2: SMS Verification
      yield put(accountActions.claimCSExpressAccoutRequest(keystorage_user));
    } else if (response.status === StatusCode.UNAUTHORIZED_ERROR_CODE && !!message_code && message_code === KeystorageResponseErrorCodes.no_sms_auth_header) {
      //  STEP 1: response code 401 means that SMS code was sent handle here
      yield put(accountActions.setKssUserCredsSuccess({ keystorage_user: kss_user, status: 7 }));
    } else {
      const message = _.has(AccountErrorMessages, message_code) ? (AccountErrorMessages as any)[message_code] : AccountErrorMessages.default;
      const status = getCreateAccountStepOnServerError(message_code);
      // Notes: handleRequestErrors is not typed yet to received custom messages
      yield put(accountActions.setComponentStatus(status));
      yield put(uiActions.handleRequestErrors(new Message(message), response)); // Pass custom message here
    }
  } else {
    yield put(accountActions.claimCSExpressAccoutRequest(keystorage_user));
  }
}

function* watchSetNewKSSUser() {
  yield takeEvery(AccountActionTypes.SET_NEW_KSS_USER, setNewKSSUserRequest);
}
// -----------------------------------------------------------------------------------------------------------------------------
// Description: Claim Express Account
//  NOTES:      - Collection Server call
//              - Pass An express account object
// -----------------------------------------------------------------------------------------------------------------------------
function* claimCSExpressAccoutRequest(action: ReturnType<typeof accountActions.claimCSExpressAccoutRequest>): Generator {
  const keystorage_user = action.payload;
  const response = (yield call(CollectionAPI.claimExpressAccount, keystorage_user)) as IFetchResponse;
  if (!response.isError) {
    const current_account = response.data.current_account;
    if (!!current_account && current_account instanceof Account) {
      // NOTE: Initialize Drive Collection (it does not exist as default) - will be handled in getDefaultCollectionHook
      // This needs to be set before current_account to make sure proper hook state flow
      yield put(driveActions.setDriveState(DRIVE_MANAGER_STATE.NEW_EXPRESS_ACCOUNT));
      yield put(accountActions.setCurrentAccountSuccess(current_account));
      yield put(accountActions.handleSetAuthToken(keystorage_user.auth_token));
      LocalAccountStorage.updatePVAccountSession(keystorage_user.phone_number);
    } else {
      yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.create_account_route}`));
    }
  } else {
    yield put(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_ERROR));
    yield put(uiActions.handleSetRedirectUrl(`/${PublicRoutes.create_account_route}`));
  }
}

function* watchClaimCSExpressAccoutRequest() {
  yield takeEvery(AccountActionTypes.SET_NEW_CS_EXPRESS_USER, claimCSExpressAccoutRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Upgrade Express Account
//  NOTES:      - Collection Server call 
//              - Pass An express account object
// -----------------------------------------------------------------------------------------------------------------------------
function* upgradeExpressAccountRequest(action: ReturnType<typeof accountActions.upgradeExpressAccount>): Generator {
  const account_ids = action.payload;
  const user_id = account_ids.user_id;
  const response = (yield call(CryptoAPI.upgradeExpressAccount, account_ids)) as IFetchResponse;
  if (!response.isError) { // Get crypto user LOGIN_PROCESS
    yield put(accountActions.getLocalAccounts(user_id));
  } else {
    console.error(AccountErrorMessages.upgrade_account_failed);
    yield put(uiActions.handleRequestErrors(new Message(AccountErrorMessages.upgrade_account_failed), response));
  }
}

function* watchUpgradeExpressAccount() {
  yield takeEvery(AccountActionTypes.UPGRADE_EXPRESS_ACCOUNT, upgradeExpressAccountRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Respond to Subsume Request
//  NOTES:      - Step 1 in the PVX subsume flow
//              - Collection Server call
//              - Pass an object with AccountIdentifiers, UserRequest and boolean
// -----------------------------------------------------------------------------------------------------------------------------
function* respondToSubsumeRequest(action: ReturnType<typeof accountActions.respondToSubsumeRequest>): Generator {
  const request = action.payload.request;
  if (AppConfiguration.buildForApp() || request.type !== RequestTypes.subsume_account || !request.org_details) {
    return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.unsupported_request_type)));
  }
  const params = {
    requestId: request.request_id,
    entityId: request.org_details.org_id,
    payload: request.payload,
    body: {
      requester_user_id: request.requester_id,
      approve: action.payload.approve
    }
  };
  const response = (yield call(CollectionAPI.putUsersOrgsByEntityIdRequestsAndRequestId, action.payload.account_ids, params)) as IFetchResponse;
  if (!!response.isError) {
    let message = action.payload.approve ? ExpressSubsumeErrorMessages.error_accepting : ExpressSubsumeErrorMessages.error_rejecting;
    if (response.status === 409) {
      message = ExpressSubsumeErrorMessages.repeated_response;
    }
    return yield put(uiActions.handleRequestErrors(new Message(message), response));
  }
  if (!action.payload.approve) {
    yield put(uiActions.handleSetMessage(new Message(ExpressSubsumeSuccessMessages.success_rejecting)));
  } else {
    yield put(accountActions.getOrganizationInfo({ account_ids: action.payload.account_ids, request }));
  }
}

function* watchRespondToSubsumeRequest() {
  yield takeEvery(AccountActionTypes.RESPOND_TO_SUBSUME_REQUEST, respondToSubsumeRequest);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get Organization Info
//  NOTES:      - Step 2 in the PVX subsume flow
//              - Collection Server call
// -----------------------------------------------------------------------------------------------------------------------------
function* getOrganizationInfo(action: ReturnType<typeof accountActions.getOrganizationInfo>): Generator {
  const { id, request, org_info, account_ids } = action.payload;
  const org_id = request?.org_details?.org_id || id;
  if (!org_id) {
    return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), { org_id }));
  }
  const response = (yield call(CollectionAPI.getOrganizationInfo, account_ids, org_id)) as IFetchResponse<JSONOrgInfo>;
  if (!!response.isError) {
    if (!!request) {
      return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), response));
    }
    if (!!id) {
      return yield put(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_fetching_org_info, MessageHandlerDisplayType.logger), response));
    }
  }
  if (!!org_info) {
    yield put(accountActions.setCurrentAccountOrganizationSuccess({ ...org_info, org_name: response.data.display_name }));
  }
  // current_account.updateOrgInfo(response.data); // TO DO: Need to update the account through slice - TBD
  if (!!request) {
    yield call(getApprovalGroup, account_ids, response.data);
  }
}

function* watchGetOrganizationInfo() {
  yield takeEvery(AccountActionTypes.GET_ACCOUNT_ORGANIZATION_INFO, getOrganizationInfo);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get Approval Group
//  NOTES:      - Step 3 in the PVX subsume flow
//              - Collection Server call
// -----------------------------------------------------------------------------------------------------------------------------
function* getApprovalGroup(account_ids: AccountIdentifiers, org_info: JSONOrgInfo): Generator {
  const current_account = (yield select(state => state.account.current_account)) as Account;
  const export_group_info = org_info.roled_approval_groups.export_approval_group;
  if (!export_group_info) {
    yield call(getLogKeys, current_account, org_info);
    return yield call(handleSubsumeStateComplete);
  }
  const params = {
    entityId: org_info.id,
    groupId: export_group_info.group_id,
    version: export_group_info.version
  };
  const response = (yield call(CollectionAPI.getUsersOrgsByEntityIdGroupsAndGroupId, account_ids, params)) as IFetchResponse<ApproversList>;
  if (!!response.isError || response.data.approvers.length === 0) {
    yield all([
      put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.error_fetching_approval_group, MessageHandlerDisplayType.logger), response)),
      call(getLogKeys, current_account, org_info)
    ]);
    return yield call(handleSubsumeStateComplete);
  }
  const approver_ids = response.data.approvers.map(u => ({ user_id: u.user_id, account_version: u.account_version }));
  const find_users_response = (yield call(CollectionAPI.postFindUsers, account_ids, approver_ids, true)) as IFetchResponse<{ users: CollectionServerUser[] }>;
  if (!!find_users_response.isError || find_users_response.data.users.length !== response.data.approvers.length) {
    yield all([
      put(uiActions.handleRequestErrors(
        new Message(ExpressSubsumeErrorMessages.error_fetching_approvers, MessageHandlerDisplayType.logger), find_users_response)),
      call(getLogKeys, current_account, org_info)
    ]);
    return yield call(handleSubsumeStateComplete);
  }
  yield all([
    call(submitExportShards, current_account, find_users_response.data.users, response.data, export_group_info, org_info.id),
    call(getLogKeys, current_account, org_info, true)
  ]);
  yield call(handleSubsumeStateComplete);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Submit Export Shards
//  NOTES:      - Step 4 in PVX subsume flow (parallel with getLogKeys)
//              - Collection Server call
//              - SubsumeApproval call
// -----------------------------------------------------------------------------------------------------------------------------
function* submitExportShards(
  current_account: Account,
  approver_users: CollectionServerUser[],
  approvers_list: ApproversList,
  export_group: RoledApprovalGroup,
  entityId: string
): Generator {
  try {
    const account_ids = Account.getAccountIdentifiers(current_account);
    const shards = (yield call(createExportShards, current_account.user_keys, approver_users, approvers_list)) as ExportShard[];
    const params = {
      entityId,
      body: {
        shards,
        group_version: export_group.version,
        group_id: export_group.group_id
      }
    };
    const response = (yield call(CollectionAPI.postUsersOrgsByEntityIdSubmitExportShards, account_ids, params)) as IFetchResponse;
    if (!!response.isError) {
      return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), response));
    }
    yield put(accountActions.setSubsumeSuccess({ submit_export_shards_complete: true }));
  } catch (error) {
    yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), error));
  }
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Get Log Keys
//  NOTES:      - Step 4/5 in PVX subsume flow (parallel with submitExportShards)
//              - Collection Server call
//              - Includes log keys to all collections (including default and mail)
// -----------------------------------------------------------------------------------------------------------------------------
function* getLogKeys(current_account: Account, org_info: JSONOrgInfo, has_export_group: boolean = false): Generator {
  const account_ids = Account.getAccountIdentifiers(current_account);
  const response = (yield call(CollectionAPI.getUsersLogKeys, account_ids)) as IFetchResponse<LogKey[]>;
  if (!!response.isError) {
    return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), response));
  }
  yield call(grantLogKeys, current_account, response.data, org_info, has_export_group);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Grant Log Keys
//  NOTES:      - Step (4.5/5.5) in PVX subsume flow (also in parallel with submitExportShards)
//              - Collection Server call
//              - SubsumeApproval call
// -----------------------------------------------------------------------------------------------------------------------------
function* grantLogKeys(current_account: Account, log_keys: LogKey[], org_info: JSONOrgInfo, has_export_group: boolean): Generator {
  try {
    // NOTE: REMOVED THE  org_info.admin_group_id.String() since it is not a UUID in JSONOrgInfo
    const grant_log_key_params = (yield call(wrappedLogKeys,
      current_account, org_info.admin_group_key, org_info.admin_group_id, log_keys)) as Array<(PutUsersCollectionGrantApiArg | undefined)>;
    for (const params of grant_log_key_params) {
      if (!params) {
        return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.error_granting_key), { params }));
      }
      const response = (yield call(CollectionAPI.putUsersCollectionGrant, Account.getAccountIdentifiers(current_account), params)) as IFetchResponse;
      if (!!response.isError) {
        return yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.error_granting_key), response));
      }
    }
    if (has_export_group) {
      return yield put(accountActions.setSubsumeSuccess({ grant_log_keys_complete: true }));
    }
    yield put(accountActions.setSubsumeSuccess({ grant_log_keys_complete: true, submit_export_shards_complete: true }));
  } catch (error) {
    yield put(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.incomplete_subsume), error));
  }
}

// -----------------------------------------------------------------------------------------------------------------------------
// Description: Handle Subsume State Success
//  NOTES:      - Waits for both parallel log key and shard submission calls to complete then displays success
// -----------------------------------------------------------------------------------------------------------------------------
function* handleSubsumeStateComplete(): Generator {
  const grant_log_keys_complete = (yield select(state => state.account.grant_log_keys_complete)) as boolean;
  const submit_export_shards_complete = (yield select(state => state.account.submit_export_shards_complete)) as boolean;
  if (!!grant_log_keys_complete && !!submit_export_shards_complete) {
    yield put(uiActions.handleSetMessage(new Message(ExpressSubsumeSuccessMessages.success_approving)));
  }
}

// -----------------------------------------------------------------------------------------------------------------------------
// Export ALL Account Saga:
// -----------------------------------------------------------------------------------------------------------------------------
export function* accountSaga() {
  yield all([
    fork(watchGetLocalAccounts),
    fork(watchGetKSSUser),
    fork(watchFindCSCurrentUserRequest),
    fork(watchSetNewLocalAccount),
    fork(watchSetNewKSSUser),
    fork(watchClaimCSExpressAccoutRequest),
    fork(watchUpgradeExpressAccount),
    fork(watchFetchKeyHistoryRequest),
    fork(watchRespondToSubsumeRequest),
    fork(watchGetOrganizationInfo)
  ]);
}

// -----------------------------------------------------------------------------------------------------------------------------
// Account Saga Utilities
// -----------------------------------------------------------------------------------------------------------------------------
// Description: Account Get Correct step depending on server error code 
export function getLoginWebStepOnServerError(message_code: string): number {
  let status: number;
  switch (message_code) {
    case KeystorageResponseErrorCodes.bad_password:
      status = LoginWebSteps.SUBMIT_PASSWORD;
      break;
    case KeystorageResponseErrorCodes.bad_totp_code:
      status = LoginWebSteps.SUBMIT_TOTP_CODE;
      break;
    case KeystorageResponseErrorCodes.bad_sms_code:
    case KeystorageResponseErrorCodes.bad_auth_header:
      status = LoginWebSteps.SUBMIT_SMS_CODE;
      break;
    default:
      status = LoginWebSteps.SUBMIT_EMAIL;
  }
  return status;
}

// Description: Account Get Correct step depending on server error code 
export function getCreateAccountStepOnServerError(message_code: string): number {
  let status: number;
  switch (message_code) {
    case KeystorageResponseErrorCodes.bad_sms_code:
    case KeystorageResponseErrorCodes.bad_auth_header:
      status = CreateAccountSteps.SUBMIT_SMS_CODE;
      break;
    case KeystorageResponseErrorCodes.bad_password:
      status = CreateAccountSteps.SUBMIT_PASSWORD;
      break;
    case KeystorageResponseErrorCodes.phone_number:
      status = CreateAccountSteps.SUBMIT_PHONE;
      break;
    default:
      status = CreateAccountSteps.SUBMIT_EMAIL;
  }
  return status;
}

// Exporting Sagas for testing
export { getLocalAccountsRequest, setNewLocalAccountRequest, getKSSUserRequest };
