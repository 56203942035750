import React, { useState, useEffect, ReactElement, useRef } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ActionHandlerFunction, AccountType } from "@preveil-api";
import {
  CreateAccountUIActionTypes, RegexHelper, usePostUsersTypeMutation, usePostUsersMutation, account_types, PublicRoutes, AppConfiguration,
  PostUsersApiResponse, AccountErrorMessages, dayjs, Regex_Patterns, StatusCode
} from "src/common";
import { Loading, Icon } from "src/components";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";

type AllProps = {
  user_id?: string;
  children?: ReactElement;
  handleAction: ActionHandlerFunction;
}

const navigationUrl = {
  web: {
    full: PublicRoutes.connect_account_route,
    express: PublicRoutes.login_route
  },
  app: {
    full: PublicRoutes.login_route,
    express: PublicRoutes.upgrade_account_route
  }
};

// Description: Handle Login submit send to parent component
function EmailFormComponent(props: AllProps) {
  const { user_id, children, handleAction } = props;
  const navigate = useNavigate();
  const [getAccountType] = usePostUsersTypeMutation();
  const [updateUser, { isLoading }] = usePostUsersMutation();
  const [captcha_token, setCaptchaToken] = useState<string>();
  const [skip_recaptcha, setSkipRecaptcha] = useState<boolean>(false);
  const [load_recaptcha, setLoadRecaptcha] = useState<boolean>(true);
  const recaptcha_site_key = process.env.REACT_APP_RECAPTCHA_KEY;
  const cancelButtonRef = useRef<HTMLAnchorElement>(null);
  const schema = yup.object().shape({
    user_id: yup.string()
      .required("This is a required field")
      .matches(Regex_Patterns.EMAIL_REGEX,
        "Please enter a valid email"),
  });

  useEffect(() => {
    user_id && handleUserValidation(user_id);
  }, []);

  // Description: Do an account type check on load and on submit
  function handleUserValidation(userId: string, submit: boolean = false) {
    const is_web = AppConfiguration.buildForWeb();
    return getAccountType({ userId }).unwrap()
      .then((params: { account_type: AccountType }) => {
        const account_type = params.account_type;
        submit && account_type === account_types.nouser ? handleUpdateUserCallback(userId) :
          account_type === account_types.express ? navigate(`/${is_web ? navigationUrl.web.express : navigationUrl.app.express}?user_id=${userId}`) :
            account_type === account_types.full && navigate(`/${is_web ? navigationUrl.web.full :
              navigationUrl.app.full}?user_id=${encodeURIComponent(userId)}`);
      })
      .catch((stack) => {
        handleError(AccountErrorMessages.default, stack);
      });
  };

  // Description: Handle Update User after Validating email
  function handleUpdateUserCallback(user_id: string) {
    updateUser({ user_id, captcha_token }).unwrap()
      .then((params: PostUsersApiResponse) => {
        handleAction({
          actionType: CreateAccountUIActionTypes.SubmitEmail,
          params: Object.assign({}, params, { user_id, expires: dayjs.utc(params.expires) })
        });
      })
      .catch((stack) => {
        const msg = !!stack && stack.status === StatusCode.FORBIDDEN_DOMAIN ? AccountErrorMessages.domain_lock_error : AccountErrorMessages.bad_user_email;
        handleError(msg, stack);
      });
  }

  // Description: Send error up and reset form
  function handleError(message: string, stack?: any) {
    handleAction({
      actionType: CreateAccountUIActionTypes.PageError,
      params: { message, stack }
    });
  };

  // Description: is form valid
  function isValid(email: string): boolean {
    return RegexHelper.testEmailAddress(email);
  };

  // Description: Handle form submit
  function handleSubmit(formValues: { user_id: string }) {
    // NOTE: Always trim and lowercase user_id
    const _user_id = formValues.user_id.trim().toLowerCase();
    if (isValid(_user_id)) { // Validate email in form
      handleUserValidation(_user_id, true);
      setLoadRecaptcha(false);
    } else {
      handleError(AccountErrorMessages.account_user_id_error);
    };
  }

  function onRecaptchaChange(value: any) {
    setCaptchaToken(value);
  }

  const handleSkipRecaptchaCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipRecaptcha(event.target.checked);
  };

  // Description: Data has been fetched and this handles returned account type
  return isLoading ? <Loading className="in-place" /> :
    <Card.Body>
      {children}
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          user_id: user_id || "",
        }} >
        {({ dirty, values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="user_id_tb" className="fs-6" >PreVeil User ID</Form.Label>
              <div className="input-icon-prepend">
                <Icon className="ficon-user" />
                <Form.Control
                  id="user_id_tb"
                  type="email"
                  autoFocus={true}
                  placeholder="Enter your email address"
                  name="user_id"
                  value={values.user_id}
                  onBlur={(e) => {
                    if (cancelButtonRef.current && cancelButtonRef.current.contains(e.relatedTarget as Node)) { return; }
                    handleBlur(e);
                  }}
                  onChange={handleChange}
                  isInvalid={!!errors.user_id && touched.user_id} />
              </div>
              <Form.Control.Feedback type="invalid" className={`${!!errors.user_id && touched.user_id ? "d-block" : ""}`}>
                {errors.user_id}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mb-3">
              {
                load_recaptcha && recaptcha_site_key ?
                  <ReCAPTCHA
                    sitekey={recaptcha_site_key}
                    onChange={onRecaptchaChange} /> :
                  <p>Recaptcha site key not defined.</p>
              }
              <input
                type="checkbox"
                id="skiprecaptcha"
                className="d-none"
                onChange={handleSkipRecaptchaCheckbox} />
            </div>
            <div className="btn-panel">
              <Button type="submit" disabled={!isValid || (!skip_recaptcha && !captcha_token)} >Send Verification Email</Button>
              <Link ref={cancelButtonRef} className="btn btn-outline-secondary" to={`/${PublicRoutes.get_started_route}`}>Cancel</Link>
            </div>
            <small className="hide-app fs-6">Already a PreVeil Express user?&nbsp;<Link title="Already a PreVeil Express user? Sign in" to={`${PublicRoutes.login_route}`}>Sign In</Link>
            </small>
          </Form>
        )}
      </Formik>
    </Card.Body>;
}

export default React.memo(EmailFormComponent);
