import { useState, useEffect, useCallback, useRef } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { FSMessage, FSStatus, FSRole, Collection as FSCollection } from "src/common/keys/protos/collections_pb";
import {
  Account, EntryItemBase, useAppDispatch, useGetPermissions, DriveEntryType, Message, MessageHandlerDisplayType, DriveErrorMessages,
  useSendRequestMutation, CollectionFilesyncAPI, NodePermissionSet, NodePermissions, Collection, CollectionEntity, useAppSelector, DriveRequest,
  COLLECTION_PROTOCOL_VERSIONS, PermissionSetType, getEnumKey, Helpers, PermissionSet, decryptRootName, DefaultCollectionName, ErrorStackItem
} from "src/common";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

export function useGetCollectionInfo(current_account: Account, entry?: EntryItemBase, set_state: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const root_collection_id = useAppSelector((state: RootState) => state.drive.root_info?.collection_id);
  const current_collection = useAppSelector((state: RootState) => state.drive.current_collection);
  const collections = useAppSelector((state: RootState) => state.drive.collections);
  const [sendRequest] = useSendRequestMutation();
  const [error, setError] = useState<string>();
  const [collection_info, setCollectionInfo] = useState<CollectionEntity | undefined>();
  const [collection_id, setCollectionId] = useState<string>("");
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const dispatch = useAppDispatch();
  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  const defaultPermissionsRef = useRef(default_permissions);
  function setDefaultPermissionsRef(_default_permissions: PermissionSetType[]) {
    defaultPermissionsRef.current = _default_permissions;
  }

  const decodedNameRef = useRef("");
  function setDecodedNameRef(_decoded_name: string) {
    decodedNameRef.current = _decoded_name;
  }

  // Description: On Entry change get permissions and begin the flow process to fetch the selected directory
  useEffect(() => {
    !!entry ?
      initGetCollectionInfo(entry) : destroyCollectionInfo();
  }, [entry]);

  // Description: Initialize fetch by type LINK, DIR or FILE (TBD)
  useEffect(() => {
    if (!!permissions && !!entry) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      fetchCollectionProtocolVersion(entry, permissions);
    }
  }, [permissions]);

  // Description: On rekey reset the permissions and get collection info again
  useEffect(() => {
    setDefaultPermissionsRef(default_permissions);
  }, [default_permissions]);

  // Description: Use this for reinitializing a call to get the collection information
  function initGetCollectionInfo(_entry: EntryItemBase) {
    setDecodedNameRef("");
    const _collection_id = _entry.type === DriveEntryType.LINK && !!_entry.linked_collection_id ?
      _entry.linked_collection_id : _entry.collection_id;
    setCollectionId(_collection_id);
    getPermissions(_collection_id, defaultPermissionsRef.current);
  }

  // Description: Destroy State to be able to change
  function destroyCollectionInfo() {
    setCollectionInfo(undefined);
    setCollectionId("");
    destroyPermissions();
    setDecodedNameRef("");
  }

  // Description: Get Entry collection's Protocol version
  async function fetchCollectionProtocolVersion(_entry: EntryItemBase, _permissions: PermissionSetType) {
    const drive_request = !!_permissions ?
      await CollectionFilesyncAPI.getCollectionAttributes(current_account, _permissions, collection_id) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const attributes = message.getCollectionAttributes();
        const collection_protocol_version = attributes?.getProtocolVersion();
        if (!!attributes) {
          ResolveCollectionName(attributes, _permissions, collection_protocol_version);
          // NOTE: If it is a LINK need to get the correct node_id
          if (_entry.type === DriveEntryType.LINK || _entry.type === DriveEntryType.FILE) {
            // NOTE: IF file - NEED TO PASS Parent collection node_id 
            (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2) ? getACLTree(_entry, _permissions) : getRootInfo(_permissions);
          } else {
            // NOTE: Return to this after CS makes changes to FETCH_NODE_PERMISSIONS:
            collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2 ?
              fetchNodePermissions(collection_id, _entry.id, _permissions, collection_protocol_version) :
              getRootInfo(_permissions);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_protocol_version });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_protocol_version },
          message);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_collection_protocol_version);
  }

  // Description: Get the Collection Name from the correct source
  async function ResolveCollectionName(attributes: FSCollection.Attributes, _permissions: PermissionSetType, collection_protocol_version?: COLLECTION_PROTOCOL_VERSIONS) {
    // NOTE: Set Collection Name for V2 and defaultCollection
    if (collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2) {
      const decoded_name = await getMaintainersName(attributes, _permissions);
      !!decoded_name && setDecodedNameRef(decoded_name);
    } else if (root_collection_id === collection_id) {
      setDecodedNameRef(DefaultCollectionName);
    }
  }

  // Description: Decode maintaners name
  async function getMaintainersName(attributes: FSCollection.Attributes, _permissions: PermissionSetType): Promise<string | undefined> {
    const wrapped_name = attributes.getName()?.getContent_asU8();
    const key_version = attributes.getName()?.getKeyVersion();
    if (!!wrapped_name && !!_permissions) {
      const permission_set = PermissionSet.init(_permissions);
      const permission = permission_set.permission(FSRole.READER, key_version) || permission_set.permission(FSRole.ACCESS, key_version);
      const user_key = !!permission ? await permission.key(current_account) : null;
      const decoded = !!user_key ? Helpers.utf8Decode(await user_key.encryption_key.unseal(wrapped_name)) : "";
      return decoded;
    }
  }

  // Description: Get V1 directory node_id
  async function getRootInfo(_permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.getRootInfoRequest(collection_id);
    const drive_request = !!_permissions ? await DriveRequest.initializeRequest(request, current_account, _permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const message_root_info = message.getRootInfo();
        if (!!message_root_info) {
          const wrapped_dir_key = message_root_info.getWrappedDirKey();
          const wrapped_dir_key_version = !!wrapped_dir_key ? wrapped_dir_key.getKeyVersion() : undefined;
          const decoded_name = !!_permissions ?
            await decryptRootName(message_root_info.getName_asU8(), _permissions, current_account, wrapped_dir_key_version) : (entry?.name || "");
          setDecodedNameRef(decoded_name);
          const root_info = {
            collection_id,
            id: message_root_info.getId_asB64()
          };
          fetchNodePermissions(root_info.collection_id, root_info.id, _permissions, COLLECTION_PROTOCOL_VERSIONS.V1);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info },
          message);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_root_info);
  }

  // Description: Get V2 directory node_id
  async function getACLTree(_entry: EntryItemBase, _permissions: PermissionSetType) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(collection_id);
    const drive_request = !!_permissions ? await DriveRequest.initializeRequest(request, current_account, _permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const acl_tree = message.getAclTree()?.getDirsList();
        const dir = _.find(acl_tree, (dir: FSMessage.Dir) => dir.getIsRoot());
        const root_node_id = _entry.type === DriveEntryType.FILE ? _entry.id : (!!dir && dir instanceof FSMessage.Dir) ? dir.getId_asB64() : null;
        if (!!root_node_id) {
          fetchNodePermissions(collection_id, root_node_id, _permissions);
        } else {
          // NOTE: This collection does not have a true root, an Effective Root node which means that the immediate child / children nodes are directories with ACL  
          // and current user is not the owner => Share component should be disabled for this cases.
          const effective_root = !!acl_tree && acl_tree.length > 0 ? acl_tree[0] : undefined;
          const effective_root_permissions = NodePermissions.createEffectiveRootNodePermissions(current_account.user_id, collection_id);
          const effective = new FSMessage.Dir();
          effective.setMaintainerId(_entry.id);
          effective.setMaintainerId(_entry.id);
          effective.setIsRoot(false);
          const collection_info = new Collection(_entry, [effective_root_permissions], current_account.user_id, "",
            COLLECTION_PROTOCOL_VERSIONS.V2, decodedNameRef.current, effective_root).collection_info;
          handleSuccess(collection_info);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree },
          message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
  }

  // Description: Fetch node permissions with Grantees
  // NOTE: NEED TO PASS THE CORRECT COLLECTION_PROTOCOL_VERSIONS as it will default to V2
  async function fetchNodePermissions(
    _collection_id: string,
    node_id: string,
    _permissions: PermissionSetType,
    collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS = COLLECTION_PROTOCOL_VERSIONS.V2
  ) {
    const drive_request = !!_permissions ?
      await CollectionFilesyncAPI.getNodePermissions(current_account, _permissions, _collection_id, node_id) : null;
    async function callback(message: FSMessage) {
      const dir = message.getDir();
      const maintainer_id = dir?.getMaintainerId_asB64();
      // NOTE: if dir.getMaintainerId_asB64() !== dir.getId_asB64() => NEED TO FETCH ACL with maintainer_id
      // Call only to get self permissions - RETURN ONLY node_permissions.permissions
      if (!!maintainer_id && dir?.getId_asB64() !== maintainer_id) {
        fetchNodePermissions(collection_id, maintainer_id, _permissions, collection_protocol_version); // NOTE: Def. V2 set by default
      } else {
        const grantees_flat = message.getGranteesFlatList();
        const node_permissions: NodePermissionSet[] = [];
        // NOTE: IF NODE IS DELETED IT WILL RETURN AND EMPTY ARRAY FOR grantees_flat
        if (grantees_flat.length > 0 && !!entry) {
          _.forEach(grantees_flat, (grantee_flat: FSMessage.GranteeFlat) => {
            const grantee = NodePermissions.parseGranteeFlat(grantee_flat, collection_protocol_version);
            if (!!grantee && !!entry) {
              // NOTE: Force disable share for DIR items that are children of V1 collections that are not default_collections
              const disable_share = entry.type === DriveEntryType.DIR && collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 && root_collection_id !== _collection_id;
              node_permissions.push(new NodePermissions(grantee, disable_share, !!entry.is_locked).node_permission_set);
            }
          });
          if (!!node_permissions) {
            // NOTE: Collection v1 do not have maintainer ids and in the case of V2 node_id is the maintainer id passed into this function
            //       Pass node_id for maintainer_id for V2 collections but wil be set as root_id for V1 collections within the Collection class
            // NOTE: Get Files maintainer Id from entry or current collection
            const _maintainer_id = entry.type === DriveEntryType.FILE ?
              (!!entry.maintainer_id ? entry.maintainer_id :
                (!!current_collection?.maintainer_id && current_collection.collection_id === entry.collection_id) ?
                  current_collection.maintainer_id : "") :
              node_id;
            const collection_info = new Collection(entry, node_permissions, current_account.user_id, _maintainer_id, collection_protocol_version, decodedNameRef.current, dir).collection_info;
            handleSuccess(collection_info);
          } else {
            handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_node_permissions });
          }
        }
        //  else {
        //   For detail view if something is changed external (a delete for example) to the UI, the Permissions will get emptied to []
        //   NOTE: this can happen when sending an invite to unclaimed account dont pass error here
        // }
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_node_permissions);
  }

  // Description: Add info to the state and the update global store for access on other components
  function handleSuccess(info: CollectionEntity) {
    if (set_state) {
      dispatch(driveActions.setCurrentCollection(info));
    } else {
      setCollectionInfo(info);
      const new_collections = Object.assign({}, collections);
      new_collections[info.id] = info;
      dispatch(driveActions.setCollections(new_collections));
    }
  }

  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null);
    }
  }

  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null, display_type?: MessageDisplayType) {
    const _display_type = !!display_type ? display_type : MessageHandlerDisplayType.logger;
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetCollectionInfo Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, _display_type), stack));
    setError(message);
  }

  // Description: Use this callback to refresh or refetch the collection Information
  const getCollectionInfo = useCallback((_entry?: EntryItemBase) => {
    (!!_entry && !!permissionsRef.current) ? initGetCollectionInfo(_entry) :
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_collection_information });
  }, []);

  return {
    collection_info,
    collection_info_error: error,
    getCollectionInfo
  };
}
