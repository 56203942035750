import { KSSargs } from "src/common/drive/types";
import { keyStorageServerAPI as api } from "../base/base-api";

// ------------------------------------------------------------
// RTK Queries Calls: 
// ------------------------------------------------------------
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.user_id}`,
        method: "DELETE",
        params: { auth_token: queryArg.auth_token },
      }),
    }),
    isRecoverySetUp: build.query<IsRecoverySetUpApiResponse, IsRecoverySetUpApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.user_id}/shard_metadata`,
        method: "GET",
        params: { auth_token: queryArg.auth_token },
      }),
    }),
    getAuthenticationMethod: build.query<GetAuthenticationMethodApiResponse, GetAuthenticationMethodApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.user_id}/secondary_auth_method`,
        method: "GET",
        params: { auth_token: queryArg.auth_token },
      }),
    }),
  }),
  overrideExisting: false,
});

// GENERAL EXPORTS OF TYPES AND HOOKS:
export { injectedRtkApi as keystorageApi };

// ------------------------------------------------------------
// Request and Response types:
// ------------------------------------------------------------
export type DeleteUserApiResponse = unknown;
export type DeleteUserApiArg = KSSargs;
export type IsRecoverySetUpApiResponse = {
  server_shard_exists: boolean;
};
export type IsRecoverySetUpApiArg = KSSargs;

export type GetAuthenticationMethodApiResponse = {
  authentication_method: string; // either "sms" or "totp"
};
export type GetAuthenticationMethodApiArg = KSSargs;

// Export hooks
export const { useDeleteUserMutation, useIsRecoverySetUpQuery, useGetAuthenticationMethodQuery } = injectedRtkApi;
