import React, { FormEvent, FocusEvent, Dispatch, SetStateAction, KeyboardEvent } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { DriveUIActionTypes, KeyCode } from "src/common";
import { Icon } from "src/components";

type AllProps = {
    search_term?: string;
    setSearchTerm: Dispatch<SetStateAction<string | undefined>>;
    handleAction: ActionHandlerFunction;
}

function SearchFormComponent(props: AllProps) {
    const { search_term, setSearchTerm, handleAction } = props;

    // Description: Submit Search for Drive
    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        setSearchTerm(undefined);
    };

    // Description: Set the current state for controlled form and propagate to store state
    function handleChange(e: FocusEvent<HTMLInputElement>) {
        setSearchTerm(e.currentTarget.value);
    }

    // Description: Block clear on entry
    function handleOnKeyEvent(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === KeyCode.Enter) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    return <Form noValidate onSubmit={handleSubmit} >
        <Form.Label htmlFor="search_drive" visuallyHidden>Search My PreVeil</Form.Label>
        <InputGroup>
            <Form.Control
                id="search_drive"
                autoFocus={true}
                name="search_drive"
                autoComplete="off"
                placeholder="Search by item name..."
                aria-label="Search by item name..."
                value={search_term || ""}
                onChange={handleChange}
                onKeyDown={handleOnKeyEvent} />
            <Button variant="icon" type="submit" disabled><Icon className="ficon-search text-muted" /></Button>
            {
                !!search_term &&
                <Button variant="icon" className="text-muted-light"
                    onClick={() => handleAction({ actionType: DriveUIActionTypes.Reset })}>
                    <Icon className="ficon-x-circle" /></Button>
            }
        </InputGroup>
    </Form>;
}

export default React.memo(SearchFormComponent);
