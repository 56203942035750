import React, { useState, useEffect } from "react";
import { Collapse, Card, Button } from "react-bootstrap";
import { ThreadMessage, ThreadAttachment } from "@preveil-api";
import { IFRAME_HEAD, Account, useAppDispatch, Message, MailErrorMessages, MessageHandlerDisplayType, MailThreadMessage, MailMessageBody } from "src/common";
import { Loading, Icon, InlineIframe } from "src/components";
import { uiActions } from "src/store";
import { Attachment } from ".";
import _ from "lodash";

type AllProps = {
  is_web: boolean;
  default_open: boolean;
  collection_id: string;
  message: ThreadMessage;
  messages: ThreadMessage[];
  current_account: Account;
  handleGetBody: (message: ThreadMessage) => void;
}

function MessageBodyComponent(props: AllProps) {
  const { message, messages, default_open, handleGetBody } = props;
  const [message_body, setMessageBody] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(true);
  const [previous_message, setPreviousMessage] = useState<ThreadMessage>();
  const [previous_message_body, setPreviousMessageBody] = useState<string | null>(null);
  const [open_prev, setOpenPrev] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  // Description: Send message body errors directly to logger
  useEffect(() => {
    !!message.error &&
      dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.error_getting_message, MessageHandlerDisplayType.logger),
        { error: message.error, message }));
  }, []);

  // Description: Set to close previous as default state, after rendering the iframe (for height purposes)
  useEffect(() => {
    handleMessageBody(message.html);
  }, [messages]);

  // Description: Sanitize html and create MessageBody content
  function handleMessageBody(_message_body: string | null) {
    // NOTE: Accept empty strings:
    if (_message_body !== null && _message_body !== undefined) {
      const _previous_message = MailThreadMessage.getPreviousMessage(message, messages);
      const processed_message = new MailMessageBody(message, _previous_message).processed_message;
      setMessageBody(processed_message.message_body || "");
      (!!_previous_message && _previous_message.html !== previous_message?.html) && handlePreviousMessageBody(_previous_message);
    }
    setOpen(default_open || !!_message_body);
  }

  // Description: Sanitize previous messages body
  function handlePreviousMessageBody(_previous_message: ThreadMessage) {
    setPreviousMessage(_previous_message);
    const __previous_message = MailThreadMessage.getPreviousMessage(_previous_message, messages);
    const processed_message = new MailMessageBody(_previous_message, __previous_message).processed_message;
    if (!!processed_message) {
      const _previous_message_body = MailThreadMessage.buildPreviousMailBody(processed_message);
      setPreviousMessageBody(!!_previous_message_body ? _previous_message_body : `<p class='error'>${MailErrorMessages.error_getting_message}</p>`);
    }
  }

  // Description: trigger open of a body for call when message_body is not avail
  function handleOpenBody(new_open: boolean) {
    (!message.message_body && new_open) && handleGetBody(message);
    setOpen(new_open);
  }

  return <>
    <Collapse in={open}>
      <div>
        <Card.Body>
          {
            message_body !== null ?
              <InlineIframe
                iframeHead={IFRAME_HEAD}
                srcDoc={message_body}
                title="Mail Body"
                height="100%"
                width="100%"
                sandbox="allow-same-origin allow-popups allow-top-navigation allow-popups-to-escape-sandbox allow-scripts"
              /> :
              <Loading noLogo className="in-place my-0" message="Loading content..." />
          }
          {
            (!!previous_message) &&
            <div className="previous_mail">
              <Button variant="icon" size="sm" onClick={() => setOpenPrev(!open_prev)} title={open_prev ? "View less message information" :
                "View more information about message"}>
                {open_prev ? <span>Show less...</span> : <span>Show more...</span>}
              </Button>
              <Collapse in={open_prev}>
                <div>
                  {
                    !!previous_message_body ?
                      <InlineIframe
                        iframeHead={IFRAME_HEAD}
                        srcDoc={previous_message_body}
                        title="Mail Body"
                        height="100%"
                        width="100%"
                        sandbox="allow-same-origin allow-popups allow-top-navigation allow-popups-to-escape-sandbox allow-scripts"
                      /> : <Loading noLogo className="in-place my-0" message="Loading content..." />
                  }
                </div>
              </Collapse>
            </div>
          }
        </Card.Body>
        {
          (!!message.attachments && message.attachments.length > 0) &&
          <Card.Footer className="attachments">
            {
              _.map(message.attachments, (attachment: ThreadAttachment, i: number) => {
                return <Attachment key={`attachment_${i}`} attachment={attachment} {...props} />;
              })
            }
          </Card.Footer>
        }
      </div>
    </Collapse>
    <Button variant="icon" onClick={() => handleOpenBody(!open)} title={open ? "View Less" : "View More"}>
      {
        open ? <Icon className="ficon-chevron-up" /> : <Icon className="ficon-chevron-down" />
      }
    </Button>
  </>;
}

export default React.memo(MessageBodyComponent);
