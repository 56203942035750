import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { FormValidationLimits, Country, Countries, LocationService, DefaultCountry, ChangeAuthenticationUIActionTypes, dayjs } from "src/common";
import { SelectCountry } from "src/components";
import { Formik, Field } from "formik";
import * as yup from "yup";


type AllProps = {
  title?: string;
  handleAction: ActionHandlerFunction;
}
const locationService = new LocationService();
function PhoneFormComponent(props: AllProps) {
  const { handleAction, title } = props;
  const [country_number_code, setCountryNumberCode] = useState<string>("1"); // setCountryCode - default to US
  const [phone_mask, setPhoneMask] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>(locationService._phoneTemplate);
  const schema = yup.object().shape({
    phone_number: yup.string()
      .required()
      .min(
        FormValidationLimits.PHONE_MIN_LENGTH,
        `Phone must contain ${FormValidationLimits.PHONE_MIN_LENGTH} or more numbers.`
      )
      .max(
        FormValidationLimits.PHONE_MAX_LENGTH,
        `Phone must contain no more than ${FormValidationLimits.PHONE_MAX_LENGTH} numbers.`
      )
      .test("phoneValidator", "Oops! something is wrong with this phone number.",
        (value?: string) => (!!value && locationService.isPhoneNumberValid(value)))
  });

  // Description: Handle form submit
  function handleSubmit(formValues: { phone_number: string }) {
    const phone_number = locationService.phoneNumberE164format(formValues.phone_number);
    handleAction({
      actionType: ChangeAuthenticationUIActionTypes.SubmitPhone,
      params: { expires: dayjs().add(15, "minutes"), phone_number }
    });
  }

  // Description: Handle change on the select country dropdown
  function handleSelectedCountry(country: Country) {
    locationService.setSelectedCountryByAbb(country.country_abb);
    setCountryNumberCode(country.country_number_code);
    setPlaceholder(locationService.phonePlaceholder);
  }

  // Description: Handle on change of selected country
  function handleOnPhoneChange(e: React.KeyboardEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const _phone_mask = (phone_mask.length < value.length) ? locationService.phoneMaskChange(value) : value;
    setPhoneMask(_phone_mask);
  }

  return <>
    <h3 className="border-bottom">{title || "Enter Your Phone Number"}</h3>
    <p>Please enter the phone number you want to use for verification.</p>
    <p>We will use it to send this number a text message with a verification code every time you log in.</p>
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      initialValues={{ phone_number: "" }} >
      {({ dirty, handleChange, handleSubmit, handleBlur, isValid }) => (
        <Form onSubmit={handleSubmit} className="phone-group">
          <Form.Label htmlFor="phone_control_tb">Mobile Phone Number</Form.Label>
          <Row className="mb-3 g-2 align-items-center">
            <Form.Group as={Col} xs="auto" >
              <SelectCountry options={Countries}
                default_country={DefaultCountry}
                handleAction={handleSelectedCountry} />
            </Form.Group>
            <Col xs="auto" className="phone-code">{country_number_code} -</Col>
            <Form.Group as={Col} sm={6} xs={8} >
              <Field type="phone"
                id="phone_control_tb"
                className="form-control"
                name="phone_number"
                aria-describedby="phoneInput"
                placeholder={placeholder}
                value={phone_mask}
                onBlur={handleBlur}
                onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  handleOnPhoneChange(e);
                  handleChange(e); // Needs to be here for validation
                }}
              />
            </Form.Group>
          </Row>
          <div className="btn-panel">
            <Button type="submit" disabled={!dirty || !isValid}>Submit</Button>
            <Button variant="outline-secondary" type="button" onClick={() => handleAction({ actionType: ChangeAuthenticationUIActionTypes.ResetForms })}>Cancel</Button>
          </div>
        </Form>
      )}
    </Formik>
  </>;
}

export default React.memo(PhoneFormComponent);
