import React, { useEffect, useState } from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { Account, AccountErrorMessages, Helpers, KeyStorageAPI, KeyStorageData, KeystorageResponseErrorCodes, Message, useAppDispatch, useAppSelector } from "src/common";
import { Icon, Loading, PageHeader, SharedCurrentPasswordForm } from "src/components";
import { RootState } from "src/store/configureStore";
import { accountActions, uiActions } from "src/store";
import { PasswordForm } from ".";

/* Description: Component for the user to change their password. */
function PasswordResetComponent() {
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const auth_token_store = useAppSelector((state: RootState) => state.account.auth_token);
  const [auth_token, setAuthToken] = useState<string>();
  const [step, setStep] = useState<number>(4);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!auth_token_store) {
      setAuthToken(auth_token_store);
      setStep(2);
    } else {
      setStep(1);
    }
  }, []);

  /* Description: Calls the rekey keystorage call to change the password.
    NOTE: we do not allow the user to enter one of their previous passwords. (KSS will send us an error in this case) */
  async function createNewPassword(password: string) {
    if (current_account && !!auth_token) {
      setStep(4);
      const token = await KeyStorageData.authenticationToken(current_account.user_id, password);
      const new_auth_token = Helpers.b64Encode(token);
      const account_ids = Account.getAccountIdentifiers(current_account);
      const wrapped_data = await KeyStorageData.wrapKSSData(password, account_ids.user_key, account_ids.device_key, 1, current_account.current_device_id);
      KeyStorageAPI.rekeyUser(account_ids, auth_token, wrapped_data, undefined, Helpers.b64Encode(current_account.user_key.public_user_key.verify_key.public_key), new_auth_token).then((response) => {
        if (!response.isError) {
          setAuthToken(new_auth_token);
          dispatch(accountActions.handleSetAuthToken(new_auth_token));
          setStep(3);
        } else {
          setStep(2);
          if (response.data.error === KeystorageResponseErrorCodes.bad_password) {
            dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_password), response.data)) && setStep(1);
          } else {
            const message = response.data.bad_parameter === AccountErrorMessages.previous_password_existed ? AccountErrorMessages.cannot_user_previous_password : AccountErrorMessages.error_changing_password;
            dispatch(uiActions.handleRequestErrors(new Message(message), response.data));
          }
        }
      }).catch((error) => {
        setStep(2);
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.error_changing_password), error));
      });
    }
  }

  async function submit(old_password: string): Promise<void> {
    if (!!current_account) {
      const old_auth_token = Helpers.b64Encode(await KeyStorageData.authenticationToken(current_account.user_id, old_password));
      setAuthToken(old_auth_token);
    }
    setStep(2);
  }

  // Description: Render the correct form
  function ResetForms() {
    switch (step) {
      case 1:
        return <SharedCurrentPasswordForm submit={submit} />;
      case 2:
        return <PasswordForm createNewPassword={createNewPassword} />;
      case 3:
        return <div>
          <h3>Your password has been succesfully saved.</h3>
          <Alert variant="warning" className="details-alert max-600 ms-0">
            <Alert.Heading><Icon className="ficon-alert-triangle" />For your security, PreVeil does not save your password.</Alert.Heading>
            <p>Save your password in a secure place such as your browser's password manager.</p>
          </Alert>
          <Button variant="outline-primary" onClick={() => setStep(2)}>Close</Button>
        </div>;
      default:
        return <Loading message="Changing Password" />;
    }
  }

  return <>
    <PageHeader>
      <h1>Change Password</h1>
    </PageHeader>
    <Card className="card-section">
      <ResetForms />
    </Card>
  </>;
}

export default React.memo(PasswordResetComponent);
