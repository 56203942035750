import keyMirror from "keymirror";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { action } from "typesafe-actions";
import { AppBuildInformation, MessageItem, DeviceLockInfoBase, RenameCollectionEventPayload } from "@preveil-api";
import { AccountIdentifiers, AccountUserKey } from "src/common";

export interface AppState extends Partial<AppBuildInformation> {
  errors?: MessageItem;
  locked_devices?: DeviceLockInfoBase;
  // This flag is app level to determine if web should fetch directly from S3 unless set here per session
  mail_direct_fetch: boolean;
  new_invitee_signup: boolean; // From Notify Ws
  new_event: number;
  renamed_collections: RenameCollectionEventPayload[];
}

export interface HandleRekeyAndSetApprovalGroupParams {
  account_ids: AccountIdentifiers;
  user_keys: AccountUserKey[];
  password: string;
  auth_token: string;
}

export const AppSagaActionTypes = keyMirror({
  FETCH_PING_REQUEST: null,
  FETCH_BUILD_REQUEST: null,
  FETCH_EVENTS: null
});

const initialState: AppState = {
  // cs_connection: true,
  build_id: null,
  head_commit_hash: null,
  version: null,
  build_mode: undefined,
  errors: undefined,
  os_info: undefined,
  browser: undefined,
  locked_devices: undefined,
  mail_direct_fetch: true,  // default to true to try first direct
  new_invitee_signup: false, // From Notify Ws
  new_event: 0,
  renamed_collections: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getBuildVersionComplete: (state, action: PayloadAction<Partial<AppBuildInformation>>) => {
      return { ...state, ...action.payload };
    },
    appFetchError: (state, action: PayloadAction<MessageItem>) => {
      state.errors = action.payload;
    },
    deviceLockedSet: (state, action: PayloadAction<DeviceLockInfoBase | undefined>) => {
      state.locked_devices = action.payload;
    },
    setMailDirectFetch: (state, action: PayloadAction<boolean>) => {
      state.mail_direct_fetch = action.payload;
    },
    setNewInviteeSignup: (state, action: PayloadAction<boolean>) => {
      state.new_invitee_signup = action.payload;
    },
    setNewEvent: (state) => {
      state.new_event += state.new_event < 2 ? 1 : 0;
    },
    setEventComplete: (state) => {
      state.new_event -= state.new_event > 0 ? 1 : 0;
    },
    addRenamedCollections: (state, action: PayloadAction<RenameCollectionEventPayload[]>) => {
      state.renamed_collections = [...state.renamed_collections, ...action.payload];
    },
    removeRenamedCollection: (state) => {
      state.renamed_collections = [];
    },
    destroyApp: () => {
      return initialState;
    },
  }
});

// ----------------------------------------------------------------------
// Saga specific Actions
// Note: These are actions that are watched by sagas for side effects    
// ----------------------------------------------------------------------
const getBuildVersion = (config_info: Partial<AppBuildInformation>) => action(AppSagaActionTypes.FETCH_BUILD_REQUEST, config_info);
const getPingCryptoStatus = (user_id?: string) => action(AppSagaActionTypes.FETCH_PING_REQUEST, user_id);
const getEvents = (account_ids: AccountIdentifiers, user_keys: AccountUserKey[], password?: string, auth_token?: string) =>
  action(AppSagaActionTypes.FETCH_EVENTS, { account_ids, user_keys, password, auth_token });
const appSagaActions = {
  getBuildVersion,
  getPingCryptoStatus,
  getEvents
};
// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const appActions = { ...appSlice.actions, ...appSagaActions };
export default appSlice.reducer;
