import React, { useState, useEffect, ReactElement, FocusEvent } from "react";
import { Form, Button } from "react-bootstrap";
import { Navigate, Link } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import { RegexHelper, LoginWebUIActionTypes, PublicRoutes, useGetUsersTypeQuery, account_types } from "src/common";
import { Icon } from "src/components";


type AllProps = {
  user_id?: string;
  children?: ReactElement;
  handleAction: ActionHandlerFunction;
  forgot_password?: boolean;
}

// Description: Handle Login submit send to parent component
function LoginEmailFormComponent(props: AllProps) {
  const { user_id, children, handleAction, forgot_password } = props;
  const [skip, setSkip] = useState(true);
  const { data } = useGetUsersTypeQuery({ userId: user_id || "" }, { skip });
  const [_user_id, setUserId] = useState(user_id || "");

  useEffect(() => {
    !!user_id && setSkip(false);
  }, []);

  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    // TODO - to Fix: Not getting currentTarget property from the Event when in testing environment
    // currentTarget returns undefined.
    // https://github.com/testing-library/dom-testing-library/issues/73
    const email = e.currentTarget?.userId?.value || _user_id;
    if (isValid(email)) { // Validate email in form
      // Note: Add to Lower case immediately before saving to server
      handleAction({ actionType: LoginWebUIActionTypes.SubmitEmail, params: email.toLowerCase() });
    } else {
      console.error("HANDLE FORM ERRORS");
    }
  }

  // Description: is form valid
  function isValid(email: string): boolean {
    return RegexHelper.testEmailAddress(email);
  };

  // Description: Handle account types and rendering
  function AccountTypeHandler() {
    const qs = user_id ? encodeURIComponent(user_id) : "";
    const account_type = data?.account_type;
    switch (account_type) {
      case account_types.full:
        return <Navigate to={`/${PublicRoutes.connect_account_route}?user_id=${qs}`} />;
      case account_types.nouser:
        return <Navigate to={`/${PublicRoutes.create_account_route}?user_id=${qs}`} />;
      default:
        return <>
          {children}
          <Form onSubmit={handleSubmit} className="login-email-form ">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email_tb" className="fs-6">PreVeil User ID</Form.Label>
              <div className="input-icon-prepend">
                <Icon className="ficon-user" />
                <Form.Control
                  id="email_tb"
                  type="email"
                  placeholder="Enter User ID" name="userId"
                  value={_user_id}
                  onChange={(e: FocusEvent<HTMLInputElement>) => setUserId(e.currentTarget.value)}
                  autoFocus={true} />
              </div>
            </Form.Group>
            <div className="btn-panel mb-2">
              <Button type="submit" disabled={!_user_id || !isValid(_user_id)} >{!!forgot_password ? "Continue" : "Sign In"}</Button>
              <Link className="btn btn-outline-secondary" to={`/${PublicRoutes.get_started_route}`}>Cancel</Link>
            </div>
            {forgot_password ?
              <small className="fs-6">Remember Your Password?&nbsp;<Link to={`/${PublicRoutes.login_route}`}
                title="Remember Your Password? Back to Login">Back to Login</Link>
              </small> :
              <small className="fs-6">Not a PreVeil Express user?&nbsp;<Link to={`/${PublicRoutes.create_account_route}`}
                title="Not a PreVeil Express user? Create a new account">Create a new
                account</Link>
              </small>}
          </Form >
        </>;
    }
  }
  return AccountTypeHandler();
}

export default React.memo(LoginEmailFormComponent);
