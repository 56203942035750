import React, { useState, FormEvent, FocusEvent } from "react";
import { Form, Col, InputGroup, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { RecoverAccountUIActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
}

function PasswordFormComponent(props: AllProps) {
  const { handleAction } = props;
  const [password, setPassword] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  // Description: Handle form submit
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      handleAction({ actionType: RecoverAccountUIActionTypes.SubmitPassword, params: form.password.value.trim() });
    }
  };

  return <Form onSubmit={handleSubmit}>
    <h5>Please enter the password for your Backup</h5>
    <Form.Group as={Col} md={8} className="mb-4" >
      <Form.Label htmlFor="password_tb" visuallyHidden>Enter the password for your recovery file </Form.Label>
      <InputGroup className="mb-3">
        <Button variant="link" disabled className="btn-icon">
          <Icon className="ficon-lock" />
        </Button>
        <Form.Control
          type={show ? "text" : "password"}
          id="password_tb"
          className="ps-0"
          autoFocus={true}
          aria-describedby="passwordInput"
          placeholder="Enter Password"
          name="password"
          value={password}
          onChange={(e: FocusEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)} />
        <Button variant="link" className="btn-icon" onClick={() => setShow(!show)}>
          <Icon className={show ? " ficon-eye" : "ficon-eye-off "} />
        </Button>
      </InputGroup>
    </Form.Group>
    <div className="btn-panel">
      <Button type="submit" disabled={!password} >Submit</Button>
      <Button variant="outline-secondary" type="button"
        onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.ResetForms })}>Cancel</Button>
    </div>
  </Form>;
}
export default React.memo(PasswordFormComponent);
