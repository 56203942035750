import { FSMessage, Node } from "src/common/keys/protos/collections_pb";
import { COLLECTION_PROTOCOL_VERSIONS, SnapshotResult, isDisjointedACLNode } from "src/common";

export class SearchSnapshot {
    collection_id: string;
    id?: string;
    maintainer_id?: string;
    directory_map: Map<string, FSMessage.Dir>;
    // files_map: Map<string, FSMessage.File.AsObject>;
    links_map: Map<string, FSMessage.Link.AsObject>;
    collection_protocol_version!: COLLECTION_PROTOCOL_VERSIONS;
    root_acl_list!: Node.Grant[];

    constructor(snapshot_result: SnapshotResult) {
        this.collection_id = snapshot_result.collection_id;
        // NOTE: This may be empty string for general snapshot fetch
        this.id = !!snapshot_result.id ? snapshot_result.id : undefined;
        const fs_snapshot = this._getIndexedSnapshot(snapshot_result.snapshot);
        this.directory_map = fs_snapshot.dirs;
        this.links_map = fs_snapshot.links;
    }

    // Description: In a multi-page snapshot, directory children might be split across pages, so we are combining the entries for the directories
    // with the same id so that all entries are together.
    private _getIndexedSnapshot(snapshot: FSMessage.Snapshot) {
        const dirs = new Map<string, FSMessage.Dir>();
        snapshot.getDirsList().forEach((dir: FSMessage.Dir) => {
            const is_root = dir.getIsRoot();
            const id = dir.getId_asB64();
            const maintainer_id = dir.getMaintainerId_asB64();
            const disjointed_acl_node = isDisjointedACLNode(dir);
            if (is_root || disjointed_acl_node) {
                this.maintainer_id = maintainer_id;
                // NOTE: For disjointed ACL Nodes  dir.getId_asB64() === dir.getMaintainerId_asB64() but isRoot is false
                this.collection_protocol_version = !!maintainer_id ? COLLECTION_PROTOCOL_VERSIONS.V2 : COLLECTION_PROTOCOL_VERSIONS.V1;
                this.root_acl_list = dir.getAclList();
            }

            const exisiting_dir = dirs.get(id);
            if (dirs.has(id) && !!exisiting_dir) {
                exisiting_dir.setEntriesList(exisiting_dir.getEntriesList().concat(dir.getEntriesList()));
            } else {
                dirs.set(id, dir);
            }
        });
        const links = new Map<string, FSMessage.Link.AsObject>();
        snapshot.getLinksList().forEach((link: FSMessage.Link) => {
            links.set(link.getId_asB64(), link.toObject());
        });
        return { dirs, links };
    }
}
