import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler } from "@preveil-api";
import {
  Account, buildSelectiveSyncTree, DirectoryEntity, DriveUIActionTypes, useSelectiveSyncMutation, GlobalErrorMessages, Message, MessageAnchors,
  MessageHandlerDisplayType, useAppDispatch, useAppSelector, useFetchDirectory, useFetchLink, SelectiveSyncApiResponse, convertB64toUUID, UUID,
  DriveErrorMessages, SelectiveSyncRulesMap, SELECTIVE_SYNC_STATES, TreeItem
} from "src/common";
import { Tree } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";

type AllProps = {
  current_directory: DirectoryEntity;
  handleAction: ActionHandlerFunction;
  current_account: Account;
};

const bool_sync_status_map = {
  [SELECTIVE_SYNC_STATES.ON]: true,
  [SELECTIVE_SYNC_STATES.InheritedON]: true,
  [SELECTIVE_SYNC_STATES.OFF]: false,
  [SELECTIVE_SYNC_STATES.InheritedOFF]: false,
  [SELECTIVE_SYNC_STATES.Pending]: false,
  [SELECTIVE_SYNC_STATES.PARTIAL]: false,
};

function SelectiveSyncComponent(props: AllProps) {
  const { current_directory, handleAction, current_account } = props;
  const [fetching_children, setFetchingChildren] = useState<boolean>(false);
  const [linked_collection_id, setLinkedCollectionID] = useState<string>();
  const [tree_item, setTreeItem] = useState<TreeItem>();
  const [tree, setTree] = useState<TreeItem[]>();
  const [directory_entity, setDirectoryEntity] = useState<DirectoryEntity>();
  const [selected_folders_dict, setSelectiveSyncRulesMap] = useState<SelectiveSyncRulesMap>({});
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const selected = useAppSelector((state: RootState) => state.drive.selected);
  const { directory, fetchDirectory, resetDirectory, error: dir_error } = useFetchDirectory(current_account, false, true);
  const { fetchLink, directory_id, resetLink, directory_entity: acl_directory_entity } = useFetchLink(current_account, false, true);
  const [selective_sync] = useSelectiveSyncMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const _tree = buildSelectiveSyncTree(current_directory, selected);
    !!_tree && setTree(_tree);
  }, []);

  useEffect(() => {
    if (!!linked_collection_id && !!directory_id) {
      fetchDirectory(linked_collection_id, directory_id);
    }
    resetLink();
  }, [directory_id]);

  useEffect(() => {
    setDirectoryEntity(directory);
  }, [directory]);

  useEffect(() => {
    setDirectoryEntity(acl_directory_entity);
  }, [acl_directory_entity]);

  // Build tree structure on fetch directory success or error
  useEffect(() => {
    if (!!directory_entity) {
      if (!!tree_item) {
        const children = buildSelectiveSyncTree(directory_entity, selected, tree_item.path, tree_item);
        if (!!children && children.length > 0 && !!tree_item.collection_id && !!tree_item.id && tree_item.should_sync !== undefined) {
          // Add the parent folder to the dict
          if (!!tree_item.linked_collection_id) {
            updateFolderSyncState(tree_item.linked_collection_id, tree_item.linked_collection_id, tree_item.should_sync);
          } else {
            updateFolderSyncState(tree_item.collection_id, tree_item.id, tree_item.should_sync);
          }

          tree_item.children = children;
          tree_item.children?.forEach(child => {
            // Handle if a parent folder is checked or unchecked before fetching the children
            if ((tree_item.should_sync !== tree_item.current_sync_status)) {
              child.should_sync = tree_item.should_sync;
            }

            // Add all children to the dict
            if (!!child.collection_id && !!child.id && child.should_sync !== undefined) {
              updateFolderSyncState(child.linked_collection_id || child.collection_id, child.id, child.should_sync);
            }
          });
        }
        tree_item.children_loading = false;
      }
      setFetchingChildren(false);
      resetDirectory();
      resetLink();
    }
  }, [directory_entity, dir_error]);

  // Description: Update the sync dictionary as folders are selected and deselected from the tree
  function updateFolderSyncState(collection_id_b64: string, node_id_b64: string, new_sync_status: SELECTIVE_SYNC_STATES) {
    setSelectiveSyncRulesMap(prev_folders => {
      const collection_id = convertB64toUUID(collection_id_b64);
      const node_id = convertB64toUUID(node_id_b64);
      const existing_folders = prev_folders[collection_id] || [];
      const folder_index = existing_folders.findIndex(f => f.node_id === node_id);

      // If new sync status is different from the current, add or update the folder
      const updated_folder = { node_id, sync: bool_sync_status_map[new_sync_status] };
      const updated_folders = folder_index >= 0
        ? existing_folders.map((folder, index) => index === folder_index ? updated_folder : folder)
        : [...existing_folders, updated_folder];

      return { ...prev_folders, [collection_id]: updated_folders };
    });
  };

  // Description: Handle states of modal
  function handleHideModal() {
    handleAction({ actionType: DriveUIActionTypes.SelectiveSyncModal, params: { show: false } });
  }

  // Description: Handle save of the modal state
  async function handleOnSubmit() {
    // Create a request_id to track the progress of the sync job
    const request_id = new UUID().String();
    await selective_sync({
      uid: current_account.user_id,
      syncRulesMap: selected_folders_dict,
      request_id,
    })
      .unwrap()
      .then((_: SelectiveSyncApiResponse) => {
        handleHideModal();
      })
      .catch(() => {
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_selective_sync)));
        setTimeout(() => {
          dispatch(uiActions.setShowSyncStatus(false));
        }, 3500);
      });

    handleAction({ actionType: DriveUIActionTypes.SelectiveSyncNotification, params: { request_id } });
  }

  const SelectiveSyncRequests = {
    // Fetch the children children nodes through "fetch directory" when a folder is clicked
    handleFetchTreeNodeChildren: (item: TreeItem) => {
      if (!!item.id && item.collection_id) {
        setTreeItem(item);
        item.children_loading = true;
        setFetchingChildren(true);
        // Check if its a shared directory
        if (!item.linked_collection_id) {
          fetchDirectory(item.collection_id, item.id);
        } else {
          setLinkedCollectionID(item.linked_collection_id);
          fetchLink(item.collection_id, item.id);
        }

      }
    },
    handlePageErrorMessage: (params: { message: string; stack?: any }) => {
      dispatch(
        uiActions.handleRequestErrors(
          new Message(params.message, MessageHandlerDisplayType.logger),
          params.stack
        ),
      );
    },
    handleSyncCheckboxChange(params: { folder: TreeItem, sync: boolean }) {
      // Recursive function to update synced state
      const updateSyncedState = (folder: TreeItem, sync: boolean) => {
        folder.should_sync = sync ? SELECTIVE_SYNC_STATES.ON : SELECTIVE_SYNC_STATES.OFF;
        if (!!folder.id && !!folder.collection_id) {
          if (sync) {
            if (!!folder.linked_collection_id) {
              updateFolderSyncState(folder.linked_collection_id, folder.linked_collection_id, SELECTIVE_SYNC_STATES.ON);
            } else {
              updateFolderSyncState(folder.collection_id, folder.id, SELECTIVE_SYNC_STATES.ON);
            }
          } else {
            if (!!folder.linked_collection_id) {
              updateFolderSyncState(folder.linked_collection_id, folder.linked_collection_id, SELECTIVE_SYNC_STATES.OFF);
            } else {
              updateFolderSyncState(folder.collection_id, folder.id, SELECTIVE_SYNC_STATES.OFF);
            }
          }
        }

        // Update children to match parent state
        folder.children?.forEach(child => {
          updateSyncedState(child, sync);
        });

        setTreeItem({ ...folder });
      };
      updateSyncedState(params.folder, params.sync);
    },
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((SelectiveSyncRequests as any)[callback] instanceof Function) {
      (SelectiveSyncRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      SelectiveSyncRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <Modal
    size="lg"
    dialogClassName="sync-modal"
    show={true}
    onHide={() => handleHideModal()}
    aria-labelledby="Selective Sync Modal">
    <Modal.Header className="align-items-start" closeButton>
      <div>
        <Modal.Title>Sync your PreVeil Folders</Modal.Title>
        <p className="mb-0">Only <b>checked</b> folders will be synced when you save</p>
      </div>
    </Modal.Header>
    <Modal.Body>
      {
        (!!root_info && current_directory.id === root_info.id && !!tree && tree.length === 0) &&
        <p>No eligible subfolders in this folder</p>
      }
      {
        <div className="move-folders ps-0">
          {
            !!tree &&
            <Tree
              readonly
              className="move-tree"
              data={tree}
              handleAction={handlePageActions}
              fetching_children={fetching_children}
              drive={true}
              selective_sync={true} />
          }
        </div>
      }
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button onClick={() => handleOnSubmit()}>
        Save
      </Button>
      <Button variant="no-outline-primary" onClick={handleHideModal}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>;
}

export default React.memo(SelectiveSyncComponent);
