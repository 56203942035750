import React, { useEffect, FocusEvent, useState, Dispatch, SetStateAction, DragEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, PaginationItem } from "@preveil-api";
import {
  Account, DriveUIActionTypes, CheckboxStates, CheckboxStatesTypes, DirectoryEntity, Pagination, EntryItem, isNumber,
  useAppDispatch, DriveLimits, useGetCollectionInfo, PanelState, PanelStateType, ShareDataBase, DriveEntryType, mapEntryType,
  SORT_DIRECTION, getOppositeSortDirection, sortBy, SortFieldType, useAppSelector, DragEventType, useDriveProcessNotification,
  DriveListViewEventWatch, COLLECTION_PROTOCOL_VERSIONS, buildNavigationLink, Message, MessageHandlerDisplayType, MessageToastTypes,
  useCopyToClipboard, PreveilDownloads, DriveConstants, convertB64toUUID, DriveErrorMessages, UUID, useSelectiveSyncMutation, Directory, PathInfo,
  SelectiveSyncRulesMap, SELECTIVE_SYNC_STATES, AppConfiguration
} from "src/common";
import { Checkbox, Icon, Loading } from "src/components";
import { CreateDirectory, ListRow, Move } from "../entries";
import { EmptyMessage, DriveSidePanel } from "..";
import { createRoot } from "react-dom/client";
import { RootState } from "src/store/configureStore";
import { uiActions, driveActions } from "src/store";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  is_web: boolean;
  page?: string;
  pagination: PaginationItem;
  is_loading: boolean;
  selected: string[];
  current_directory: DirectoryEntity;
  creating_new_folder: boolean;
  drag_event?: { type: string, e: DragEvent };
  handleAction: ActionHandlerFunction;
  setCreatingNewFolder: Dispatch<SetStateAction<boolean>>;
};

function ListViewComponent(props: AllProps) {
  const {
    current_account, is_web, is_loading, current_directory, pagination, selected, page, handleAction, creating_new_folder, setCreatingNewFolder, drag_event
  } = props;
  const navigate = useNavigate();
  const [page_index, setPageIndex] = useState<number>(Pagination.getPageIndex(page));
  const [current_entry, setCurrentEntry] = useState<EntryItem | undefined>();
  const [current_action, setCurrentAction] = useState<string>();
  const [panel_state, setPanelState] = useState<PanelStateType>(PanelState.off);
  const [entries, setEntries] = useState<EntryItem[]>([]);
  const [dragging_items, setDraggingItems] = useState<string[]>([]);
  const [move_info, setMoveInfo] = useState<{ items: string[], destination: EntryItem }>();
  const sort = useAppSelector((state: RootState) => state.drive.sort);
  const { copy } = useCopyToClipboard();
  const { collection_info, collection_info_error, getCollectionInfo } = useGetCollectionInfo(current_account, current_entry);
  const [notification_collection_id, setNotificationCollectionId] = useState<string | undefined>();
  const { new_notification } = useDriveProcessNotification(notification_collection_id, DriveListViewEventWatch);
  const breadcrumbs = useAppSelector((state: RootState) => state.drive.breadcrumbs);
  const under_downloads = !!breadcrumbs ? breadcrumbs.find((breadcrumb: PathInfo) => breadcrumb.path === PreveilDownloads) : undefined;
  const active_share_id = useAppSelector((state: RootState) => state.drive.active_share_id);
  const current_collection = useAppSelector((state: RootState) => state.drive.current_collection);
  const current_directory_name = useAppSelector((state: RootState) => state.drive.current_directory_name);
  const link_entity = useAppSelector((state: RootState) => state.drive.current_link);
  const [selective_sync] = useSelectiveSyncMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(driveActions.resetSort());
    };
  }, []);

  useEffect(() => {
    !!current_entry &&
      setNotificationCollectionId(!!current_entry.linked_collection_id ? current_entry.linked_collection_id : current_entry.collection_id);
  }, [current_entry]);


  useEffect(() => {
    !!creating_new_folder && setPanelState(PanelState.off);
  }, [creating_new_folder]);

  useEffect(() => {
    handleEntriesPagination();
  }, [page, current_directory]);

  // Description: Handle updates to specific current_entry after refresh
  useEffect(() => {
    if (!!entries && entries.length > 0) {
      if (!!current_entry) {
        const _current_entry = _.find(entries, (entry: EntryItem) => entry.id === current_entry.id && entry.collection_id === current_entry.collection_id);
        !!_current_entry && setCurrentEntry(_current_entry);
      } else if (!!active_share_id) {
        handleActiveShareId(active_share_id);
      }
    }
  }, [entries]);

  // Description: Handle Toolbar to Details Sidepanel
  useEffect(() => {
    if (!!active_share_id && !!current_collection) {
      const _current_entry = Directory.parseDirectorytoEntry(current_directory, current_directory_name || current_collection.collection_name, current_collection, link_entity);
      ListViewRequests.handleDetails({ entry: _current_entry, action: DriveUIActionTypes.Details });
      // NOTE: Reset store flag
      dispatch(driveActions.setActiveShareId(undefined));
    }
  }, [active_share_id]);

  useEffect(() => {
    if (!!drag_event) {
      switch (drag_event.type) {
        case DragEventType.DRAG_OVER:
          handlePageActions({ actionType: DriveUIActionTypes.DragOver, params: { e: drag_event.e, outer_scope: true } });
          break;
        case DragEventType.DRAG_LEAVE:
          handlePageActions({ actionType: DriveUIActionTypes.OnDragLeave, params: { e: drag_event.e, outer_scope: true } });
          break;
        case DragEventType.DROP:
          handlePageActions({ actionType: DriveUIActionTypes.OnDrop, params: { e: drag_event.e, outer_scope: true } });
      }
    }
  }, [drag_event]);

  // Description: Update notifications
  useEffect(() => {
    (!!new_notification && !!current_entry) && getCollectionInfo(current_entry);
  }, [new_notification]);

  // Description: Get the selected all checkbox state
  function getSelectedState(): CheckboxStatesTypes {
    let ck_state: CheckboxStatesTypes = CheckboxStates.empty;
    if (!!pagination) {
      ck_state = (!selected.length ? CheckboxStates.empty :
        (selected.length < DriveLimits.DRIVE_PAGINATION_PAGE_SIZE && selected.length < pagination.totalRows) ?
          CheckboxStates.indeterminate : CheckboxStates.checked);
    }
    return ck_state;
  }

  // Description: Handles specific threads to display in search (i.e based on pagination and filters)
  function handleEntriesPagination(reset: boolean = false, sorted_entries?: EntryItem[]) {
    let _entries = !!sorted_entries ? sorted_entries : sortBy(current_directory?.entries, sort);
    if (!!_entries && _entries.length > 0) {
      const chunks = _.chunk(_entries, pagination.pageSize);
      const new_page_index = !!page && isNumber(page) ? (Number(page) - 1) : pagination.pageIndex;
      _entries = chunks[new_page_index];
      // TODO: For next set of pages need to examine !_entries and then call fetch directory again
      (!!_entries && (page_index !== new_page_index || reset)) &&
        handlePaginationUpdate(new_page_index, _entries.length, pagination.totalRows || _entries.length);
    }
    setEntries(_entries);
  }

  // Description: Update store pagination item
  function handlePaginationUpdate(new_page_index: number, totalPageRows: number, totalRows: number) {
    const paginationItem = new Pagination(new_page_index, totalPageRows, totalRows, pagination.pageSize).pagination_item;
    !!paginationItem && dispatch(driveActions.setPagination(paginationItem));
    setPageIndex(new_page_index);
  }

  // Description: On Finder or File explorer click on Share ... action this function opens the side Panel and displays the share section
  function handleActiveShareId(_active_share_id: string) {
    const _current_entry = _.find(entries, (entry: EntryItem) => entry.id === _active_share_id);
    (!!_current_entry) ?
      ListViewRequests.handleDetails({ entry: _current_entry, action: DriveUIActionTypes.Details }) :
      dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_finding_share_target)));
    // NOTE: Reset store flag
    dispatch(driveActions.setActiveShareId(undefined));
  }

  // -------------------------------------------------------------------------------
  // Description: Handle partial children component actions or pass to Manager
  // Handles mostly single actions from details view
  // -------------------------------------------------------------------------------
  const ListViewRequests = { // ListViewRequests.handleDetails
    // Description: clean up UI after sharing
    handleSharedComplete(params: ShareDataBase) {
      // NOTE: No need to refresh list or Collection Info as Notifications will take care of it
      // NOTE: Need to update setCurrentEntry with db data 
      const localSyncStatus = current_entry?.localSyncStatus || (is_web ? SELECTIVE_SYNC_STATES.OFF : SELECTIVE_SYNC_STATES.ON);
      const entry_info = mapEntryType(params.type, undefined, localSyncStatus);
      const new_entry = (!!current_entry && params.collection_id === current_entry?.linked_collection_id) ? current_entry :
        Object.assign({}, current_entry, {
          linked_collection_id: params.type !== DriveEntryType.DIR ? params.collection_id : "", // For ACL Nodes
          collection_id: current_entry?.collection_id || params.collection_id, // This is not accurate as it is not the Link collection_id
          id: params.id,  // This is not accurate as it is not the Link node id
          type: params.type,
          name: current_entry?.name || collection_info?.collection_name || "",
          type_label: entry_info?.type_label,
          mapped_type: entry_info?.mapped_type,
          type_class: entry_info?.type_class,
          localSyncStatus
        });
      // NOTE: Manually update shares from V1 collections as notifications are not included in event_watch
      // REMOVE WHEN https://preveil.atlassian.net/browse/BACK-1025 comes back
      ((!params.is_promote && collection_info?.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1) ||
        // NOTE: This condition refreshes the collection info when only unclaimed users are set
        (_.isEqual(new_entry, current_entry) && params.grantees.length <= 0))
        && getCollectionInfo(new_entry);
      setCurrentEntry(new_entry);

      // NOTE: If this is promoting a current_directory needs to redirect to the link
      if (params.is_promote) {
        (current_entry?.id === current_directory.id && params.is_promote) ?
          navigate(buildNavigationLink(new_entry.collection_id, new_entry.id, new_entry.type)) : handleAction({ actionType: DriveUIActionTypes.Refresh });
      }
    },

    handleGetCollectionInfo() {
      !!current_entry && getCollectionInfo(current_entry);
    },

    // Description: Toggle side panel used for close and reset, but can be used for open
    handleToggleSidePanel(_panel_state: PanelStateType) {
      setPanelState(_panel_state);
      if (_panel_state === PanelState.off) { // Reset states
        setTimeout(() => {
          setCurrentAction(undefined);
          setCurrentEntry(undefined);
        }, 500);
      }
    },

    // Description: Reset Action on close of section collapsables
    handleResetAction() {
      setCurrentAction(undefined);
    },

    // Description: Open or Close the Sidebar by passing an entry or undefined for reseting 
    handleDetails: (params?: { entry: EntryItem, action: string }) => {
      const entry = params?.entry;
      const action = params?.action;
      setCurrentEntry(entry);
      if (action !== DriveUIActionTypes.ToggleSidePanel) {
        setCurrentAction(action);
        panel_state === PanelState.off && setPanelState(PanelState.on);
      }
    },
    // Description: Syncing on or off 
    handleSync: (params: { entry: EntryItem }) => {
      const entry = params.entry;
      const collection_id = entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.collection_id;
      const node_id = entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.id;
      const sync_state = entry.localSyncStatus === SELECTIVE_SYNC_STATES.ON || entry.localSyncStatus === SELECTIVE_SYNC_STATES.InheritedON;
      syncItem(collection_id, node_id, !sync_state);
    },
    // Description: Copy Link
    handleCopyLink: (entry: EntryItem) => {
      if (!!collection_info && !!current_directory) {
        // NOTE: For disjointed ACL Nodes (recipient side) use legacy format drive/collection_uuid/list/dl
        const disjointed_acl_node = collection_info.disjointed_acl_node;
        const path = entry.type === DriveEntryType.FILE ?
          buildNavigationLink(entry.collection_id, current_directory.id, entry.type, entry.id) :
          entry.type === DriveEntryType.LINK ?
            buildNavigationLink(collection_info.collection_id,
              collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 ? collection_info.root_id :
                !disjointed_acl_node ? collection_info.maintainer_id : DriveConstants.DIRECT_LINK_MASK_ID,
              disjointed_acl_node ? DriveEntryType.ACL_NODE : DriveEntryType.DIR) :
            buildNavigationLink(entry.collection_id, entry.id, entry.type);
        const full_url = AppConfiguration.getDriveUniversalLink(path);
        copy(`${full_url}`);
        dispatch(uiActions.handleSetMessage(new Message("Copied", MessageHandlerDisplayType.toastr, MessageToastTypes.light)));
      } else {
        dispatch(uiActions.handleSetMessage(new Message("Unable to copy the link to this shared Folder",
          MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
      }
    },
    // Description: Handler for when the user drops the items in a row.
    handleOnDrop: (params: { e: DragEvent, outer_scope?: boolean }) => {
      const { e, outer_scope } = params;
      const drop_target = !!outer_scope ? document.getElementById("outer-scope") : e.currentTarget as HTMLElement;
      if (!drop_target) return;
      const has_files = e.dataTransfer.files.length > 0;
      const type = drop_target.id.split("~")[1];
      if (has_files && (drop_target.id === "outer-scope" || type === DriveEntryType.FILE) && drop_target.classList.contains("drag-over-list")) {
        handleAction({ actionType: DriveUIActionTypes.Upload, params: { destination_id: current_directory.id, files: e.dataTransfer.files } });
      } else if (drop_target.id.includes("entity-item~") && drop_target.classList.contains("drag-over")) {
        const drop_entry_id = drop_target.id.split("~")[2];
        const destination = current_directory.entries.find(entry => entry.id === drop_entry_id);
        if (has_files) {
          !!destination && handleAction({ actionType: DriveUIActionTypes.Upload, params: { destination, files: e.dataTransfer.files } });
        } else {
          !!destination && !dragging_items.includes(drop_entry_id) && setMoveInfo({ items: dragging_items, destination });
        }
      }
      drop_target.classList.remove("drag-over", "drag-over-list");
      setDraggingItems([]);
    },
    // Description: When the user drags over an item it will add the "drag-over" class (only if the row is a normal directory)
    handleDragOver: (params: { e: DragEvent, outer_scope?: boolean }) => {
      const { e, outer_scope } = params;
      e.preventDefault();
      e.stopPropagation();
      const drop_target = !!outer_scope ? document.getElementById("outer-scope") : e.currentTarget as HTMLElement;
      if (!drop_target || !!drop_target.classList.contains("drag-over") || !!drop_target.classList.contains("drag-over-list")) return;
      if (drop_target.id === "outer-scope") {
        drop_target.classList.add("drag-over-list");
      } else if (drop_target.id.includes("entity-item~") && drop_target.classList.contains("row")) {
        const type = drop_target.id.split("~")[1];
        const has_files = e.dataTransfer.items.length > 0 || _.includes(e.dataTransfer.types, "Files");
        if ((has_files && type !== DriveEntryType.FILE) || type === DriveEntryType.DIR) {
          !dragging_items.includes(drop_target.id.split("~")[2]) && drop_target.classList.add("drag-over");
        } else if (has_files) {
          const outer_target = document.getElementById("outer-scope");
          if (!outer_target || !!outer_target.classList.contains("drag-over-list")) return;
          outer_target.classList.add("drag-over-list");
        }
      }
    },
    // Description: this is the handler for when the user has dragged over and past a row so that we remove the class "drag-over" so
    // they know which row theyre focusing on/planning to drop in
    handleOnDragLeave: (params: { e: DragEvent, outer_scope?: boolean }) => {
      const { e, outer_scope } = params;
      e.preventDefault();
      e.stopPropagation();
      const drop_target = !!outer_scope ? document.getElementById("outer-scope") : e.currentTarget as HTMLElement;
      if (!drop_target) return;
      if (outer_scope) {
        drop_target.classList.remove("drag-over-list");
      } else {
        const type = drop_target.id.split("~")[1];
        if (type === DriveEntryType.FILE) {
          document.getElementById("outer-scope")?.classList.remove("drag-over-list");
        } else {
          drop_target.classList.remove("drag-over");
        }
      }
    },
    // Description: If the user drops it outside of the component
    handleOnDragEnd: () => {
      setDraggingItems([]);
    },
    // Description: Handler for when the user starts dragging an item or items.
    handleOnDrag: (params: { e: DragEvent, entry: EntryItem }) => {
      const { e, entry } = params;
      setMoveInfo(undefined);
      const items = selected.includes(entry.id) ? selected : [entry.id]; // if this specific entry is selected it will move all the selected items
      setDraggingItems(items);
      const image = (
        <div id="drag">
          <Icon className={"pv-icon-move"} />
          <div>{`Move ${items.length} item${items.length > 1 ? "s" : ""}`}</div>
        </div>
      );
      const ghost = document.createElement("div");
      ghost.style.transform = "translate(-10000px, -10000px)";
      ghost.style.position = "absolute";
      document.body.appendChild(ghost);
      e.dataTransfer.setDragImage(ghost, 0, 0); // sets a custom drag image like how it looks in web V1
      const root = createRoot(ghost);
      root.render(image);
    },
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((ListViewRequests as any)[callback] instanceof Function) {
      (ListViewRequests as any)[callback](actionObj.params);
    } else {
      // NOTE: If not found here propagate to parent
      handleAction(actionObj);
    }
  }

  function syncItem(collection_id: string, node_id: string, sync_state: boolean) {
    const params: SelectiveSyncRulesMap = {
      [convertB64toUUID(collection_id)]: [
        {
          node_id: convertB64toUUID(node_id),
          sync: sync_state
        }
      ]
    };

    const request_id = new UUID().String();
    selective_sync({
      uid: current_account.user_id,
      syncRulesMap: params,
      request_id,
    })
      .unwrap()
      .catch(() => {
        dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_selective_sync)));
        setTimeout(() => {
          dispatch(uiActions.setShowSyncStatus(false));
        }, 3500);
      });

    handleAction({ actionType: DriveUIActionTypes.SelectiveSyncNotification, params: { request_id } });
  }

  // Description: Render the rows
  function RenderList(_entries: EntryItem[]) {
    return <div className="list-body">
      {creating_new_folder && <CreateDirectory setCreatingNewFolder={setCreatingNewFolder} current_account={current_account} />}
      {
        _.map(_entries, (entry: EntryItem, i: number) => {
          return (
            <ListRow
              key={`item_${i}`}
              entry={entry}
              collection_info={collection_info}
              is_loading={is_loading}
              current_directory={current_directory}
              selected={selected.includes(entry.id)}
              dragging_items={dragging_items}
              handleAction={handlePageActions}
              under_downloads={!!under_downloads}
            />
          );
        })
      }
    </div>;
  }

  // Descriptions: Shareable react fragment - for each field - returns the header with sort icon
  function headerWithSortIcon(name: string, field: string) {
    return (
      <>
        {name}
        {!!sort && sort.field === field && entries.length > 1 && (
          <Icon className={`${sort.direction === SORT_DIRECTION.descending ? "ficon-arrow-down" : "ficon-arrow-up"} small-icon ms-1`} />
        )}
      </>
    );
  }

  // Description: Sets the sort field with the new field. (if its the same field, it flips the direction)
  // called handleEntriesPagination to update entries with the sorted list.
  function setSort(field: keyof typeof SortFieldType) {
    const direction = sort.field === field ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
    dispatch(driveActions.setSort({ field, direction }));
    const sorted_entries = sortBy(current_directory?.entries, { field, direction }, field === SortFieldType.lastModificationDate);
    handleEntriesPagination(false, sorted_entries);
  }

  // Description: Render Header component
  function RenderHeader() {
    return <Row className="header-row">
      <Col md="1">
        <Checkbox className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => {
            handleAction({ actionType: DriveUIActionTypes.Select, params: { selected: e.target.checked } });
          }}
          value="all" label="Select All" selected={getSelectedState()} />
      </Col>
      <Col md="5" className="col" onClick={() => setSort(SortFieldType.name)}>
        {headerWithSortIcon("Name", SortFieldType.name)}
      </Col>
      <Col md="2" onClick={() => setSort(SortFieldType.type_label)}>
        {headerWithSortIcon("Type", SortFieldType.type_label)}
      </Col>
      <Col md="3" onClick={() => setSort(SortFieldType.lastModificationDate)}>
        {headerWithSortIcon("Modified", SortFieldType.lastModificationDate)}
      </Col>
      <Col md="1">
        <span hidden>actions</span>
      </Col>
    </Row>;
  }

  return <>
    {!!move_info && <Move
      count={selected.length}
      current_directory={current_directory}
      handleAction={handlePageActions}
      current_account={current_account}
      move_info={move_info}
    />}
    <div className={`drive-list mypreveil${is_loading ? " isloading" : ""}`}>
      {
        (!!entries && entries.length > 0) || creating_new_folder ?
          <>
            {RenderHeader()}
            {RenderList(entries)}
          </> : <EmptyMessage />
      }
      {
        is_loading && <Loading />
      }
    </div>
    {
      !creating_new_folder &&
      <DriveSidePanel
        panel_state={panel_state}
        current_account={current_account}
        entry={current_entry}
        collection_info_error={collection_info_error}
        collection_info={collection_info}
        current_directory={current_directory}
        action={current_action}
        handleAction={handlePageActions}
        under_downloads={!!under_downloads} />
    }
  </>;
}

export default React.memo(ListViewComponent);
