import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { MessageItem, ErrorMessageItem, DeviceLockInfoBase, CryptoServerError, CollectionServerError } from "@preveil-api";
import { MessageHandler } from "src/components";
import {
    MessageHandlerDisplayType, MessageToastTypes, StatusCode, isDeviceLockInfoBase, useAppDispatch, PublicRoutes, QueryParamKeys, useAppSelector,
    isKeyRotationError, isSameUser, normalizeQueryUserId, BaseAPI, AppConfiguration, CollectionServerStatusCode,
    AccountErrorMessages
} from "src/common";
import { RootState } from "src/store/configureStore";
import { appActions, uiActions } from "src/store";

interface AllProps {
    error: ErrorMessageItem;
    onErrorHandlerDismiss: () => void
}

const page_error_codes = [StatusCode.DEVICE_LOCKED_CODE, StatusCode.EXPIRED_KEY_VERSION, StatusCode.OUTDATED_API_VERSION];
const enable_app_logger = AppConfiguration.buildForApp() && !AppConfiguration.isDevelopmentRunMode();
function ErrorHandlerComponent(props: AllProps) {
    const { error, onErrorHandlerDismiss } = props;
    const [searchParams] = useSearchParams();
    const current_account = useAppSelector((state: RootState) => state.account.current_account);
    const active_status_code = useAppSelector((state: RootState) => state.ui.active_status_code);
    const [message_item, setMessageItem] = useState<MessageItem | undefined>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentLocation = useLocation();

    useEffect(() => {
        handleInitErrorHandler();
    }, [error]);

    function handleInitErrorHandler() {
        // Handle User messaging
        if (error.message) {
            // Silence Error message if it is a redirect error code
            const displayType = !!error.stack && !!error.stack.status && page_error_codes.includes(error.stack.status) ? MessageHandlerDisplayType.logger :
                error.displayType || MessageHandlerDisplayType.toastr;
            setMessageItem({
                message: error.message,
                type: error.type || MessageToastTypes.error,
                displayType
            });
        }
        // Note: Message Handler does not deal with stack so log the stack here
        !!error.stack && HandleStackInformation(error.stack);
    }

    // Description: Handle Logging and Stack information 
    function HandleStackInformation(stack: any) {
        // Handle Error logging for all error types
        LogErrorStack(stack);
        const data = stack.data; // const text = stack.statusText;
        const status = stack.status;
        if (!!status) {
            switch (status) {
                case StatusCode.UNAUTHORIZED_ERROR_CODE:
                case CollectionServerStatusCode.EXPIRED_DEVICE_KEY:
                case CollectionServerStatusCode.UNAUTHORIZED:
                    // NOTE: Passing redirect url to return on the current functionality if it self logins (i.e key rotation)
                    // need to check that there is cause and that the cause has: 
                    const path = currentLocation.pathname;
                    const error = parseServerResponse(data);
                    const mail_key_rotation_error = !!error?.cause && isKeyRotationError(error.cause) && isSameUser(current_account?.user_id, error.cause?.user_id);
                    // NOTE: search the message for device key version discrepancy - Collection message: device key version X, got Y
                    const cs_key_rotation_error = (!!stack?.message && stack.message.includes("expected device key version")) ||
                        status === CollectionServerStatusCode.EXPIRED_DEVICE_KEY;

                    // Set Store active_status_code so that its handled properly outside the error handler
                    if (!active_status_code && (mail_key_rotation_error || cs_key_rotation_error)) {
                        dispatch(uiActions.handleSetActiveStatusCode("EXPIRED_USER_KEY"));
                        navigate(`/${PublicRoutes.login_route}?${QueryParamKeys.REDIRECT_URL_QUERY_KEY}=${path}`);
                    }
                    break;
                case StatusCode.FORBIDDEN_DOMAIN:
                    if (!!stack.data.errors && Array.isArray(stack.data.errors) && stack.data.errors.length > 0 &&
                        stack.data.errors[0].cause === AccountErrorMessages.certificate_not_found) {
                        navigate(`/${PublicRoutes.certificate_not_found_route}`);
                    }
                    break;
                // Decription: Parse Lock information returned in error response
                case StatusCode.DEVICE_LOCKED_CODE:
                case CollectionServerStatusCode.LOCKED_DEVICE:
                    const _error = parseServerResponse(data);
                    const lock_info = !!_error && !!_error.cause && isDeviceLockInfoBase(_error.cause) ? (_error.cause as DeviceLockInfoBase) : null;
                    if (!!lock_info) {
                        const qs = normalizeQueryUserId(searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY)) || null;
                        dispatch(appActions.deviceLockedSet(Object.assign({}, lock_info, { display_name: current_account?.display_name || qs })));
                    };
                    navigate(`/${PublicRoutes.device_locked_route}`);
                    break;
                case StatusCode.EXPIRED_KEY_VERSION:
                    navigate(`/${PublicRoutes.expired_key_route}`);
                    break;
                case StatusCode.OUTDATED_API_VERSION:
                    navigate(`/${PublicRoutes.update_required_route}`);
                    break;
            }
        }
    }

    // Description: Stub to send the stack to a file that will be downloaded with all the other PV logs.
    // Issue: https://preveil.atlassian.net/browse/NEW-141
    async function LogErrorStack(stack: unknown) {
        console.error("%cPreveil Logger Error Stack: ", "color:red;font-size: 16px; font-weight: bold;", stack);
        // Log errors to the server if not in dev mode and not in web build
        if (enable_app_logger) {
            const stack_message = typeof stack === "object" ? JSON.stringify(stack) : stack;
            const data = {
                level: "error",
                message: stack_message
            };
            await BaseAPI.put(`${process.env.REACT_APP_PORT}/post/log`, data);
        }
    }

    // Decription: Parse Collection error coming back from CS or Crypto
    // NOTE: Errors from crypto will come back in a Json stringify string that needs to be parsed
    //  const cause = Array.isArray(data.errors) && typeof data.errors[0].cause !== "string" ? data.errors[0].cause :
    //       typeof data.errors[0].cause === "string" ? ParseCryptoErrors(data) : null;
    //               return !!cause && isDeviceLockInfoBase(cause) ? (cause as DeviceLockInfoBase) : null;
    function parseServerResponse(data: any): CollectionServerError | null {
        try {
            // Some instances it returns {status: string, msg: string}
            if (!!data.msg && typeof data.msg === "string") {
                return {
                    status: data.status || "error",
                    title: data.msg,
                    cause: data.msg
                };
            } else if (!!data.errors) {
                const cause = Array.isArray(data.errors) && typeof data.errors[0].cause !== "string" ? data.errors[0] :
                    typeof data.errors[0].cause === "string" ? ParseCryptoErrors(data) : null;
                return !!cause ? cause : null;
            } else {
                return null;
            }
        }
        catch (m) {
            console.error("Error trying to parse server error");
            return null;
        }
    }

    // Description: Parse the Crypto Returned error returned as a JSON Stringified object into an object
    // data: {errors: { source: any, cause: string }[]}
    // returns a CollectionServerError plus any props brought up from crypto errors
    function ParseCryptoErrors(data: CryptoServerError): any {
        let cause = null;
        const data_errors = data.errors[0];
        if (Array.isArray(data.errors) && !!data_errors.cause && typeof data_errors.cause === "string") {
            const _parsed = JSON.parse(data_errors.cause); // Parse stringified CS error and return whole
            cause = Array.isArray(_parsed.errors) && !!_parsed.errors[0] && !!_parsed.errors[0].cause ?
                _parsed.errors[0].cause : null;
            cause = Object.assign({}, data_errors, _parsed.errors[0], { cause });
        }
        return cause;
    }

    return !!message_item ? <MessageHandler message_item={message_item}
        onMessageHandlerDismiss={onErrorHandlerDismiss} /> : <></>;
}

export default React.memo(ErrorHandlerComponent);
