import React, { useState, useEffect, FocusEvent, FormEvent, useRef } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, DirectoryEntity, DriveEntryType, DriveSuccessMessages, DriveUIActionTypes, EntryItem, Message, MessageAnchors, MessageHandlerDisplayType, MessageToastTypes, RenameValidationErrorMessages, useAppDispatch, useRename, validateFileExtension, validateFileFolderName, CollectionEntity
} from "src/common";
import { Icon } from "src/components";
import { uiActions } from "src/store";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  action?: string;
  current_directory: DirectoryEntity;
  collection_info: CollectionEntity;
  handleAction: ActionHandlerFunction;
};

function RenameItemFormComponent(props: AllProps) {
  const { current_account, entry, action, current_directory, collection_info, handleAction } = props;
  const [edit_mode, setEditMode] = useState<boolean>(action === DriveUIActionTypes.Rename);
  const [new_name, setName] = useState<string>(entry.name);
  const input_ref = useRef<HTMLInputElement>(null);
  const { rename, reset, rename_success, rename_error } = useRename(current_account, collection_info, current_directory, entry);
  const dispatch = useAppDispatch();

  // Description: Change panel state on entry change
  useEffect(() => {
    setEditMode(action === DriveUIActionTypes.Rename);
    if (action === DriveUIActionTypes.ResetName) {
      setName(entry.name);
      handleAction({ actionType: DriveUIActionTypes.ResetAction });
    }
  }, [action]);

  // Description: Ensures cursor is in input field when "Rename" option is selected in row dropdown
  useEffect(() => {
    (edit_mode && !!input_ref.current) && input_ref.current.focus();
  }, [edit_mode]);

  // Description: Update form if entry name changes
  useEffect(() => {
    setName(entry.name);
  }, [entry]);

  // Description: Show success message on successful rename
  useEffect(() => {
    if (rename_success) {
      dispatch(uiActions.handleSetMessage(
        new Message(
          DriveSuccessMessages.success_rename.replace(MessageAnchors.prev_name, entry.name).replace(MessageAnchors.new_name, new_name),
          MessageHandlerDisplayType.toastr,
          MessageToastTypes.success
        )
      ));
      reset();
    }
  }, [rename_success]);

  // Description: Show error message on failed rename
  useEffect(() => {
    if (rename_error) {
      handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.ResetName } });
      reset();
    }
  }, [rename_error]);

  // Description: Clear form 
  function handleReset() {
    setEditMode(false);
    setName(entry.name);
    handleAction({ actionType: DriveUIActionTypes.ResetAction });
  }

  // Description: Validate and Save name 
  function handleSubmit() {
    const _new_name = new_name.trim();
    if (entry.name !== _new_name) {
      setEditMode(false);
      handleRename(_new_name);
    } else {
      handleReset();
    }
  }

  // Description: Validate new name and show rename confirmation
  function handleRename(new_name: string) {
    handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: undefined } });
    const invalid = validateFileFolderName(new_name, entry.type, current_directory);
    if (!!invalid) {
      handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.ResetName } });
      dispatch(uiActions.handleRequestErrors(new Message(invalid)));
      return;
    }
    const previous_extension = entry.name.includes(".") ? entry.name.split(".").pop()?.toLowerCase() : "";
    const new_extension = new_name.includes(".") ? new_name.split(".").pop()?.toLowerCase() : "";
    const title = entry.type === DriveEntryType.FILE && validateFileExtension(previous_extension, new_extension);
    if (!!title) {
      const confirmation_dialog = new Message(
        RenameValidationErrorMessages.confirm_change,
        MessageHandlerDisplayType.confirm,
        MessageToastTypes.primary,
        title,
        {
          label: !!new_extension ? `Use ".${new_extension}"` : "Save without extension",
          data: true,
          action: () => rename(new_name)
        },
        {
          label: "Cancel",
          action: () => handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.ResetName } })
        }
      );
      dispatch(uiActions.handleSetMessage(confirmation_dialog));
    } else {
      rename(new_name);
    }
  }

  return edit_mode ?
    <Form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmit();
      }}>
      <Form.Label htmlFor="entry_name_tb" visuallyHidden>Selected Item Name</Form.Label>
      <InputGroup size="sm">
        <Form.Control
          id="entry_name_tb"
          ref={input_ref}
          autoFocus={true}
          type="text"
          placeholder={entry.name}
          value={new_name}
          onChange={(e: FocusEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            e.preventDefault();
            handleSubmit();
          }} />
        {
          !!new_name &&
          <>
            <Button variant="icon" size="sm"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleSubmit}><Icon className="ficon-check" /></Button>
            <Button variant="icon" size="sm" className="text-muted-light"
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleReset}><Icon className="ficon-x-circle" /></Button>
          </>
        }
      </InputGroup>
    </Form> :
    <p onClick={() => setEditMode(true)}>
      {new_name}<Icon className="pv-icon-compose text-muted" />
    </p>;
}

export default React.memo(RenameItemFormComponent);
