import React, { useState, useEffect, useRef } from "react";
import { AppConfiguration, dayjs, LocalAccountStorage, Message, PDFNETJS_WEBVIEWER_OPTIONS, SettingsSuccessMessages, useAppDispatch } from "src/common";
import { accountActions, uiActions } from "src/store";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import { saveAs } from "file-saver";

type AllProps = {
  recovery_shard: string;
  user_id: string;
  downloadComplete: Function;
};

/* Description: PDF generator for the user shard (part of the users key) ** FOR PASSWORD RECOVERY */
function UserShardPDFGeneratorComponent(props: AllProps) {
  const { recovery_shard, user_id, downloadComplete } = props;
  const file_name = `password-recovery-file-${dayjs().local().format("MM-DD-YYYY-h:mm")}.pdf`;
  const [instance, setInstance] = useState<WebViewerInstance | undefined>(undefined);
  const viewer = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  /* Description: on initial load, get the qr code and instantiate a new instance of web viewer.
  We use a template for the initial doc which we will then modify with the current users backup information */
  useEffect(() => {
    (async () => {

      if (!!viewer) {
        const instance = await WebViewer(
          Object.assign({}, PDFNETJS_WEBVIEWER_OPTIONS, {
            initialDoc: "/templates/Express-Account-Recovery-File-Template.pdf",
            fullAPI: true,
          }),
          viewer.current as HTMLDivElement,
        );
        const { documentViewer, PDFNet } = instance.Core;
        // NOTE: Disable js embeds for CSP 
        AppConfiguration.buildForWeb() && instance.Core.disableEmbeddedJavaScript();
        documentViewer.addEventListener("documentLoaded", async () => {
          await PDFNet.runWithCleanup(async () => {
            await modifyPDF(instance);
          }, PDFNETJS_WEBVIEWER_OPTIONS.licenseKey);
        });

      }
    })();
  }, []);

  /* Description: cleanup function, disposes the current documents data  */
  useEffect(() => {
    return () => {
      instance?.Core.documentViewer.dispose();
    };
  }, [instance]);

  /* Description: Notify the store that the backup pdf file has been downloaded and call the getBackup function again. */
  function complete() {
    downloadComplete();
    dispatch(uiActions.handleSetMessage(new Message(SettingsSuccessMessages.succesfully_downloaded_file)));
    LocalAccountStorage.removeTemporaryCookies("express_recovery_not_setup", "false");
    dispatch(accountActions.setExpressRecoveryNotSetup(null));
  };

  /* Description: create and modify the initial template with the users backup info */
  async function modifyPDF(instance: WebViewerInstance) {
    const { documentViewer, PDFNet, annotationManager } = instance.Core;
    await PDFNet.initialize();
    const doc = await documentViewer.getDocument().getPDFDoc();


    // lock the document before a write operation
    await doc.lock();

    const replacer = await PDFNet.ContentReplacer.create();
    const page = await doc.getPage(1);

    // replace a text placeholder
    await replacer.addString("email_address", user_id);
    await replacer.process(page);

    // Modify text field
    // Create a widget annotation
    documentViewer.getAnnotationsLoadedPromise().then(async () => {
      const fieldManager = annotationManager.getFieldManager();
      const field = fieldManager.getField("recovery_code");
      field.setValue(recovery_shard);
      documentViewer.refreshAll();
      documentViewer.getDocument().refreshTextData();
      // Get Blob data and download
      const xfdfString = await annotationManager.exportAnnotations();
      const options = { xfdfString };
      const data = await documentViewer.getDocument().getFileData(options);
      const arr = new Uint8Array(data);
      const blob = new Blob([arr], { type: "application/pdf" });
      saveAs(blob, file_name);
      complete();
      setInstance(instance);
    });
  }


  return <div className="invisible" ref={viewer}></div>;
}

export default React.memo(UserShardPDFGeneratorComponent);
