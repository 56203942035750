/*
*   File:           session.class.ts
*   Description:    Support class for handling session storage and local storage
*   Author:         PreVeil, LLC
*/
import { AccountSessions, AccountDevice, AccountType, AccountSessionsType, UserProfile } from "@preveil-api";
import { isSessionStorageEnabled, AuthenticationConstants, Account, Helpers, AppUserKey, AccountTypeGuard, account_types } from "src/common";

export class LocalAccountStorage implements AccountSessions {
  _pv_account: UserProfile | null = null;
  _most_recent_user_id: string | null = null;
  _most_recent_user_key: string | null = null;
  _most_recent_user_device: AccountDevice | null = null;
  _account_type: AccountType | null = null;
  _contacts: UserProfile[] | null = null;
  constructor(account?: Account, account_types?: AccountType) {
    !!account ?
      this._setAccountSessionsAccount(account, account_types) : this._getSessionStorage();
  }

  get pv_account(): UserProfile | null {
    return this._pv_account;
  }

  set pv_account(profile: UserProfile | null) {
    this._pv_account = profile;
  }

  get account_type(): AccountType | null {
    return this._account_type;
  }

  set account_type(account_type: AccountType | null) {
    this._account_type = account_type;
  }

  get most_recent_user_id(): string | null {
    return this._most_recent_user_id;
  }

  set most_recent_user_id(user_id: string | null) {
    this._most_recent_user_id = user_id;
  }
  get most_recent_user_key(): string | null {
    return this._most_recent_user_key;
  }

  set most_recent_user_key(user_key: string | null) {
    this._most_recent_user_key = user_key;
  }
  get most_recent_user_device(): AccountDevice | null {
    return this._most_recent_user_device;
  }

  set most_recent_user_device(device: AccountDevice | null) {
    this._most_recent_user_device = device;
  }

  get contacts(): UserProfile[] | null {
    return this.contacts;
  }

  set contacts(set_contacts: UserProfile[] | null) {
    this.contacts = set_contacts;
  }

  // Description: Set up the Session class props from account model
  private _setAccountSessionsAccount(account: Account, account_type?: AccountType) {
    this._pv_account = account.profile;
    this._most_recent_user_id = account.profile.address;

    if (!!account.user_keys && account.user_keys.length > 0) {
      const user_key = account.user_keys[0];
      this._most_recent_user_key = !!user_key && user_key instanceof AppUserKey ? Helpers.b64Encode(user_key.serialize()) : null;
    }

    if (!!account.devices && account.devices.length > 0) {
      const device = account.devices[0];
      if (device.device_key instanceof AppUserKey) {
        this._most_recent_user_device = {
          id: device.device_id,
          name: device.device_name,
          device_key: Helpers.b64Encode(device.device_key.serialize())
        };
      }
    }

    if (!!account_type) {
      this.account_type = account_type;
    }
  }

  private _getSessionStorage() {
    if (isSessionStorageEnabled()) {
      const pv_account = sessionStorage.getItem(AuthenticationConstants.USER_ID_SESSION_KEY);
      this.pv_account = !!pv_account ? JSON.parse(pv_account) : null;
      const account_type = sessionStorage.getItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY);
      this.account_type = !!account_type && AccountTypeGuard(account_type) ? account_type as AccountType : account_types.nouser;
      this.most_recent_user_id = localStorage.getItem(AuthenticationConstants.USER_ID_LOCALSTORAGE_KEY);
      this.most_recent_user_key = sessionStorage.getItem(AuthenticationConstants.USER_KEY_SESSION_KEY);
      const most_recent_user_device = sessionStorage.getItem(AuthenticationConstants.DEVICE_SESSION_KEY);
      this.most_recent_user_device = !!most_recent_user_device ? JSON.parse(most_recent_user_device) : null;
    }
  }

  // Description: Set All local and Session Storages
  setLocalStorageSessions(): boolean {
    if (isSessionStorageEnabled()) {
      try {
        if (!!this.most_recent_user_id) {
          localStorage.setItem(AuthenticationConstants.USER_ID_LOCALSTORAGE_KEY, this.most_recent_user_id);
        }
        if (!!this.pv_account) {
          sessionStorage.setItem(AuthenticationConstants.USER_ID_SESSION_KEY, JSON.stringify(this.pv_account));
        }
        if (!!this.account_type) {
          sessionStorage.setItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY, this.account_type);
        }
        if (!!this.most_recent_user_key) {
          sessionStorage.setItem(AuthenticationConstants.USER_KEY_SESSION_KEY, this.most_recent_user_key);
        }
        if (!!this.most_recent_user_device) {
          sessionStorage.setItem(AuthenticationConstants.DEVICE_SESSION_KEY, JSON.stringify(this.most_recent_user_device));
        }
        return true;
      } catch (e) {
        console.error("Error setting Account Local Sessions");
        return false;
      }
    } else {
      return false;
    }
  }

  destroy() {
    this.pv_account = null;
    this._account_type = null;
    this.most_recent_user_id = null;
    this.most_recent_user_key = null;
    this.most_recent_user_device = null;
  }

  // Description: destroy class and stored sessions
  static destroyLocalStorageSessions() {
    if (isSessionStorageEnabled()) {
      localStorage.removeItem(AuthenticationConstants.USER_ID_LOCALSTORAGE_KEY);
      sessionStorage.removeItem(AuthenticationConstants.USER_ID_SESSION_KEY);
      sessionStorage.removeItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY);
      sessionStorage.removeItem(AuthenticationConstants.USER_KEY_SESSION_KEY);
      sessionStorage.removeItem(AuthenticationConstants.DEVICE_SESSION_KEY);
    }
  }

  static getStorageItem(session: AccountSessionsType) {
    return session === AuthenticationConstants.USER_ID_LOCALSTORAGE_KEY ? localStorage.getItem(session) :
      sessionStorage.getItem(session);
  }

  // Contact Sessions:
  // ------------------------------------------------------------------------------------
  // Description: Set a contact session
  static setContactSessionItems(fetch_key: string, contact: UserProfile[]) {
    isSessionStorageEnabled() &&
      localStorage.setItem(fetch_key, JSON.stringify(contact));
  }

  // Description: Retrieve a contact session
  static getContactSessionItems(fetch_key: string): UserProfile[] | undefined {
    if (isSessionStorageEnabled()) {
      const contact_string = localStorage.getItem(fetch_key);
      return !!contact_string ? JSON.parse(contact_string) : null;
    }
  }
  // Description: Remove a specific contact session
  static removeContactSessionItems(fetch_key: string): void {
    isSessionStorageEnabled() && localStorage.removeItem(fetch_key);
  }

  // Description: Add temp cookies for UI behavior
  static setTemporaryCookies(fetch_key: string, value: string) {
    isSessionStorageEnabled() && localStorage.setItem(fetch_key, value);
  }

  // Description: Add temp cookies for UI behavior
  static removeTemporaryCookies(fetch_key: string, value: string) {
    isSessionStorageEnabled() && localStorage.removeItem(fetch_key);
  }

  static updatePVAccountSession(_phone_number?: string) {
    const session_pv_account = sessionStorage.getItem(AuthenticationConstants.USER_ID_SESSION_KEY);
    const _profile = !!session_pv_account ? JSON.parse(session_pv_account) as UserProfile : null;
    if (!!_profile) {
      _profile.phone_number = _phone_number;
      sessionStorage.setItem(AuthenticationConstants.USER_ID_SESSION_KEY, JSON.stringify(_profile));
    }
  }
}
