import React, { useEffect, useState, Dispatch, SetStateAction, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { IActionHandler } from "@preveil-api";
import {
  Account, AccountErrorMessages, GlobalErrorMessages, Helpers, KeyStorageAPI, KeyStorageData, KeystorageResponseErrorCodes, Message, MessageAnchors,
  MessageHandlerDisplayType, PublicRoutes, SettingsErrorMessages, SettingsSuccessMessages, StatusCode, useAppDispatch, useAppSelector, LocalAccountStorage,
  QueryParamKeys, MessageToastTypes, ChangePhoneNumberSteps
} from "src/common";
import { Loading, PhoneNumberForm, SharedCurrentPasswordForm, SharedSmsCodeVerificationForm } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";
import parsePhoneNumberFromString, { PhoneNumber } from "libphonenumber-js";

type AllProps = {
  modal_show: boolean;
  setModalShow: Dispatch<SetStateAction<boolean>>;
  current_phone_number: PhoneNumber;
}

/* Description: Component for the user to change their password. */
function ChangePhoneNumberComponent(props: AllProps) {
  const { modal_show, setModalShow, current_phone_number } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const auth_token_store = useAppSelector((state: RootState) => state.account.auth_token);
  const [expires, setExpires] = useState<string>();
  const [step, setStep] = useState<number>(ChangePhoneNumberSteps.LOADING);
  const auth_token_ref = useRef<string>();
  const phone_number_ref = useRef<string>();
  const current_sms_ref = useRef<string>();
  const new_sms_ref = useRef<string>();
  const sms_verification_title_ref = useRef<string>("");

  useEffect(() => {
    if (!!modal_show) {
      if (!!auth_token_store) {
        auth_token_ref.current = auth_token_store;
        setStep(ChangePhoneNumberSteps.LOADING);
        changePhoneNumber();
      } else {
        setStep(ChangePhoneNumberSteps.SUBMIT_PASSWORD);
      }
    }
  }, [modal_show]);

  // Description: Function that calls keystorage server to change the phone number
  function changePhoneNumber() {
    if (!!auth_token_ref.current && !!current_account) {
      const two_factor_codes = [KeystorageResponseErrorCodes.no_sms_auth_header, KeystorageResponseErrorCodes.no_totp_auth_header];
      const account_ids = Account.getAccountIdentifiers(current_account);
      KeyStorageAPI.changeAuthenticationMethod(account_ids, auth_token_ref.current, phone_number_ref.current, undefined, new_sms_ref.current, undefined, current_sms_ref.current, undefined)
        .then((response) => {
          const message_code = response.data.error || response.data.bad_parameter || "";
          if (!response.isError) {
            !!phone_number_ref.current && LocalAccountStorage.updatePVAccountSession(phone_number_ref.current);
            dispatch(uiActions.handleSetMessage(new Message(SettingsSuccessMessages.succesfully_changed_phone_number)));
            ChangePhoneNumberRequests.handleResetForms();
          } else if (response.status === StatusCode.UNAUTHORIZED_ERROR_CODE && !!message_code && two_factor_codes.includes(message_code)) {
            if (message_code === KeystorageResponseErrorCodes.no_sms_auth_header) {
              sms_verification_title_ref.current = !current_sms_ref.current ? "Verify Your Current Phone Number" : "Verify New Phone Number";
              setStep(ChangePhoneNumberSteps.SUBMIT_SMS_CODE);
            } else {
              ChangePhoneNumberRequests.handleResetForms();
            }
          } else if (message_code === KeystorageResponseErrorCodes.bad_password) {
            dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_password), response.data.error));
            reset();
            setStep(ChangePhoneNumberSteps.SUBMIT_PASSWORD);
          } else if (message_code === KeystorageResponseErrorCodes.bad_sms_code) {
            dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_sms_code), response.data.error));
            reset();
            setStep(ChangePhoneNumberSteps.LOADING);
            changePhoneNumber();
          } else if (message_code === KeystorageResponseErrorCodes.locked_out) {
            // NOTE: lock out occurs when a user wrongly authentifies more than 10 times. It will log out and redirect them to the account locked page
            // this lockout is universal in key storage server.
            ChangePhoneNumberRequests.handleResetForms();
            navigate(`/${PublicRoutes.logout_route}?${QueryParamKeys.REDIRECT_URL_QUERY_KEY}=/${PublicRoutes.account_locked_route}`);
          } else {
            ChangePhoneNumberRequests.handleResetForms();
            dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_changing_phone_number), response));
          }
        }).catch((error) => {
          ChangePhoneNumberRequests.handleResetForms();
          dispatch(uiActions.handleRequestErrors(new Message(SettingsErrorMessages.error_changing_phone_number), error));
        });
    }
  }

  // Description: Functions in handleAction for child components to use.
  const ChangePhoneNumberRequests = {
    // Description: Sets the auth token after directing the user to enter their password (if the auth token is not stored as a session cookie)
    handleSubmitPassword: async (password: string) => {
      if (!!current_account) {
        const _auth_token = Helpers.b64Encode(await KeyStorageData.authenticationToken(current_account.user_id, password));
        auth_token_ref.current = _auth_token;
        setStep(ChangePhoneNumberSteps.LOADING);
        changePhoneNumber();
      }
    },
    // Description: Submit the phone number and call toggleAuthentication which should trigger an sms challenge
    handleSubmitPhone: (params: { expires: string; phone_number?: string; }) => {
      const _phone_number = params.phone_number || (phone_number_ref.current || current_phone_number.number);
      setExpires(params.expires);
      if (!!_phone_number) {
        const new_phone_number = parsePhoneNumberFromString(_phone_number);
        if (new_phone_number?.number === current_phone_number.number && !!current_sms_ref.current) { // if the user entered their current phone number - display warning
          dispatch(uiActions.handleSetMessage(
            new Message(SettingsErrorMessages.same_phone_number, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
        } else {
          phone_number_ref.current = _phone_number;
          changePhoneNumber();
        }
      }
    },
    // Description: handles submitting the sms verification code. 
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      if (!current_sms_ref.current) {
        current_sms_ref.current = sms_code;
        setStep(ChangePhoneNumberSteps.SUBMIT_PHONE_NUMBER);
      } else {
        new_sms_ref.current = sms_code;
        changePhoneNumber();
      }
    },
    // Description: Reset all form states and go back to first step
    handleResetForms: () => {
      setModalShow(false);
      reset();
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    },
  };

  // Description: Reset all refs
  function reset() {
    current_sms_ref.current = undefined;
    new_sms_ref.current = undefined;
    phone_number_ref.current = undefined;
    sms_verification_title_ref.current = "";
  }

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((ChangePhoneNumberRequests as any)[callback] instanceof Function) {
      (ChangePhoneNumberRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      ChangePhoneNumberRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }


  // Description: Render the correct form
  function ChangePhoneNumberForms() {
    switch (step) {
      case ChangePhoneNumberSteps.SUBMIT_PASSWORD:
        return <SharedCurrentPasswordForm submit={ChangePhoneNumberRequests.handleSubmitPassword} cancel={ChangePhoneNumberRequests.handleResetForms} />;
      case ChangePhoneNumberSteps.SUBMIT_SMS_CODE:
        return <SharedSmsCodeVerificationForm expires_param={expires} handleAction={handlePageActions} title={sms_verification_title_ref.current} />;
      case ChangePhoneNumberSteps.SUBMIT_PHONE_NUMBER:
        return <PhoneNumberForm handleAction={handlePageActions} title="Change Your Phone Number" />;
      default:
        return <Loading message="Changing Phone Number" />;
    }
  }

  return <Modal className="change-auth-modal" centered show={modal_show} onHide={() => ChangePhoneNumberRequests.handleResetForms()}>
    <Modal.Body>
      <ChangePhoneNumberForms />
    </Modal.Body>
  </Modal>;
}

export default React.memo(ChangePhoneNumberComponent);
