import * as React from "react";
import { Col, Form } from "react-bootstrap";

type AllProps = {
  id: string;
  label?: string;
  type: string;
  touched: any;
  handleBlur: any;
  handleChange: any;
  value: any;
  xs?: string;
  md?: string;
  lg?: string;
  placeholder?: string;
  autoComplete?: "on" | "off";
  autoFocus?: boolean;
  className?: string;
  helperText?: string;
  required?: boolean;
  errors?: any;
};

function FormFieldComponent(props: AllProps) {
  const { id, xs, md, lg, className, label, required, handleBlur, handleChange, value, type, touched, placeholder, autoComplete, autoFocus, helperText, errors } = props;
  return (
    <Form.Group
      as={Col}
      xs={xs}
      md={md}
      lg={lg}
      className={className || ""}>
      <Form.Label htmlFor={`${id}_tb`}>
        {label || null} {!!required ? <i className="required">*</i> : null}
      </Form.Label>
      <Form.Control
        id={`${id}_tb`}
        type={type}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        isInvalid={touched && !!errors}
        placeholder={placeholder || ""}
        autoComplete={autoComplete || "on"}
        autoFocus={autoFocus || false}
      />
      {helperText ? <Form.Text className="text-muted">{helperText}</Form.Text> : null}
      {touched && !!errors ? <p className="error-message">{errors}</p> : null}
    </Form.Group>
  );
}

export default React.memo(FormFieldComponent);
