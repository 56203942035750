/*
* Description: Crypto API endpoints: RTK Hooks and types
* Author:      PreVeil, LLC
*/
import {
  IFetchResponse, OrgInfo, ApproversInfo, AGUserInfo, CryptoServerBaseResponse, SearchResultItem, CryptoThreadsPage, ClientThreadMessage, DeviceBase,
  LocalUser
} from "@preveil-api";
import { BackupDataObject, BaseAPI, form_headers } from "src/common";
import { cryptoServerAPI as api } from "../base/base-api";
import { uiActions } from "src/store";
import { EmptyObject } from "@preveil-api";
const base_url = `${process.env.REACT_APP_CRYPTO_SERVER}`;

// --------------------------------------------------------------------------------------------
// RTK Queries Calls: 
// --------------------------------------------------------------------------------------------
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ping: build.query<PingApiResponse, PingApiArg>({
      queryFn: async () => {
        const url = "/ping";
        const result: IFetchResponse = await BaseAPI.get(`${base_url}${url}`);
        return !result.isError ? {
          // NOTE: Pass a timestamp for useEffect proper detection
          data: {
            cs_connection: result.data.cs_connection,
            timestamp: Date.now()
          }
        } : { error: result as IFetchResponse };
      }
    }),
    pingVerifyDesktop: build.query<PingVerifyDesktopApiResponse, PingApiArg>({
      queryFn: async () => {
        const url = "/ping";
        const params = undefined;
        // Note: Server at that port is expecting text/html in the request headers otherwise it will return a 404.
        const headers = {
          "Accept": "text/html",
        };
        const result: IFetchResponse = await BaseAPI.get(`${process.env.REACT_APP_PORT}${url}`, params, headers);
        return !result.isError ? {
          data: result.status === 200
        } : { error: result as IFetchResponse };
      }
    }),
    buildVersion: build.query<BuildVersionApiResponse, BuildVersionApiArg>({
      query: () => ({ url: "/get/buildversion" }),
    }),
    getLocalUser: build.query<GetLocalUserApiResponse, GetLocalUserApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${encodeURIComponent(queryArg.userId)}/localusers`,
      }),
    }),
    putLocalUser: build.mutation<GetLocalUserApiResponse, GetLocalUserApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${encodeURIComponent(queryArg.userId)}/localusers`,
        method: "PUT",
      }),
    }),
    // For Dev Trigger key rotation
    putDeviceKeyRotation: build.mutation<CryptoServerBaseResponse, GetLocalUserApiArg>({
      query: (queryArg) => ({
        url: `/user/${encodeURIComponent(queryArg.userId)}/device/refresh`,
        method: "PUT",
      }),
    }),
    getLocalAccountList: build.query<GetLocalAccountListApiResponse, GetLocalAccountListApiArg>({
      query: () => ({ url: `/get/account/list/local`, method: "PUT" }),
    }),
    putLocalAccountList: build.mutation<GetLocalAccountListApiResponse, GetLocalAccountListApiArg>({
      query: (queryArg) => ({ url: `/get/account/list/local`, method: "PUT" }),
    }),
    upgradeExpressAccount: build.mutation<UpgradeExpressAccountApiResponse, UpgradeExpressAccountApiArg>({
      query: (queryArg) => ({
        url: `/account/${encodeURIComponent(queryArg.userId)}/upgrade`,
        method: "PUT",
        params: {
          user_key: queryArg.userKey,
          device_id: queryArg.deviceId,
          device_key: queryArg.deviceKey,
        },
      }),
    }),
    restoreAccountBackup: build.mutation<RestoreAccountBackupApiResponse, BackupDataObject>({
      query: (queryArg) => ({
        url: `/account/${queryArg.user_id}/backup/restore`,
        method: "PUT",
        body: queryArg,
      }),
    }),
    createOrganization: build.mutation<OrgInfo, CreateOrganizationApiArg>({
      query: (queryArg) => ({
        url: `/post/account/${encodeURIComponent(queryArg.userId)}/orgs`,
        method: "PUT",
        body: { org_name: queryArg.orgName, user_department: queryArg.userDepartment },
      }),
    }),
    getApprovers: build.mutation<GetApproversApiResponse, GetApproversApiArg>({
      query: (queryArg) => ({
        url: !!queryArg.secret
          ? `/get/account/${queryArg.user_id}/approval/users`
          : `/get/users/${queryArg.user_id}/approval/users`,
        method: "PUT",
        body: !!queryArg.secret ? { secret: queryArg.secret } : {},
      }),
    }),
    getApprovalGroupLocalInfo: build.mutation<
      GetApprovalGroupLocalInfoApiResponse,
      GetApprovalGroupLocalInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/get/account/${queryArg.user_id}/approval/local`,
        method: "PUT",
        body: { secret: queryArg.secret, doas: queryArg.user_id },
      }),
    }),
    reconstructPrivateKey: build.mutation<ReconstructPrivateKeyApiResponse, ReconstructPrivateKeyApiArg>({
      query: (queryArg) => ({
        url: `/post/account/${queryArg.user_id}/key/reconstruct`,
        method: "PUT",
        body: { secret: queryArg.secret, doas: queryArg.user_id },
      }),
    }),

    // Mail
    //--------------------------------------------------------
    // Search
    search: build.mutation<SearchApiResponse, SearchApiArg>({
      query: (queryArg) => ({
        url: `/mail/${encodeURIComponent(queryArg.userId)}/search`,
        method: "PUT",
        body: { query: queryArg.query },
      }),
    }),
    installMuaProfile: build.mutation<InstallMuaProfileApiResponse, InstallMuaProfileApiArg>({
      query: (queryArg) => ({
        url: `/post/account/${queryArg.userId}/profile`,
        method: "PUT",
        body: {},
      }),
    }),
    getUserKey: build.mutation<GetUserKeyApiResponse, GetUserKeyApiArg>({
      query: (queryArg) => ({
        url: `/get/account/${queryArg.userId}/key/force_get`,
        method: "PUT",
      }),
    }),
    // Get threads
    getPaginatedThreadsRemote: build.mutation<GetPaginatedThreadsRemoteApiResponse, GetPaginatedThreadsRemoteApiArg>({
      query: (queryArg) => ({
        url: `/get/mail/${encodeURIComponent(queryArg.userId)}/mailboxes/${queryArg.mailboxId
          }/threads`,
        method: "PUT",
        body: { limit: queryArg.limit, offset: queryArg.offset },
      }),
    }),
    getPaginatedThreads: build.mutation<GetPaginatedThreadsApiResponse, GetPaginatedThreadsApiArg>({
      query: (queryArg) => ({
        url: `/mail/${encodeURIComponent(queryArg.userId)}`,
        method: "PUT",
        body: queryArg.mailboxData,
      }),
    }),
    getEmailBodies: build.mutation<GetEmailBodiesApiResponse, GetEmailBodiesApiArg>({
      query: (queryArg) => ({
        url: `/mail/thread/${encodeURIComponent(queryArg.userId)}`,
        method: "PUT",
        body: { unique_ids: queryArg.uniqueIds, inlines_only: queryArg.inlinesOnly },
      }),
    }),
    getEmailAttachment: build.mutation<GetEmailAttachmentApiResponse, GetEmailAttachmentApiArg>({
      query: (queryArg) => ({
        url: `/mail/${encodeURIComponent(queryArg.userId)}/contents`,
        method: "PUT",
        body: {
          server_id: queryArg.serverId,
          content_ids: queryArg.contentIds,
          raw: queryArg.raw,
        },
      }),
    }),
    // Settings
    //--------------------------------------------------------
    createApprovalGroup: build.mutation<CreateApprovalGroupApiResponse, CreateApprovalGroupApiArg>({
      query: (queryArg) => ({
        url: `/post/users/${queryArg.userId}/approval/users`,
        method: "PUT",
        body: {
          required_users: queryArg.requiredUsers,
          optional_users: queryArg.optionalUsers,
          optionals_required: queryArg.optionalsRequired,
        },
      }),
    }),
    changeApprovalGroup: build.mutation<ChangeApprovalGroupApiResponse, ChangeApprovalGroupApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${queryArg.userId}/approval/users`,
        method: "PUT",
        body: {
          required_users: queryArg.requiredUsers,
          optional_users: queryArg.optionalUsers,
          optionals_required: queryArg.optionalsRequired,
        },
      }),
    }),
    getUserRequests: build.mutation<GetUserRequestsApiResponse, GetUserRequestsApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${queryArg.userId}/requests`,
        method: "PUT",
        body: {
          status: queryArg.status,
          request_type: queryArg.requestType,
          hide_expired: queryArg.hideExpired,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    deleteUserRequest: build.mutation<DeleteUserRequestApiResponse, DeleteUserRequestApiArg>({
      query: (queryArg) => ({
        url: `/delete/users/${queryArg.userId}/requests/${queryArg.requestId}`,
        method: "PUT",
      }),
    }),
    getUserApprovals: build.mutation<GetUserApprovalsApiResponse, GetUserApprovalsApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${queryArg.userId}/approvals`,
        method: "PUT",
        body: {
          status: queryArg.status,
          response: queryArg.response,
          hide_expired: queryArg.hideExpired,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    respondToUserApproval: build.mutation<RespondToUserApprovalApiResponse, RespondToUserApprovalApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${queryArg.userId}/approvals/${queryArg.requestId}`,
        method: "PUT",
        body: { response: queryArg.response, request: queryArg.request },
      }),
    }),
    respondToAdminApproval: build.mutation<RespondToAdminApprovalApiResponse, RespondToAdminApprovalApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${queryArg.userId}/orgs/${queryArg.orgId}/approvals/${queryArg.requestId}`,
        method: "PUT",
        body: { response: queryArg.response, request: queryArg.request },
      }),
    }),
    getUserRequestResponses: build.mutation<GetUserRequestResponsesApiResponse, GetUserRequestResponsesApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${queryArg.userId}/requests/${queryArg.requestId}/responses`,
        method: "PUT",
      }),
    }),
    getUserDevices: build.mutation<GetUserDevicesApiResponse, GetUserDevicesApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/device/list`,
        method: "PUT",
        body: { for_user: queryArg.forUser },
      }),
    }),
    renameDevice: build.mutation<RenameDeviceApiResponse, RenameDeviceApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/device/${queryArg.deviceId}/patch`,
        method: "PUT",
        body: { name: queryArg.newName },
      }),
    }),
    lockDevice: build.mutation<LockDeviceApiResponse, LockDeviceApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/device/${queryArg.deviceId}/lock`,
        method: "PUT",
        body: { for_user: queryArg.forUser },
      }),
    }),
    unlockDevice: build.mutation<UnlockDeviceApiResponse, UnlockDeviceApiArg>({
      query: (queryArg) => ({
        url: `/user/${queryArg.userId}/device/${queryArg.deviceId}/unlock`,
        method: "PUT",
        body: { for_user: queryArg.forUser },
      }),
    }),
    removeAccountFromCurrentDevice: build.mutation<RemoveAccountFromCurrentDeviceApiResponse, RemoveAccountFromCurrentDeviceApiArg>({
      query: (queryArg) => ({ url: `/delete/user/${queryArg.userId}/local`, method: "PUT" }),
    }),
    // Description: Save mail drafts ***** NOTE: Needs to be a queryFn to submit formData()
    saveDraft: build.mutation<SaveDraftApiResponse, SaveDraftApiArg>({
      queryFn: async (params, api) => {
        const url = `/post/account/${encodeURIComponent(params.senderId)}/draft`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, params.messageData, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // Description: Send mail ***** NOTE: Needs to be a queryFn to submit formData()
    sendEmail: build.mutation<SendEmailApiResponse, SendEmailApiArg>({
      queryFn: async (params, api) => {
        const url = `/put/account/${encodeURIComponent(params.senderId)}/message`;
        const Additional_config = {
          onUploadProgress: (progressEvent: any) => {
            //Set the progress value to show the progress bar
            let uploadloadProgress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
            api.dispatch(uiActions.setProgress(uploadloadProgress));
          },
        };
        const result: IFetchResponse = await BaseAPI.put(
          `${base_url}${url}`,
          params.messageData,
          form_headers,
          Additional_config,
        );
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    deleteEmails: build.mutation<DeleteEmailsApiResponse, DeleteEmailsApiArg>({
      query: (queryArg) => ({
        url: `/delete/account/${encodeURIComponent(queryArg.userId)}/emails/`,
        method: "PUT",
        body: { emails: queryArg.emails },
      }),
    }),
    moveEmailsToMailbox: build.mutation<MoveEmailsToMailboxApiResponse, MoveEmailsToMailboxApiArg>({
      query: (queryArg) => ({
        url: `/post/account/${queryArg.userId}/copy`,
        method: "PUT",
        body: {
          email_ids: queryArg.email_ids,
          dest_mailbox_id: queryArg.dest_mailbox_id,
          src_mailbox_id: queryArg.src_mailbox_id,
          trash_source: true
        },
      }),
    }),
    sendPvInvite: build.mutation<SendPvInviteApiResponse, SendPvInviteApiArg>({
      query: (queryArg) => ({
        url: `/user/${encodeURIComponent(queryArg.userId)}/invite`,
        method: "POST",
        body: { invitee: queryArg.invitee, metadata: queryArg.metadata },
      }),
    }),
    /*
      Admin
      -----------------------------------------------
    */
    // Get Trusted Communities
    getTrustedCommunities: build.mutation<GetTrustedCommunitiesApiResponse, GetTrustedCommunitiesApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/whitelists`,
        method: "PUT",
      }),
    }),
    // Add to Trusted Community
    addToTrustedCommunity: build.mutation<AddToTrustedCommunityApiResponse, AddToTrustedCommunityApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/whitelists`,
        method: "PUT",
        body: {
          users: queryArg.users,
          domains: queryArg.domains,
        },
      }),
    }),
    removeFromTrustedCommunity: build.mutation<RemoveFromTrustedCommunityApiResponse, RemoveFromTrustedCommunityApiArg>({
      query: (queryArg) => ({
        url: `/delete/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId
          }/whitelists`,
        method: "PUT",
        body: {
          users: queryArg.users,
          domains: queryArg.domains,
        },
      }),
    }),
    toggleTrustedCommunity: build.mutation<ToggleTrustedCommunityApiResponse, ToggleTrustedCommunityApiArg>({
      query: (queryArg) => ({
        url: `/patch/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId
          }/whitelists`,
        method: "PUT",
        body: { set_active: queryArg.setActive },
      }),
    }),
    createOrgApprovalGroup: build.mutation<CreateOrgApprovalGroupApiResponse, CreateOrgApprovalGroupApiArg>({
      query: (queryArg) => ({
        url: `/post/${queryArg.userId}/orgs/${queryArg.orgId}/groups`,
        method: "PUT",
        body: {
          name: queryArg.name,
          optionals_required: queryArg.optionalsRequired,
          approvers: queryArg.approvers,
        },
      }),
    }),
    assignApprovalGroupActivity: build.mutation<AssignApprovalGroupActivityApiResponse, AssignApprovalGroupActivityApiArg>({
      query: (queryArg) => ({
        url: `/put/${queryArg.userId}/orgs/${queryArg.orgId}/groups/${queryArg.approvalGroupUid}`,
        method: "PUT",
        body: { group_role: queryArg.groupRole, group_version: queryArg.groupVersion },
      }),
    }),
    adminSetInviteEmailType: build.mutation<AdminSetInviteEmailTypeApiResponse, AdminSetInviteEmailTypeApiArg>({
      query: (queryArg) => ({
        url: `/patch/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId
          }/invite_email_type`,
        method: "PUT",
        body: { no_download_email: queryArg.inviteEmailState },
      }),
    }),
    deleteOrgUser: build.mutation<DeleteOrgUserApiResponse, DeleteOrgUserApiArg>({
      query: (queryArg) => ({
        url: `/delete/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/members/${queryArg.userToBeDeletedId}`,
        method: "PUT",
      }),
    }),
    addOrganizationMembers: build.mutation<AddOrganizationMembersApiResponse, AddOrganizationMembersApiArg>({
      query: (queryArg) => ({
        url: `/post/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/members`,
        method: "PUT",
        body: { users: queryArg.users },
      }),
    }),
    orgSubsumeMember: build.mutation<OrgSubsumeMemberApiResponse, OrgSubsumeMemberApiArg>({
      query: (queryArg) => ({
        url: `/post/users/${queryArg.userId}/orgs/${queryArg.orgId}/subsume`,
        method: "PUT",
        body: {
          subsume_user_id: queryArg.subsumeUserId,
          department: queryArg.department,
        },
      }),
    }),
    requestExport: build.mutation<RequestExportApiResponse, RequestExportApiArg>({
      query: (queryArg) => ({
        url: `/post/users/${queryArg.userId}/orgs/${queryArg.orgId}/exportrequests`,
        method: "PUT",
        body: {
          from: queryArg.payload.from,
          include_emails: queryArg.payload.include_emails,
          include_files: queryArg.payload.include_files,
          include_logs: queryArg.payload.include_logs,
          include_org_acl_report: queryArg.payload.include_org_acl_report,
          until: queryArg.payload.until,
          users: queryArg.payload.users,
        },
      }),
    }),
    addOrgApprovalGroupMembers: build.mutation<
      AddOrgApprovalGroupMembersApiResponse,
      AddOrgApprovalGroupMembersApiArg
    >({
      query: (queryArg) => ({
        url: `/post/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/groups/${queryArg.approvalGroupUid}/members`,
        method: "PUT",
        body: {
          group_version: queryArg.groupVersion,
          optionals_required: queryArg.optionalsRequired,
          users: queryArg.users,
          current_group_id: queryArg.currentGroupId,
          current_group_version: queryArg.currentGroupVersion,
          approvers: queryArg.approvers,
        },
      }),
    }),
    updateUserOrgRole: build.mutation<UpdateUserOrgRoleApiResponse, UpdateUserOrgRoleApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${encodeURIComponent(queryArg.userId)}/orgs/${encodeURIComponent(queryArg.orgId)}/members/${queryArg.userAddress}`,
        method: "PUT",
        body: { role: queryArg.role, member_id: queryArg.memberId },
      }),
    }),
    updateUserOrgDept: build.mutation<UpdateUserOrgDeptApiResponse, UpdateUserOrgDeptApiArg>({
      query: (queryArg) => ({
        url: `/put/${encodeURIComponent(queryArg.userId)}/orgs/${encodeURIComponent(queryArg.orgId)}/members`,
        method: "PUT",
        body: { department: queryArg.department, member_id: queryArg.memberId },
      }),
    }),
    deleteAdminRequest: build.mutation<DeleteAdminRequestApiResponse, DeleteAdminRequestApiArg>({
      query: (queryArg) => ({
        url: `/delete/users/${queryArg.userId}/orgs/${queryArg.orgId}/requests/${queryArg.requestId}`,
        method: "PUT",
      }),
    }),
    getExportRequests: build.mutation<GetExportRequestsApiResponse, GetExportRequestsApiArg>({
      query: (queryArg) => ({ url: `/get/users/${queryArg.userId}/exportrequests`, method: "PUT" }),
    }),
    startExport: build.mutation<StartExportApiResponse, StartExportApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${queryArg.userId}/orgs/${queryArg.orgId}/exportrequests/${queryArg.exportId}/start`,
        method: "PUT",
        body: { dropdir: queryArg.dropDir },
      }),
    }),
    cancelExport: build.mutation<CancelExportApiResponse, CancelExportApiArg>({
      query: (queryArg) => ({
        url: `/put/users/${queryArg.userId}/orgs/${queryArg.orgId}/exportrequests/${queryArg.exportId}/cancel`,
        method: "PUT",
      }),
    }),
    completeExport: build.mutation<CompleteExportApiResponse, CompleteExportApiArg>({
      // Note from V1: Finishing a job that is not running means that crypto server and drive have potentially become out of sync.
      // To recover we try to complete as filesync should have done, and if that fails delete the job to clean up.
      // See cancelExport in V1 for reference.
      query: (queryArg) => ({
        url: `/put/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/exportrequests/${queryArg.exportId}/complete`,
        method: "PUT",
      }),
    }),
    getOrgRequests: build.mutation<GetOrgRequestsApiResponse, GetOrgRequestsApiArg>({
      query: (queryArg) => ({
        url: `/get/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/requests`,
        method: "PUT",
        body: {
          status: queryArg.status,
          hide_expired: queryArg.hideExpired,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    deleteExportRequest: build.mutation<DeleteExportRequestApiResponse, DeleteExportRequestApiArg>({
      query: (queryArg) => ({
        url: `/delete/users/${encodeURIComponent(queryArg.userId)}/orgs/${queryArg.orgId}/exportrequests/${queryArg.exportId}`,
        method: "PUT",
      }),
    }),
    getCertificateDetails: build.mutation<GetCertificateDetailsApiResponse, GetCertificateDetailsApiArg>({
      query: (queryArg) => ({
        url: `/post/verify_certificate`,
        method: "PUT",
        body: {
          certificate_body: queryArg.certificate_body,
        },
      }),
    }),

  }),
  // Add more ...
});


// GENERAL EXPORTS OF TYPES AND HOOKS:
export { injectedRtkApi as cryptoApi };
// Request and Response types:
export type PingApiArg = void;
export type PingApiResponse = /** status 200 successful operation */ {
  cs_connection: boolean;
};
export type PingVerifyDesktopApiResponse = /** status 200 successful operation */ boolean;
export type BuildVersionApiArg = void;
export type BuildVersionApiResponse = Record<string, number>; // INumberObject; /** status 200 successful operation */
export type GetLocalUserApiResponse = Record<string, LocalUser[]>; /** status 200 successful operation */
export type GetLocalUserApiArg = {
  userId: string;
};
export type GetLocalAccountListApiResponse = Record<string, LocalUser[]>; /** status 200 successful operation */
export type GetLocalAccountListApiArg = void;
export type UpgradeExpressAccountApiResponse = {
  device_name: string;
}
export type UpgradeExpressAccountApiArg = {
  userId: string;
  userKey: string;
  deviceId: string;
  deviceKey: string;
};
export type RestoreAccountBackupApiResponse = void;  /** status 200 ok */
export type CreateOrganizationApiResponse = { /** status 200 ok */
  dept_name?: string;
  org_id?: string;
  org_name?: string;
  role?: string;
  show_mua_prompt?: boolean;
};
export type CreateOrganizationApiArg = {
  userId: string;
  orgName: string;
  userDepartment: string;
};
export type GetApproversApiResponse = /** status 200 ok */ {
  optional_users: ApproversInfo[];
  optionals_required: number;
  required_users: ApproversInfo[];
  user_id: string;
};
export type GetApproversApiArg = AGUserInfo;
export type GetApprovalGroupLocalInfoApiResponse = Record<string, boolean>;
export type GetApprovalGroupLocalInfoApiArg = AGUserInfo;
export type ReconstructPrivateKeyApiResponse = CryptoServerBaseResponse;
export type ReconstructPrivateKeyApiArg = AGUserInfo;
export type SignWithUserKeyApiResponse = {
  key_version: number;
  signature: string;
};
export type GetUserKeyApiResponse = /** status 200 ok */ {
  key?: string;
};
export type GetUserKeyApiArg = {
  userId: string;
};
// Mail
export type SearchApiResponse = SearchResultItem[]; /** status 200 ok */
export type SearchApiArg = {
  userId: string;
  query: {
    type: string;
    value: string;
  }[];
};

export type GetPaginatedThreadsRemoteApiArg = {
  userId: string;
  mailboxId: string;
  limit: number;
  offset: number;
};
/** status 200 ok */
export type GetPaginatedThreadsRemoteApiResponse = {
  [key: string]: CryptoThreadsPage
}
export type GetPaginatedThreadsApiResponse = {
  [key: string]: CryptoThreadsPage;
}
export type GetPaginatedThreadsApiArg = {
  userId: string;
  mailboxData: {
    id: string;
    limit: number;
    offset: number;
  }[];
};
export type GetEmailBodiesApiResponse = {  /** status 200 ok */
  messages: { [key: string]: ClientThreadMessage };
};
export type GetEmailBodiesApiArg = {
  userId: string;
  uniqueIds: string[];
  inlinesOnly: boolean;
};
export type GetEmailAttachmentApiResponse = {
  contents: Record<string, string>;
}; /** status 200 ok */
export type GetEmailAttachmentApiArg = {
  userId: string;
  serverId: string;
  contentIds: any;
  raw: boolean;
};
export type CreateApprovalGroupApiResponse = /** status 200 ok */ {
  request: {
    device_id: string;
    expiration: string;
    metadata: {
      approval_group: {
        approvers: {
          user_id: string;
          secret: string;
          required: boolean;
          protocol_version: number;
          key_version: number;
          display_name: string;
          deleted: boolean;
          account_version: number;
        }[];
        id: string;
        optionals_required: number;
        __dirty__?: boolean;
      };
    };
    payload: string;
    protocol_version: number;
    requester: string;
    request_id?: string; // only exists on the ChangeApprovalGroupApiResponse
    status: string;
    timestamp: string;
    type: string;
    __dirty__: boolean;
  };
};
export type CreateApprovalGroupApiArg = {
  userId: string;
  requiredUsers: string[];
  optionalUsers: string[];
  optionalsRequired: number;
};
export type ChangeApprovalGroupApiResponse = /** status 200 ok */ {
  request: {
    collection_id: string;
    expiration: string;
    group_id: string;
    group_version: string;
    metadata: {
      approval_group: {
        approvers: {
          user_id: string;
          secret: string;
          required: boolean;
          protocol_version: number;
          key_version: number;
          display_name: string;
          deleted: boolean;
          account_version: number;
        }[];
        id: string;
        optionals_required: number;
        __dirty__: boolean;
      };
      public_key: string;
      wrapped_last_key: string;
    };
    payload: string;
    protocol_version: number;
    request_id: string;
    requester: string;
    started: boolean;
    status: string;
    timestamp: string;
    type: string;
    __dirty__: boolean;
  };
};
export type ChangeApprovalGroupApiArg = {
  userId: string;
  requiredUsers: string[];
  optionalUsers: string[];
  optionalsRequired: number;
};
export type DeleteUserRequestApiResponse = {
  errors?: any;
};
export type DeleteUserRequestApiArg = {
  userId: string;
  requestId: string;
};
export type GetUserApprovalsApiResponse = /** status 200 ok */ {
  approvals: {
    request_id: string;
    response: string;
    signature: string;
    payload: string;
    status: string;
    expiration: string;
    __dirty__: boolean;
  }[];
  total_rows: number;
};
export type GetUserApprovalsApiArg = {
  userId: string;
  status?: string;
  response?: string;
  hideExpired?: boolean;
  limit?: number;
  offset?: number;
};
export type RespondToUserApprovalApiResponse = EmptyObject;
export type RespondToUserApprovalApiArg = {
  userId: string;
  requestId: string;
  response: boolean;
  request: string;
};
export type RespondToAdminApprovalApiResponse = EmptyObject;
export type RespondToAdminApprovalApiArg = {
  userId: string;
  orgId: string;
  requestId: string;
  response: boolean;
  request: string;
};
export type GetUserRequestsApiResponse = /** status 200 ok */ {
  requests: {
    status: string;
    collection_id: string;
    expiration: string;
    group_id: string;
    group_version: string;
    payload: string;
    protocol_version: number;
    metadata: {
      approval_group?: {
        approvers?: {
          user_id?: string;
          secret?: string;
          required?: boolean;
          protocol_version?: number;
          key_version?: number;
          display_name?: string;
          deleted?: boolean;
          account_version?: number;
        }[];
        id?: string;
        optionals_required?: number;
      };
      public_key?: string;
      wrapped_last_key?: string;
    };
    request_id: string;
    requester: string;
    started: boolean;
    timestamp: string;
    type: string;
  }[];
  total_rows: number;
};
export type GetUserRequestsApiArg = {
  userId: string;
  orgId?: string;
  status: string;
  requestType?: string;
  hideExpired: boolean;
  limit?: number;
  offset?: number;
};
export type GetUserRequestResponsesApiResponse = /** status 200 ok */ {
  responses: {
    approver: string;
    response: string;
  }[];
};
export type GetUserRequestResponsesApiArg = {
  userId: string;
  requestId: string;
}
export type InstallMuaProfileApiResponse = /** status 200 ok */ {
  status?: string;
};
export type InstallMuaProfileApiArg = {
  userId: string;
};
export type GetUserDevicesApiResponse = /** status 200 ok */ DeviceBase[];
export type GetUserDevicesApiArg = {
  userId: string;
  forUser?: string;
};
export type RenameDeviceApiResponse = EmptyObject
export type RenameDeviceApiArg = {
  userId: string;
  deviceId: string;
  newName: string;
};
export type LockDeviceApiResponse = /** status 200 ok */ DeviceBase;
export type LockDeviceApiArg = {
  userId: string;
  deviceId: string;
  forUser?: string;
};
export type UnlockDeviceApiResponse = /** status 200 ok */ DeviceBase;
export type UnlockDeviceApiArg = {
  userId: string;
  deviceId: string;
  forUser?: string;
};
export type RemoveAccountFromCurrentDeviceApiResponse = EmptyObject;
export type RemoveAccountFromCurrentDeviceApiArg = {
  userId: string;
};
export type SendEmailApiResponse = /** status 200 ok */ {
  body?: {
    trashed?: object;
    uid?: number;
    unique_id?: string;
  }[];
  complete?: boolean;
  percent?: number;
};
export type SendEmailApiArg = {
  senderId: string;
  messageData: FormData;
};
export type DeleteEmailsApiResponse = {  /** status 200 ok */
  [key: string]: ClientThreadMessage;
};
export type DeleteEmailsApiArg = {
  userId: string;
  emails: string[]; // pass an array of unique_ids to delete
};
export type MoveEmailsToMailboxApiResponse = /** status 200 ok */ {
  [key: string]: {
    duped_email?: ClientThreadMessage;
    email_update?: ClientThreadMessage;
  }
};
export type MoveEmailsToMailboxApiArg = {
  userId: string;
  email_ids: string[];
  dest_mailbox_id: string;
  src_mailbox_id: string;
  trash_source: boolean;
};
export type SaveDraftApiResponse = {  /** status 200 ok */
  trashed?: object;
  uid?: number;
  unique_id?: string;
};
export type SaveDraftApiArg = {
  senderId: string;
  messageData: FormData; // TO BE TYPED
};
export type SetEmailFlagApiResponse = { /** status 200 ok */
  messages?: ClientThreadMessage[];
};
export type SetEmailFlagApiArg = {
  emails: string[]; // unique_ids[]
  flag: string;
};

export type SendPvInviteApiResponse = EmptyObject;
export type SendPvInviteApiArg = {
  userId: string;
  invitee: string;
  metadata: string;
};
export type GetTrustedCommunitiesApiResponse = /** status 200 ok */ {
  domains?: string[];
  users?: string[];
  is_active?: boolean;
};
export type GetTrustedCommunitiesApiArg = {
  userId: string;
  orgId: string;
};
export type AddToTrustedCommunityApiResponse = EmptyObject;
export type AddToTrustedCommunityApiArg = {
  userId: string;
  orgId: string;
  users: string[];
  domains: string[];
};
export type RemoveFromTrustedCommunityApiResponse = EmptyObject;
export type RemoveFromTrustedCommunityApiArg = {
  userId: string;
  orgId: string;
  users: string[];
  domains: string[];
};
export type ToggleTrustedCommunityApiResponse = EmptyObject;
export type ToggleTrustedCommunityApiArg = {
  userId: string;
  orgId: string;
  setActive: boolean;
};
export type CreateOrgApprovalGroupApiResponse = /** status 200 ok */ {
  id?: string;
  version?: string;
};
export type CreateOrgApprovalGroupApiArg = {
  userId: string;
  orgId: string;
  name: string;
  optionalsRequired: number;
  approvers: {
    user_id: string;
    account_version: number;
    required: boolean;
  }[];
};
export type AssignApprovalGroupActivityApiResponse = /** status 200 ok */ {
  request?: {
    device_id?: string;
    expiration?: string;
    metadata?: {
      event?: {
        payload?: string;
        signature?: string;
      };
      group_id?: string;
      group_role?: string;
      requester_key_version?: number;
      version?: string;
    };
    payload?: string;
    protocol_version?: number;
    request_id?: string;
    requester?: string;
    started?: boolean;
    status?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
  };
};
export type AssignApprovalGroupActivityApiArg = {
  userId: string;
  orgId: string;
  approvalGroupUid: string;
  groupRole: string;
  groupVersion: string;
};
export type AdminSetInviteEmailTypeApiResponse = EmptyObject;
export type AdminSetInviteEmailTypeApiArg = {
  userId: string;
  orgId: string;
  inviteEmailState: boolean;
};
export type DeleteOrgUserApiArg = {
  userId: string;
  orgId: string;
  userToBeDeletedId: string;
};
export type DeleteOrgUserApiResponse = {
  request?: {
    device_id?: string;
    expiration?: string;
    metadata?: {
      user_id?: string;
    };
    payload?: string;
    protocol_version?: number;
    requester?: string;
    status?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
  };
};
export type AddOrganizationMembersApiResponse = /** status 200 ok */ {
  status?: string;
};
export type AddOrganizationMembersApiArg = {
  userId: string;
  orgId: string;
  users: {
    user_id?: string;
    display_name?: string | null;
    role?: string;
    department?: string;
  }[];
};
export type OrgSubsumeMemberApiResponse = /** status 200 ok */ {
  request?: {
    collection_id?: string;
    expiration?: string;
    group_id?: string;
    group_version?: string;
    metadata?: {
      department?: string;
      entity_id?: string;
      subsume_user_id?: string;
    };
    payload?: string;
    protocol_version?: number;
    request_id?: string;
    requester?: string;
    signature?: string;
    started?: boolean;
    status?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
  };
};
export type OrgSubsumeMemberApiArg = {
  userId: string;
  orgId: string;
  subsumeUserId: string;
  department: string;
};
export type RequestExportApiArg = {
  userId: string;
  orgId: string;
  payload: {
    from: string;
    include_emails: boolean;
    include_files: boolean;
    include_logs: boolean;
    include_org_acl_report: boolean;
    until?: string;
    users: {
      key_version: number;
      user_id: string;
    }[];
  }
};
export type RequestExportApiResponse = /** status 200 ok */ {
  request: {
    collection_id?: string;
    expiration?: number;
    group_id?: string;
    group_version?: string;
    payload?: string;
    protocol_version?: number;
    request_id?: string;
    requester?: string;
    signature?: string;
    started?: boolean;
    status?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
    metadata?: {
      from?: string;
      include_emails?: boolean;
      include_files?: boolean;
      include_logs?: boolean;
      include_org_acl_report?: boolean;
      include_user_acl_report?: boolean;
      until?: string;
      users?: {
        user_id?: string;
        key_version?: number;
      }[];
    };
  }
};
export type AddOrgApprovalGroupMembersApiResponse = /** status 200 ok */ {
  recovery_group?: string;
  users?: any;
  request?: {
    device_id?: string;
    expiration?: string;
    metadata?: {
      current_group_id?: string;
      current_group_version?: string;
      events?: {
        payload?: string;
        signature?: string;
        user_id?: string;
      }[];
      requester_key_version?: string;
    };
    payload?: string;
    protocol_version?: number;
    requester?: string;
    status?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
  };
};
export type AddOrgApprovalGroupMembersApiArg = {
  userId: string;
  orgId: string;
  approvalGroupUid: string;
  groupVersion: string;
  optionalsRequired: number;
  users: any;
  currentGroupId: string | null;
  currentGroupVersion: string | null;
  approvers: {
    user_id?: string;
    account_version?: number;
    required?: boolean;
  }[];
};
export type UpdateUserOrgRoleApiResponse = /** status 200 ok */ {
  request?: {
    device_id?: string;
    expiration?: string;
    metadata?: {
      department?: string;
      entity_id?: string;
      subsume_user_id?: string;
    };
    payload?: string;
    protocol_version?: number;
    requester?: string;
    timestamp?: string;
    type?: string;
    __dirty__?: boolean;
  };
};
export type UpdateUserOrgRoleApiArg = {
  userId: string;
  orgId: string;
  userAddress: string;
  role: string;
  memberId: string;
};
export type UpdateUserOrgDeptApiResponse = EmptyObject;
export type UpdateUserOrgDeptApiArg = {
  userId: string;
  orgId: string;
  department: string;
  memberId: string;
};
export type DeleteAdminRequestApiResponse = EmptyObject;
export type DeleteAdminRequestApiArg = {
  userId: string;
  orgId: string;
  requestId: string;
};
export type ExportInfo = {
  status?: string;
  dropdir?: string;
  approvers_info?: {
    user_id?: {
      approver_shards?: object;
      approver_signature?: string;
    };
    dropdir?: string;
  };
  group_info?: {
    approvers?: {
      account_version?: number;
      required?: boolean;
      user_id?: string;
    }[];
    optionals_required?: number;
  };
  request?: {
    request_id?: string;
    request_payload?: string;
    signature?: string;
  };
};
export type GetExportRequestsApiResponse = /** status 200 ok */ {
  request_id?: {
    approvers_info?: {
      user_id?: {
        approver_shards?: object;
        approver_signature?: string;
      };
      dropdir?: string;
    };
    group_info?: {
      approvers?: {
        account_version?: number;
        required?: boolean;
        user_id?: string;
      }[];
      optionals_required?: number;
    };
    request?: {
      request_id?: string;
      request_payload?: string;
      signature?: string;
    };
    status?: string;
  };
};
export type GetExportRequestsApiArg = {
  userId: string;
};
export type StartExportApiResponse = /** status 200 ok */ ExportInfo;
export type StartExportApiArg = {
  userId: string;
  orgId: string;
  exportId: string;
  dropDir: string;
};
export type CancelExportApiResponse = /** status 200 ok */ ExportInfo;
export type CancelExportApiArg = {
  userId: string;
  orgId: string;
  exportId: string;
};
export type CompleteExportApiResponse = /** status 200 ok */ ExportInfo;
export type CompleteExportApiArg = {
  userId: string;
  orgId: string;
  exportId: string;
};
export type GetOrgRequestsApiResponse = /** status 200 ok */ {
  requests?: {
    request_id?: object;
    collection_id?: object;
    group_id?: object;
    group_version?: any;
    requester?: string;
    type?: string;
    payload?: string;
    started?: string;
    status?: string;
    signature?: string;
    timestamp?: string;
    expiration?: string;
    protocol_version?: number;
    __dirty__?: string;
    metadata?: {
      from?: string;
      include_emails?: boolean;
      include_files?: boolean;
      include_logs?: boolean;
      include_orgs_acl_report?: boolean;
      include_user_acl_report?: boolean;
      until?: string;
      users?: {
        user_id?: string;
        key_version?: number;
      }[];
    };
  }[];
  total_rows?: number;
};
export type GetOrgRequestsApiArg = {
  userId: string;
  orgId: string;
  status?: string;
  hideExpired?: boolean;
  limit?: number;
  offset?: number | null;
};
export type DeleteExportRequestApiResponse = /** status 200 ok */ ExportInfo;
export type DeleteExportRequestApiArg = {
  userId: string;
  orgId: string;
  exportId: string;
};
export type GetCertificateDetailsApiResponse = {
  issued_to: string;
  issued_by: string;
  expiration_date: string;
  friendly_name: string;
};
export type GetCertificateDetailsApiArg = {
  certificate_body: string;
};


// Export hooks
export const {
  usePingQuery,
  usePingVerifyDesktopQuery,
  useBuildVersionQuery,
  useGetLocalUserQuery,
  usePutLocalUserMutation,
  useGetLocalAccountListQuery,
  usePutLocalAccountListMutation,
  usePutDeviceKeyRotationMutation,
  useUpgradeExpressAccountMutation,
  useCreateOrganizationMutation,
  useRestoreAccountBackupMutation,
  useGetApproversMutation,
  useGetApprovalGroupLocalInfoMutation,
  useReconstructPrivateKeyMutation,
  useInstallMuaProfileMutation,
  useGetUserKeyMutation,
  useSearchMutation,
  useGetPaginatedThreadsRemoteMutation,
  useGetPaginatedThreadsMutation,
  useGetEmailBodiesMutation,
  useGetEmailAttachmentMutation,
  useCreateApprovalGroupMutation,
  useChangeApprovalGroupMutation,
  useGetUserRequestsMutation,
  useDeleteUserRequestMutation,
  useGetUserApprovalsMutation,
  useRespondToUserApprovalMutation,
  useRespondToAdminApprovalMutation,
  useGetUserRequestResponsesMutation,
  useGetUserDevicesMutation,
  useRenameDeviceMutation,
  useLockDeviceMutation,
  useUnlockDeviceMutation,
  useRemoveAccountFromCurrentDeviceMutation,
  useSendEmailMutation,
  useDeleteEmailsMutation,
  useSaveDraftMutation,
  useSendPvInviteMutation,
  useGetTrustedCommunitiesMutation,
  useAddToTrustedCommunityMutation,
  useRemoveFromTrustedCommunityMutation,
  useToggleTrustedCommunityMutation,
  useCreateOrgApprovalGroupMutation,
  useAssignApprovalGroupActivityMutation,
  useAdminSetInviteEmailTypeMutation,
  useDeleteOrgUserMutation,
  useOrgSubsumeMemberMutation,
  useAddOrganizationMembersMutation,
  useAddOrgApprovalGroupMembersMutation,
  useUpdateUserOrgRoleMutation,
  useUpdateUserOrgDeptMutation,
  useRequestExportMutation,
  useGetExportRequestsMutation,
  useStartExportMutation,
  useCancelExportMutation,
  useDeleteAdminRequestMutation,
  useGetOrgRequestsMutation,
  useDeleteExportRequestMutation,
  useGetCertificateDetailsMutation,
} = injectedRtkApi;

// -------------------------------------------------------------------------------------------------------
// ------------------------------- Additional Fetch
// -------------------------------------------------------------------------------------------------------
