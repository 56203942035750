import React, { useState, FormEvent, FocusEvent, useCallback, KeyboardEvent } from "react";
import { InputGroup, Form, Button, Badge } from "react-bootstrap";
import { fileExtensions, KeyCode } from "src/common";
import { Icon } from "src/components";
import { SearchAutocomplete } from ".";
import _ from "lodash";

type AllProps = {
    extensions_active: string[];
    setExtensionsActiveState: (extension: string, active: boolean) => void;
}

const file_extension: string[] = fileExtensions();
function SearchExtensionFilterComponent(props: AllProps) {
    const { extensions_active, setExtensionsActiveState } = props;
    const [search_term, setSearchTerm] = useState<string>("");
    const [active, setActive] = useState<string>(""); // NOTE: Navigate with keyboard
    const [found_extensions, setFoundExtensions] = useState<string[] | null>(null);

    // Description: Callback with debounce to handle user input in textbox
    const onSearchHandler = useCallback(
        _.debounce((_searchTerm: string) => {
            if (!!_searchTerm && _searchTerm.length > 0) {
                const found = _.filter(file_extension.slice(), (extension: string) =>
                    (extension.includes(_searchTerm) && !extensions_active.includes(extension)));
                setFoundExtensions(found);
            };
        }, 200), [extensions_active]);

    // Description: Set the current state for controlled form and propagate to store state
    function handleChange(new_search_term: string) {
        setSearchTerm(new_search_term);
        (!!new_search_term && new_search_term.length > 0 && file_extension.length > 0) &&
            onSearchHandler(new_search_term);
    }

    // Description: KeyDown events:
    //    - Key ArrowDown and ArrowUp Select active profile
    //    - On Tab submit form with Selected or item in search box
    //       only if field has content otherwise tab to next field
    function handleKeyDown(e: KeyboardEvent<HTMLFormElement>) {
        if (e.key === KeyCode.Tab) {
            const new_search_term = e.currentTarget.extension.value.trim().toLowerCase();
            handleSubmit(new_search_term);
        }

        if (!!found_extensions && (e.key === KeyCode.ArrowDown || e.key === KeyCode.ArrowUp)) {
            const index = _.findIndex(found_extensions, (_active: string) => _active === active);
            const new_index = e.key === KeyCode.ArrowDown ? index + 1 : index - 1;
            (e.key === KeyCode.ArrowDown) ? setActive(!!found_extensions[new_index] ? found_extensions[new_index] : found_extensions[0]) :
                setActive(!!found_extensions[new_index] ? found_extensions[new_index] : found_extensions[(found_extensions.length - 1)]);
        }
    }

    // Description: Select a new string
    function handleSubmit(new_search_term: string) {
        if (file_extension.includes(new_search_term)) {
            handleChange(new_search_term);
        } else if (!!active && file_extension.includes(active)) {
            setExtensionsActiveState(active, true);
        }
        else if (!extensions_active.includes(`.${new_search_term}`) && !active) {   // NOTE: add custom extension (user derived)
            setExtensionsActiveState(`.${new_search_term}`, true);
        }
        handleFormReset();
    }

    // Description: Reset form on close click
    function handleFormReset() {
        setSearchTerm("");
        setFoundExtensions(null);
        setActive("");
    }

    // Description: handle selected items and filter
    function resultBadges() {
        return <div className="badges">
            {
                _.map(extensions_active, (extension: string, i: number) => {
                    return <Badge key={`extension_${i}`}
                        bg="light" >
                        {extension}
                        <Button variant="icon" size="sm" tabIndex={-1}
                            onClick={() => setExtensionsActiveState(extension, false)}>
                            <Icon className="ficon-x" /></Button>
                    </Badge>;
                })
            }
        </div>;
    }

    return <Form
        noValidate
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(e.currentTarget.extension.value.trim().toLowerCase());
        }}
        onKeyDown={handleKeyDown}>
        <label htmlFor="extension">File Type:</label>
        <InputGroup>
            <InputGroup.Text className="bold">.</InputGroup.Text>
            <Form.Control
                id="extension_tb"
                autoComplete="off"
                name="extension"
                value={search_term}
                data-tooltip-id="pv-tooltip"
                data-tooltip-place="bottom"
                placeholder="File Extension"
                maxLength={5}
                onChange={(e: FocusEvent<HTMLInputElement>) => handleChange(e.target.value.trim().toLowerCase())} />
            {!!search_term &&
                <Button variant="icon" className="text-muted-light" size="sm"
                    onClick={handleFormReset}><Icon className="ficon-x-circle" /></Button>}
        </InputGroup>
        {
            (!!found_extensions && found_extensions.length > 0) &&
            <SearchAutocomplete
                active={active}
                found_extensions={found_extensions}
                handleAction={(extension: string | null) => {
                    if (!!extension) {
                        setExtensionsActiveState(extension, true);
                        handleFormReset();
                    } else { setFoundExtensions(null); }
                }} />
        }
        {resultBadges()}
    </Form>;
}

export default React.memo(SearchExtensionFilterComponent);

