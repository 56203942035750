import * as React from "react";
import WebViewer, { WebViewerInstance, Core } from "@pdftron/webviewer";
import { useEffect, useRef, useState } from "react";
import { AppConfiguration, DriveFileType, PDFNETJS_WEBVIEWER_OPTIONS } from "src/common";

type AllProps = {
  file: File;
  type: string;
  is_view_only: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function FileViewerComponent(props: AllProps) {
  const { file, type, is_view_only, setLoading } = props;
  const [instance, setInstance] = useState<WebViewerInstance | undefined>(undefined);
  const viewer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const disabledElements = is_view_only ? ["thumbExtract", "saveAsButton", "extractPage"] : [];
    const options = { ...PDFNETJS_WEBVIEWER_OPTIONS, disabledElements };
    WebViewer(options, viewer.current as HTMLDivElement).then(instance => {
      AppConfiguration.buildForWeb() && instance.Core.disableEmbeddedJavaScript();
      const Feature = instance.UI.Feature;
      // disable the things we don't use at all
      const features_to_disable = [
        Feature.Annotations,
        Feature.Download,
        Feature.FilePicker,
        Feature.LocalStorage,
        Feature.NotesPanel,
        Feature.Redaction,
        Feature.MultipleViewerMerging,
        Feature.ThumbnailMerging,
        Feature.Measurement,
      ];
      // disable more if its view only
      if (is_view_only) {
        features_to_disable.push(Feature.Print, Feature.Copy, Feature.TextSelection);
      }
      instance.UI.disableFeatures(features_to_disable);
      instance.UI.enableElements(["attachmentPanelButton"]);

      // default to dark theme?
      instance.UI.setTheme("dark");

      setInstance(instance);

      if (
        file.name.toLowerCase().endsWith(".tif") ||
        file.name.toLowerCase().endsWith(".tiff")
      ) {
        return _loadTIFF(instance);
      }
      _loadDocument(instance, file);
    });
  }, []);

  useEffect(() => {
    return () => {
      instance?.Core.documentViewer.dispose();
    };
  }, [instance]);

  function _wvDocumentLoadedHandler(): void {
    const LayoutMode = !!instance ? instance.UI.LayoutMode : null;

    switch (type) {
      case DriveFileType.Word:
      case DriveFileType.PDF:
        break;
      case DriveFileType.PowerPoint:
      case DriveFileType.Excel:
        (!!LayoutMode && !!instance) && instance.UI.setLayoutMode(LayoutMode.Single);
        break;
    }

    setLoading(false);
  }

  function _loadDocument(instance: WebViewerInstance, doc: Blob | Core.PDFNet.PDFDoc) {
    instance.UI.loadDocument(doc);
    instance.Core.documentViewer.addEventListener("documentLoaded", _wvDocumentLoadedHandler);
  }

  async function _loadTIFF(instance: WebViewerInstance) {
    const PDFNet = instance.Core.PDFNet;
    await PDFNet.initialize();
    const data = await new Response(file).arrayBuffer();
    const filter = await PDFNet.Filter.createFromMemory(data);
    const doc = await PDFNet.PDFDoc.create();
    doc.initSecurityHandler();
    doc.lock();
    await PDFNet.Convert.fromTiff(doc, filter);
    doc.unlock();
    _loadDocument(instance, doc);
  }

  return (
    <>
      <div className="viewer" ref={viewer}></div>
      <div id="pdf-marking">
        PDF technology powered by PDFTron SDK copyright © PDFTronTM Systems Inc., 2001-2024, and
        distributed by PreVeil under license. All rights reserved.
      </div>
    </>
  );
}

export default React.memo(FileViewerComponent);
