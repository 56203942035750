import React, { useState, useEffect, FocusEvent } from "react";
import { Form, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ActionHandlerFunction, AccountType } from "@preveil-api";
import { AccountUIActionTypes, RegexHelper, usePostUsersTypeMutation, account_types, AccountErrorMessages, PublicRoutes, QueryParamKeys } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  user_id?: string;
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { user_id, handleAction } = props;
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [_user_id, setUserId] = useState<string>(user_id || "");
  const [getAccountType] = usePostUsersTypeMutation();

  // Description: Onload trigger the validation of user redirect if needed
  useEffect(() => {
    user_id && handleUserValidation(user_id);
  }, []);

  // Description: Do an account type check on load and on submitt
  function handleUserValidation(userId: string, submit: boolean = false) {
    getAccountType({ userId }).unwrap()
      .then((params: { account_type: AccountType }) => {
        const account_type = params.account_type;
        const qs = `?${QueryParamKeys.USER_ID_QUERY_KEY}=${encodeURIComponent(userId)}`;
        submit && account_type === account_types.full ?
          handleAction({ actionType: AccountUIActionTypes.InitWebsocketConnection, params: { user_id: userId } }) :
          account_type === account_types.express ?
            navigate(`/${PublicRoutes.login_route}${qs}`) :
            account_type === account_types.nouser &&
            navigate(`/${PublicRoutes.create_account_route}${qs}`);
      })
      .catch((stack) => {
        handleError(AccountErrorMessages.default, stack);
      });
  }

  // Description: Submit User ID form
  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    e.stopPropagation();
    // TODO - to Fix: Not getting currentTarget property from the Event when in testing environment
    // currentTarget returns undefined.
    // https://github.com/testing-library/dom-testing-library/issues/73
    const userId = (e.currentTarget?.user_id?.value || _user_id).trim().toLowerCase();
    if (isValid(userId)) { // Validate email in form
      handleUserValidation(userId, true);
      setValidated(true);
    } else {
      handleError(AccountErrorMessages.account_user_id_error);
    };
  };

  // Description: Send error up and reset form
  function handleError(message: string, stack?: any) {
    setUserId("");
    setValidated(false);
    handleAction({
      actionType: AccountUIActionTypes.PageErrorMessage,
      params: { message, stack }
    });
  };

  // Description: is form valid
  function isValid(email: string): boolean {
    return RegexHelper.testEmailAddress(email);
  };

  return <Form noValidate validated={validated} onSubmit={handleSubmit} >
    <Form.Group as={Col}>
      <Form.Label htmlFor="email_tb">PreVeil ID (your email address)</Form.Label>
      <div className="input-icon-prepend">
        <Icon className="ficon-user" />
        <Form.Control
          id="email_tb"
          required
          type="email"
          placeholder="Enter your email address"
          name="user_id"
          autoComplete="off"
          autoFocus={true}
          value={_user_id}
          onChange={(e: FocusEvent<HTMLInputElement>) => setUserId(e.currentTarget.value)} />
      </div>
    </Form.Group>
    <div className="btn-panel my-3">
      <Button type="submit" disabled={!_user_id || !isValid(_user_id)}>Continue</Button>
    </div>
  </Form>;
}

export default React.memo(DefaultPanelComponent);
