import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { IActionHandler, AccountType } from "@preveil-api";
import {
  GlobalErrorMessages, MessageAnchors, QueryParamKeys, normalizeQueryUserId, useAppDispatch, Message, MessageHandlerDisplayType,
  useAppSelector, LocalAccountStorage, Account, AccountIdentifiers, account_types, LoginWebSteps, AccountErrorMessages, AuthenticationConstants,
  useVerifyAppStatus, verifyIfDriveLink
} from "src/common";
import { Loading, useAuthContext, ErrorMessage, ProgressCircles } from "src/components";
import { LoginEmailForm, LoginPasswordForm, LoginSMSCodeVerificationForm, LoginTOTPCodeVerificationForm } from ".";
import { RootState } from "src/store/configureStore";
import { uiActions, accountActions } from "src/store";

function LoginWebComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginComplete, redirectPath } = useAuthContext();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const account_ids = useAppSelector((state: RootState) => state.account.account_ids);
  const redirect_url = useAppSelector((state: RootState) => state.ui.redirect_url);
  const step = useAppSelector((state: RootState) => state.account.status);
  const [user_id, setUserId] = useState<string>();
  const [password, setPassword] = useState<string>();
  const dispatch = useAppDispatch();

  const { verifyDesktopAppIsRunning } = useVerifyAppStatus();

  // Description: load LoginEmailForm (First onload event)
  useEffect(() => {
    const storage = new LocalAccountStorage();
    (!!storage.pv_account) ?
      handleGetAccounts(storage) : handleInitWebLogin();
    return () => { // On unmount destroy the KSS store info
      dispatch(accountActions.destroyAccountFormStates());
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  // Description: Complete step after KSS User creds
  useEffect(() => {
    !!account_ids && dispatch(accountActions.findCollectionServerCurrentUser(account_ids));
  }, [account_ids]);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    const session = LocalAccountStorage.getStorageItem(AuthenticationConstants.ACCOUNT_TYPE_SESSION_KEY);
    const account_type = !!session ? session as AccountType : account_types.express;
    (!!current_account || !!redirect_url) &&
      loginComplete(current_account, account_type, redirect_url);
  }, [current_account, redirect_url]);

  /* Description:  Initialize web login following steps above
  * Handles get _user_id from QS or session then gets accounts
  * LOGIN LOGIC AND STEPS:
  * 1. GET user_id from QS - Validate email address -> Check Type on Step 2 (password form loading) 
  * *   - redirect to choose account if Full - SET MODE TO account_types.express
  * *   - set user_id and continue login if express - SET MODE TO account_types.express
  * *   - redirect to create account if null
  * 2. Set Step 1 if no query user and continue normal login flow
  */
  async function handleInitWebLogin() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    query_user_id && setUserId(query_user_id);
    // Note: if the redirect path is a drive link, check if the desktop app is running.
    // If it is, redirect to the desktop app. If Desktop app is not running it will return to login page. (Submit Email)
    const is_drive_link = verifyIfDriveLink(redirectPath);
    const is_desktop_app_running = !!is_drive_link && await verifyDesktopAppIsRunning();
    if (!!is_desktop_app_running) {
      window.location.href = `${process.env.REACT_APP_PORT}${redirectPath}`;
      return;
    } 
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_EMAIL));
  }

  // Handle Login from sessions
  function handleGetAccounts(storage: LocalAccountStorage) {
    Account.getExpressAccountIdentifiersFromSessions(storage)
      .then((account_identifier: AccountIdentifiers | null) => {
        !!account_identifier ? dispatch(accountActions.findCollectionServerCurrentUser(account_identifier)) :
          LocalAccountStorage.destroyLocalStorageSessions();
      });
  }

  // Description: Handle all children component actions and store it
  const LoginRequests = {
    // Description: Already validated email 
    handleSubmitEmail: (user_email: string) => {
      setUserId(user_email);
      dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_PASSWORD));  // setStep(2);
    },
    // Description: Set password or Resend from Verification form
    // Note: for Resend SMS verification click  (pass = undefined)
    handleSubmitPassword: (pass: string) => {
      const _password = !!pass ? pass : password;
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!_password && !!user_id) {
        dispatch(accountActions.getKSSUser({ user_id, password: _password }));
        setPassword(_password);
      } else {
        LoginRequests.handlePageError(AccountErrorMessages.login_submit_password);
      }
    },
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!user_id && !!password && !!sms_code) {
        dispatch(accountActions.getKSSUser({ user_id, password, sms_code }));
      } else {
        LoginRequests.handlePageError(AccountErrorMessages.bad_sms_code);
      }
    },
    handleSubmitTOTPVerificationCode: (totp_code: string) => {
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!user_id && !!password && !!totp_code) {
        dispatch(accountActions.getKSSUser({ user_id, password, totp_code }));
      } else {
        LoginRequests.handlePageError(AccountErrorMessages.bad_totp_code);
      }
    },
    handleSubmitVerificationCodeError: (message: string) => { // WORKING
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    // Description: Reset all form states and go back to first step
    handleResetLogin: () => {
      setUserId(undefined);
      setPassword(undefined);
      dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_EMAIL));// setStep(1);
    },
    handlePageError: (message: string) => {
      LoginRequests.handleResetLogin();
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((LoginRequests as any)[callback] instanceof Function) {
      (LoginRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      LoginRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  const stepDisplayNumbers: { [key: number]: number } = {
    [LoginWebSteps.SUBMIT_EMAIL]: 1,
    [LoginWebSteps.SUBMIT_PASSWORD]: 2,
    [LoginWebSteps.SUBMIT_SMS_CODE]: 3,
    [LoginWebSteps.SUBMIT_TOTP_CODE]: 3,
  };

  // Description: Render the correct form
  function LoginForms() {
    switch (step) {
      case 1:
        return <LoginEmailForm handleAction={handlePageActions} user_id={user_id}><>
          <h3 className="border-bottom">Login to Your Account</h3>
          <p>Please enter your <b>PreVeil Express user ID</b>, this is also your regular email address.</p>
          </>
        </LoginEmailForm>;
      case 2:
        return !!user_id ?
          <LoginPasswordForm handleAction={handlePageActions} user_id={user_id} is_login={true}>
            <h3 className="border-bottom">Enter your PreVeil Express password</h3>
          </LoginPasswordForm> :
          <ErrorMessage message={GlobalErrorMessages.default} />;
      case 3:
        return <LoginSMSCodeVerificationForm handleAction={handlePageActions} />;
      case 4:
        return <LoginTOTPCodeVerificationForm handleAction={handlePageActions} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  return step > 0 ? <Row className="justify-content-center">
    <Col md={7} className="max-500 mt-5">
      <Card>
        <ProgressCircles total_steps={4} current_step={stepDisplayNumbers[step]}/>
        <Card.Body className="p-3">
          <LoginForms />
        </Card.Body>
      </Card>
    </Col>
  </Row> : <Loading />;
};

export default React.memo(LoginWebComponent);
