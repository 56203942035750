import React, { useRef } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { AccountErrorMessages, CreateAccountSteps, CreateAccountUIActionTypes, GlobalConstants, Message, useAppDispatch } from "src/common";
import { VerificationCode, QRCode } from "src/components";
import { uiActions } from "src/store";
import { authenticator } from "otplib";

type AllProps = {
  user_id: string;
  totp_secret: string;
  handleAction: ActionHandlerFunction;
}

function TOTPFormComponent(props: AllProps) {
  const { user_id, totp_secret, handleAction } = props;
  const dispatch = useAppDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const otp_uri = authenticator.keyuri(user_id, "PreVeil", totp_secret);
  let totp_code = "";
  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const valid_code = authenticator.check(totp_code, totp_secret);
    if (valid_code) {
      handleAction({ actionType: CreateAccountUIActionTypes.SubmitTOTPVerificationCode });
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_totp_code)));
    }
  }

  // Description: Validate and set secret code on finished
  function handleVerifyCode(code: string) {
    totp_code = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  // Description: Render the enter code
  function VerificationForm() {
    return <>
      <h3 className="border-bottom">Time based One-time Password (TOTP) Setup</h3>
      <p>Scan the Quick Response (QR) code below with an authenticator application.</p>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="mb-3" >
          <div className="totp-code">
            <QRCode code={otp_uri} width={150} />
          </div>
          <Form.Label>Enter the generated TOTP code below and submit it to PreVeil.</Form.Label>
          <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleVerifyCode} />
          <Form.Text>
            <details>
              <summary>Unable to scan the QR code?</summary>
              <p>
                If you are unable to scan the code in your authenticator application then enter the following
                secret in the application to get your TOTP code. Then submit the code to PreVeil in the step above
              </p>
              <p className="mb-5"><b>{totp_secret}</b></p>
            </details>
          </Form.Text>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" ref={buttonRef} disabled={true} >Verify</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ChangeStep, params: CreateAccountSteps.PICK_2FA_METHOD })}>Back</Button>
        </div>
      </Form>
    </>;
  }

  return <>
    <Card.Body>
      <VerificationForm />
    </Card.Body>
  </>;
}
export default React.memo(TOTPFormComponent);
