import React, { useState, FormEvent, FocusEvent } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { Modal, Button, Form } from "react-bootstrap";
import { UIManagerActionTypes, MailUIActionTypes, Regex_Patterns, TreeModalActions } from "src/common";

type AllProps = {
  modalAction?: TreeModalActions;
  show: boolean;
  handleAction: ActionHandlerFunction;
}

function MailboxFormModalComponent(props: AllProps) {
  const { modalAction, show, handleAction } = props;
  const [folder_name, setFolderName] = useState<string>("");
  const type = modalAction?.type || UIManagerActionTypes.TreeNodeAddChild;
  const current_name = modalAction?.selected?.name || null;

  // Description: Handle form submit
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (isValid()) {
      setFolderName("");
      handleAction({
        actionType: MailUIActionTypes.SubmitMailboxName,
        params: Object.assign({}, modalAction, { new_name: form.folder_name.value.trim() })
      });
    }
  };

  // Description: Close modal and reset form
  function handleCloseReset() {
    setFolderName("");
    handleAction({ actionType: UIManagerActionTypes.CloseModal });
  }
  function isValid(): boolean {
    return Regex_Patterns.MAILBOX_NAME_REGEX.test(folder_name) && folder_name.trim().length > 0;
  }

  return <Modal
    size="sm"
    show={show}
    onHide={handleCloseReset}
    aria-labelledby="Create New Mail Folder"
    centered>
    <Modal.Header>
      <Modal.Title>
        {
          type === UIManagerActionTypes.TreeNodeRename ?
            `Rename  Mail Folder: "${current_name}"` : "Create Mail Folder"
        }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form noValidate onSubmit={handleSubmit} >
        <Form.Group className="mb-3">
          <Form.Label htmlFor="folder_name_tb" visuallyHidden>Folder Name</Form.Label>
          <Form.Control
            id="folder_name_tb"
            autoFocus={true}
            autoComplete="off"
            aria-describedby="folderNameInput"
            placeholder="Folder Name"
            name="folder_name"
            value={folder_name}
            onChange={(e: FocusEvent<HTMLInputElement>) => setFolderName(e.currentTarget.value)}
            className={(!isValid() && folder_name.length > 0) ? "is-invalid" : ""} />
          <Form.Text className={`ms-2 ${(!isValid() && folder_name.length > 0) ? "invalid-feedback" : ""}`}>
            Enter mail folder name, using only numbers or letters.
          </Form.Text>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" size="sm" disabled={(!isValid() && folder_name.length > 0) || !folder_name} >Save Mail Folder</Button>
          <Button variant="outline-secondary" size="sm" type="button"
            onClick={handleCloseReset}>Cancel</Button>
        </div>
      </Form>
    </Modal.Body>
  </Modal >;
}

export default React.memo(MailboxFormModalComponent);
