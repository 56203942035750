import React, { useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, FormValidationLimits, Country, Countries, LocationService, DefaultCountry, dayjs, CreateAccountSteps } from "src/common";
import { SelectCountry } from "src/components";
import { Formik, Field } from "formik";
import * as yup from "yup";


type AllProps = {
  handleAction: ActionHandlerFunction;
}
const locationService = new LocationService();
function PhoneFormComponent(props: AllProps) {
  const { handleAction } = props;
  const [countryNumberCode, setCountryNumberCode] = useState<string>("1"); // setCountryCode - default to US
  const [phoneMask, setPhoneMask] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>(locationService._phoneTemplate);
  const schema = yup.object().shape({
    phone_number: yup.string()
      .required()
      .min(
        FormValidationLimits.PHONE_MIN_LENGTH,
        `Phone must contain ${FormValidationLimits.PHONE_MIN_LENGTH} or more numbers.`
      )
      .max(
        FormValidationLimits.PHONE_MAX_LENGTH,
        `Phone must contain no more than ${FormValidationLimits.PHONE_MAX_LENGTH} numbers.`
      )
      .test("phoneValidator", "Oops! something is wrong with this phone number.",
        (value?: string) => (!!value && locationService.isPhoneNumberValid(value)))
  });

  // Description: Handle form submit
  function handleSubmit(formValues: { phone_number: string }) {
    const phone_number = locationService.phoneNumberE164format(formValues.phone_number);
    handleAction({
      actionType: CreateAccountUIActionTypes.SubmitPhone,
      params: { expires: dayjs().add(15, "minutes"), phone_number }
    });
  }

  // Description: Handle change on the select country dropdown
  function handleSelectedCountry(country: Country) {
    locationService.setSelectedCountryByAbb(country.country_abb);
    setCountryNumberCode(country.country_number_code);
    setPlaceholder(locationService.phonePlaceholder);
  }

  // Description: Handle on change of selected country
  function handleOnPhoneChange(e: React.KeyboardEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const _phoneMask = (phoneMask.length < value.length) ? locationService.phoneMaskChange(value) : value;
    setPhoneMask(_phoneMask);
  }

  return <>
    <Card.Body>
      <h3 className="border-bottom">Enter mobile phone number</h3>
      <p>We will use it to send you a one-time text message with a verification code.</p>
      <p>You will need the code to login to PreVeil Express.</p>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        initialValues={{ phone_number: "" }} >
        {({ dirty, handleChange, handleSubmit, handleBlur, isValid }) => (
          <Form onSubmit={handleSubmit} className="phone-group">
            <Form.Label>Mobile Phone Number</Form.Label>
            <Row className="mb-3 g-2 align-items-center">
              <Form.Group as={Col} xs="auto" >
                <SelectCountry options={Countries}
                  default_country={DefaultCountry}
                  handleAction={handleSelectedCountry} />
              </Form.Group>
              <Col xs="auto" className="phone-code">{countryNumberCode} -</Col>
              <Form.Group as={Col} sm={6} xs={8}>
                <Field type="phone"
                  className="form-control"
                  name="phone_number"
                  aria-describedby="phoneInput"
                  placeholder={placeholder}
                  value={phoneMask}
                  onBlur={handleBlur}
                  onChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    handleOnPhoneChange(e);
                    handleChange(e); // Needs to be here for validation
                  }}
                />
              </Form.Group>
            </Row>
            <div className="btn-panel">
              <Button type="submit" disabled={!dirty || !isValid}>Send Verification Code</Button>
              <Button variant="outline-secondary" type="button"
                onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ChangeStep, params: CreateAccountSteps.PICK_2FA_METHOD })}>Back</Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card.Body>
  </>;
}

export default React.memo(PhoneFormComponent);
