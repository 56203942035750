/*
* Description: Collection API endpoints for sagas
* Author:      PreVeil, LLC
*/
import {
  IFetchResponse, CollectionServerUser, UserIdentifierBase, CollectionServerExpressUser, JSONKeyHistory,
  CollectionThreadsPage, JSONBlock, JSONResponseBlock, CollectionServerFindUsers, GetThreadsParams, ApproversList, LogKey
} from "@preveil-api";
import {
  GetThreadApiArg, UpdateFlagsApiArg, UpdateFlagsDataApi, PosMessagesCopyApiArg, PostMessagesCopyApiResponse, CopyMailDataApi,
  MoveMailApiArg, MoveMailDataApi, GetUsersOrgsByEntityIdGroupsAndGroupIdApiArg, PutUsersCollectionGrantApiArg, GetUsersLogKeysApiResponse,
  PostUsersOrgsByEntityIdSubmitExportShardsApiArg, PutUsersOrgsByEntityIdRequestsAndRequestIdApiArg, GetUsersEventsApiArg,
  GetUsersEventsApiResponse, PutUsersEventsByEventIdApiArg, DeleteMailByColIdMbidMessIdApiArg
} from "./collection-rtk-api";
import {
  AccountIdentifiers, Account, KeyStorageUser, BaseAPI, ApiMethods, Helpers, mergeUint8Arrays, parseGetWebThreads,
  parseGetWebThread, parseGetAppThread, CURRENT_COLLECTION_API_VERSION
} from "src/common";
import _ from "lodash";


const base_url = `${process.env.REACT_APP_BACKEND_SERVER}`;
const default_headers: Record<string, string> = {
  "Content-Type": "application/json",
  "accept-version": CURRENT_COLLECTION_API_VERSION
};
// --------------------------------------------------------------------------------------------
// Saga calls
// --------------------------------------------------------------------------------------------
/*
 * Description: Get Users from Collection server
 * Request: POST;
 *  @params: user_identifiers = an array of user_ids 
 *            OR IF undefined then find current users account
 * Response: { ... } // legacy getUsers
 * NOTES:     PARSE Response returned as CollectionServerUser to  LocalUser before init account
 *            If findCurrentUser then pass keys from AccountIdentifiers 
 * https://github.com/PreVeil/core/blob/dev/collection_server/docs/users.md#post-usersfind
 */
const postFindUsers = async (account_ids: AccountIdentifiers, user_identifiers?: UserIdentifierBase[], return_raw_users: boolean = false): Promise<IFetchResponse> => {
  const is_current_account = !user_identifiers;
  const params = { spec: is_current_account ? [{ user_id: account_ids.user_id }] : user_identifiers };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, "/users/find", ApiMethods.post, params);
  return BaseAPI.post(`${base_url}/users/find`, params, headers)
    .then(async (response: IFetchResponse) => {
      if (response.isError || return_raw_users) { return response; }
      const users: CollectionServerUser[] = (response.data as CollectionServerFindUsers).users;
      return (!!users && Array.isArray(users) && users.length > 0) ?
        {
          ...response, data: {
            users: await Promise.all(users.map(async (user: CollectionServerUser) => {
              return is_current_account ?
                await Account.initAccount(Account.parseCollectionServerUser(user, account_ids), account_ids.user_key, account_ids.device_key) :
                await Account.initAccount(Account.parseCollectionServerUser(user));
            }))
          }
        } : response;
    });
}

// Description: Create and claim Express account - final step
const claimExpressAccount = async (keystorage_user: KeyStorageUser): Promise<IFetchResponse> => {
  const express_user = keystorage_user.getJSONExpressAccount();
  const account_ids: AccountIdentifiers = {
    user_id: keystorage_user.user_id,
    user_key: keystorage_user.user_key,
    device_id: keystorage_user.device.device_id,
    device_key: keystorage_user.device_key,
    phone_number: keystorage_user.phone_number
  }

  return BaseAPI.put(`${base_url}/users`, JSON.stringify(express_user), default_headers)
    .then(async (response: IFetchResponse) => {
      if (response.isError) { return response; }
      const user: CollectionServerExpressUser = response.data;
      return !!user ? {
        ...response, data: {
          current_account:
            await Account.initAccount(Account.parseCollectionServerExpressUser(user, account_ids, keystorage_user.public_keys.public_key),
              account_ids.user_key, account_ids.device_key)
        }
      } : response;
    })
}

// Description: Get key history for decrypting with different user key versions
const getKeyHistory = async (account_ids: AccountIdentifiers): Promise<IFetchResponse> => {
  const params = { user_id: account_ids.user_id };
  const current_key = account_ids.user_key;
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, "/users/key_history", "GET", null);
  return BaseAPI.get(`${base_url}/users/key_history`, params, headers)
    .then(async (response: IFetchResponse) => {
      if (response.isError) { return response; }
      const data = response.data as JSONKeyHistory;
      return { ...response, data: await Account.initKeyHistory(current_key, data.key_history) };
    });
}

// Description: Create Email Signature
const createEmailSignature = async (account_ids: AccountIdentifiers, val: string): Promise<IFetchResponse> => {
  const params = { user_id: account_ids.user_id, key: "email-signature", val: val };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, "/users/keyval/", "PUT", params);
  return BaseAPI.put(`${base_url}/users/keyval/`, params, headers);
}

// Description: Get Email Signature
const getEmailSignature = async (account_ids: AccountIdentifiers): Promise<IFetchResponse> => {
  const params = { user_id: account_ids.user_id, key: "email-signature" };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, "/users/keyval/", "GET", null);
  return BaseAPI.get(`${base_url}/users/keyval/`, params, headers);
}


// Description: Get Account Backup
const getAccountBackup = async (account_ids: AccountIdentifiers): Promise<IFetchResponse> => {
  const params = { user_id: account_ids.user_id };
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, "/users/backup", "GET", null));
  return BaseAPI.get(`${base_url}/users/backup`, params, headers);
}

// Description: GET Organization Information for org users
const getOrganizationInfo = async (account_ids: AccountIdentifiers, org_id: string): Promise<IFetchResponse> => {
  const url = `/users/orgs/${org_id}`;
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, "GET", null);
  return BaseAPI.get(`${base_url}${url}`, null, headers)
    .then(response => {
      const data = Object.assign(response.data, {
        id: org_id
        // NOTE: REMOVED THE  org_info.admin_group_id.String() since it is not a UUID in JSONOrgInfo
      });
      return { ...response, data };
    })
}

// Description: GET Mail Paginated threads by collID and MailboxId
// Returns:       Process returned data int UI models
const getMailThreads = async (current_account: Account, params: GetThreadsParams): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  const url = `/mail/${params.collection_id}/mailboxes/${params.mailbox_id}/threads`;
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, null);
  return BaseAPI.get(`${base_url}${url}`, { limit: params.limit, offset: params.offset, fetch_expired: params.fetch_expired || false }, headers)
    .then(async (response: IFetchResponse) => {
      const data = response.data as CollectionThreadsPage;
      try {
        return (!!data.threads && Array.isArray(data.threads) && data.threads.length > 0) ?
          { ...response, data: await parseGetWebThreads(data, current_account, params.mailbox_id) } : response;
      } catch (error: unknown) {
        return { ...response, isError: true, data: { response, error } };
      }
    })
}

// Description: GET Mail thread by threadid
const getMailThread = async (current_account: Account, params: GetThreadApiArg, isWeb: boolean, mailbox_id: string): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  const url = `/mail/${params.collectionId}/threads/${params.tid}`;
  const body = { since_rev_id: 0 };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, null);
  return BaseAPI.get(`${base_url}${url}`, body, headers)
    .then(async (response: IFetchResponse) => {
      const _data = {
        thread_id: params.tid,
        ...response.data
      };
      try {
        // NOTE: If web decrypt 
        const data = isWeb ? await parseGetWebThread(_data, current_account, mailbox_id) : parseGetAppThread(_data);
        return (!!data && (Array.isArray(data.messages) || Array.isArray(data.encrypted_messages))) ?
          { ...response, data } : { ...response, isError: true, data: response.data };
      } catch (error: unknown) {
        return { ...response, isError: true, data: { response, error } };
      }
    })
}

// Description: UPDATE FLAGS - Legacy updateFlags() - Pass an array of message with different mailbox_ids
// Returns: 1 UpdateFlagsApiResponse with an array of data to monitor errors at top level
const updateFlags = async (current_account: Account, params_arr: UpdateFlagsApiArg[]): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  return await Promise.all(_.map(params_arr, async (params: UpdateFlagsApiArg) => {
    const updates = { updates: params.updates };
    const url = `/mail/${params.collectionId}/mailboxes/${params.mbid}/messages`;
    const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.patch, updates);
    return await BaseAPI.patch(`${base_url}${url}`, updates, headers);
  }))
    .then((responses: IFetchResponse[]) => { // return only one response with error if any
      const data = _.flatten(_.map(responses, (resp) => (resp.data.data as UpdateFlagsDataApi)));
      const isError = _.filter(data, (_data: UpdateFlagsDataApi) => (_data.error !== "no_error")).length > 0;
      return { ...responses[0], isError, data };
    });
}

// Description: Copy a message to another mailbox - for deleting messages 
const copyMailThreads = async (current_account: Account, params: PosMessagesCopyApiArg): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  const url = `/mail/${params.collectionId}/mailboxes/${params.mbid}/messages/copy`;
  const update = { uids: params.uids, source_mbid: params.source_mbid };
  const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.post, update);
  return BaseAPI.post(`${base_url}${url}`, update, headers)
    .then((response: IFetchResponse) => {
      const data = response.data as PostMessagesCopyApiResponse;
      const results = data.data.result;
      const isError = _.filter(results, (result: CopyMailDataApi) => (result.error !== "no_error")).length > 0;
      return { ...response, isError, data: results };
    })
}

// Description: Move Mail one item per call so wait for batches 
const moveMail = async (current_account: Account, params_arr: MoveMailApiArg[]): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  const responses: IFetchResponse[] = [];
  for (const params of params_arr) {
    const url = `/mail/${params.collectionId}/threads/${params.tid}`;
    const body = { source_mailbox: params.source_mailbox, dest_mailbox: params.dest_mailbox };
    const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.put, body);
    responses.push(await BaseAPI.put(`${base_url}${url}`, body, headers));
  }
  // NOTE: if error the messages will be empty and data length will be less than the total number of responses
  const data = _.flatten(_.map(responses, (resp) => (resp.data.messages as MoveMailDataApi[])));
  const isError = data.length < responses.length;
  return { ...responses[0], isError, data };
}

// Deletes a message from mailbox (used for deleting drafts and trash mail)
const deleteMailByColIdMbidMessId = async (current_account: Account, params_arr: DeleteMailByColIdMbidMessIdApiArg[]): Promise<IFetchResponse> => {
  const account_ids = Account.getAccountIdentifiers(current_account);
  return await Promise.all(_.map(params_arr, async (params: DeleteMailByColIdMbidMessIdApiArg) => {
    const url = `/mail/${params.collectionId}/mailboxes/${params.mbid}/messages/${params.id}`;
    const headers = await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.delete, null);
    return await BaseAPI.axiosDelete(`${base_url}${url}`, null, headers);
  })).then((responses: IFetchResponse[]) => {
    // NOTE: if error the messages will be empty and data length will be less than the total number of responses
    const data = _.flatten(_.map(responses, (resp) => (resp.data.messages)));
    const isError = data.length < responses.length;
    return { ...responses[0], isError, data };
  });
}

// Description: Respond to user approval request
const putUsersOrgsByEntityIdRequestsAndRequestId = async (
  account_ids: AccountIdentifiers,
  params: PutUsersOrgsByEntityIdRequestsAndRequestIdApiArg
): Promise<IFetchResponse> => {
  const url = `/users/orgs/${params.entityId}/requests/${params.requestId}`;
  return BaseAPI.prepareSignedPayload(account_ids, params.payload).then(async signature => {
    const body = { ...params.body, signature };
    const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.put, body));
    return BaseAPI.put(`${base_url}${url}`, body, headers);
  });
}

// Description: Provides backend with a list of export shard secrets
const postUsersOrgsByEntityIdSubmitExportShards = async (
  account_ids: AccountIdentifiers,
  params: PostUsersOrgsByEntityIdSubmitExportShardsApiArg
): Promise<IFetchResponse> => {
  const url = `/users/orgs/${params.entityId}/submit_export_shards`;
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.post, params.body));
  return BaseAPI.post(`${base_url}${url}`, params.body, headers);
}

// Description: Get the list of log keys for all user collections (includes mail, default, etc.)
const getUsersLogKeys = async (account_ids: AccountIdentifiers): Promise<IFetchResponse> => {
  const url = "/users/log_keys";
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, null));
  return BaseAPI.get(`${base_url}${url}`, { user_id: account_ids.user_id }, headers)
    .then(response => {
      const data = response.data.log_keys as LogKey[];
      const isError = data.length === 0;
      return { ...response, isError, data };
    });
}

// Description: Gives the specified group (typically the admin group) access to the logs for a particular collection
const putUsersCollectionGrant = async (account_ids: AccountIdentifiers, params: PutUsersCollectionGrantApiArg): Promise<IFetchResponse> => {
  const url = "/users/collection/grant";
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.put, params.body));
  return BaseAPI.put(`${base_url}${url}`, params.body, headers)
    .then(response => {
      const data = response.data as GetUsersLogKeysApiResponse;
      return { ...response, data };
    });
}

// Description: Get the approval group given the group id and version for a specific organization
const getUsersOrgsByEntityIdGroupsAndGroupId = async (
  account_ids: AccountIdentifiers,
  params: GetUsersOrgsByEntityIdGroupsAndGroupIdApiArg
): Promise<IFetchResponse> => {
  const url = `/users/orgs/${params.entityId}/groups/${params.groupId}`;
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, null));
  return BaseAPI.get(`${base_url}${url}`, { version: params.version }, headers)
    .then(response => {
      const data = response.data as ApproversList;
      return { ...response, data };
    });
}

// Description: Get the list of events from the user's stream
const getUsersEvents = async (account_ids: AccountIdentifiers, params: GetUsersEventsApiArg): Promise<IFetchResponse> => {
  const url = "/users/events";
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.get, null));
  return BaseAPI.get(`${base_url}${url}`, params, headers)
    .then(response => {
      const data = response.data as GetUsersEventsApiResponse;
      return { ...response, data };
    });
}

// Description: Handles an event in the user's stream. If successful, upon a subsequent call to
// getUserEvents, the particular event will be marked { handled = true }.
const putUsersEventsByEventId = async (account_ids: AccountIdentifiers, params: PutUsersEventsByEventIdApiArg): Promise<IFetchResponse> => {
  const url = `/users/events/${params.event_id}`;
  const headers = Object.assign({}, default_headers, await BaseAPI.prepareSignedRequestHeader(account_ids, url, ApiMethods.put, params.body));
  return BaseAPI.put(`${base_url}${url}`, params.body, headers);
}

export const CollectionAPI = {
  postFindUsers,
  getKeyHistory,
  claimExpressAccount,
  createEmailSignature,
  getEmailSignature,
  getAccountBackup,
  getOrganizationInfo,
  getMailThreads,
  getMailThread,
  updateFlags,
  copyMailThreads,
  moveMail,
  deleteMailByColIdMbidMessId,
  putUsersOrgsByEntityIdRequestsAndRequestId,
  postUsersOrgsByEntityIdSubmitExportShards,
  getUsersLogKeys,
  putUsersCollectionGrant,
  getUsersOrgsByEntityIdGroupsAndGroupId,
  getUsersEvents,
  putUsersEventsByEventId
}

// -------------------------------------------------------------------------------------------------------
// ------------------------------- Additional Direct Fetch to S3
// -------------------------------------------------------------------------------------------------------
// Description: Fetch Message blocks directly from S3
export async function FetchS3MessageBlocks(block_url: string, block_id: string, collection_id: string): Promise<JSONResponseBlock> {
  const concat_blocks: Uint8Array[] = [];
  const response = await fetch(block_url);
  if (response.ok && !!response.body) {
    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      // NOTE: Add to concat_blocks array
      concat_blocks.push(value);
    }
    // NOTE: Create a new array with total length and merge all source arrays.
  } else {
    throw new Error("Failed to retrieve blocks from Direct URL call");
  }
  return {
    collection_id,
    block_id: block_id,
    data: Helpers.b64Encode(mergeUint8Arrays(concat_blocks))
  };
};

// Description: Download Attachment Blocks
export async function FetchS3AttachmentBlocks(block_url: string, block_id: string): Promise<JSONBlock> {
  const concat_blocks: Uint8Array[] = [];
  const response = await fetch(block_url);
  if (response.ok && !!response.body) {
    const reader = response.body.getReader();
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      // NOTE: Add to concat_blocks array
      concat_blocks.push(value);
    }
  } else {
    throw new Error("Failed to retrieve blocks from Direct URL call");
  }

  // NOTE: Create a new array with total length and merge all source arrays.
  return {
    block_id: block_id,
    data: mergeUint8Arrays(concat_blocks)
  };
};
