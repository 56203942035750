import React, { ReactElement, useState, useRef } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { AccountType, ActionHandlerFunction } from "@preveil-api";
import { account_types, useGetUsersTypeQuery, PublicRoutes, LoginWebUIActionTypes, FormValidationLimits, Regex_Patterns, AccountErrorMessages } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  user_id: string;
  children?: ReactElement;
  handleAction: ActionHandlerFunction;
  is_login?: boolean;
}

function LoginPasswordFormComponent(props: AllProps) {
  const { user_id, children, handleAction, is_login } = props;
  const { data } = useGetUsersTypeQuery({ userId: user_id });
  const [show_password, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const schema = yup.object().shape({
    password: yup.string()
      .required(AccountErrorMessages.password_required_error)
      .min(
        FormValidationLimits.PASSWORD_OLD_MIN_LENGTH,
        AccountErrorMessages.password_old_min_length_error
      )
      .matches(Regex_Patterns.OLD_PASSWORD_REGEX,
        AccountErrorMessages.old_password_format_error)
  });

  // Description: Handle form submit 
  function handleSubmit(formValues: { password: string; }) {
    handleAction({ actionType: LoginWebUIActionTypes.SubmitPassword, params: formValues.password });
  }

  // Description: Data has been fetched and this handles returned account type
  function AccountTypeHandler(props: { account_type: AccountType }) {
    const _user_id = user_id ? encodeURIComponent(user_id) : "";
    switch (props.account_type) {
      case account_types.full:
        return <Navigate to={`/${PublicRoutes.connect_account_route}?user_id=${_user_id}`} />;
      default:
        return <Navigate to={`/${PublicRoutes.create_account_route}?user_id=${_user_id}`} />;
    }
  }

  return !!data ? data.account_type === account_types.express ?
    <>
      {children}
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          password: "",
          confirmPassword: ""
        }} >
        {({ dirty, values, errors, touched, handleChange, handleSubmit, handleBlur, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="password_tb" className="fs-6">Password</Form.Label>
              <InputGroup className={`password-form-control ${!!errors.password && touched.password ? "invalid" : ""}`} hasValidation={true}>
                <Icon className="ficon-lock" />
                <Form.Control type={!!show_password ? "text" : "password"}
                  id="password_tb"
                  autoFocus={true}
                  aria-describedby="passwordInput"
                  placeholder="Enter Password"
                  name="password"
                  value={values.password}
                  onBlur={(e) => {
                    if (cancelButtonRef.current && cancelButtonRef.current.contains(e.relatedTarget as Node)) { return; }
                    handleBlur(e);
                  }}
                  onChange={handleChange}
                  isInvalid={!!errors.password && touched.password} />
                <Button
                  variant="transparent"
                  className="btn-icon"
                  size="sm"
                  onClick={() => setShowPassword(!show_password)}
                  aria-label="show password"
                >
                  <span>
                    <Icon className={!!show_password ? "ficon-eye" : "ficon-eye-off"} />
                  </span>
                </Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid" className={`${!!errors.password && touched.password ? "d-block" : ""}`}>
                * {errors.password}</Form.Control.Feedback>
            </Form.Group>
            <div className="btn-panel">
              <Button type="submit" disabled={!dirty || !isValid} >Continue</Button>
              <Button ref={cancelButtonRef} variant="outline-secondary" type="button"
                onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Cancel</Button>
              {
                is_login &&
                <Button variant="link" type="button" className="d-block px-0 fs-6" onMouseDown={(e) => e.preventDefault()}
                  onClick={() => navigate(`/${PublicRoutes.forgot_password_route}?user_id=${encodeURIComponent(user_id)}`)}>Forgot your password?</Button>
              }
            </div>
          </Form>
        )}
      </Formik>
    </> : <AccountTypeHandler account_type={data.account_type} /> : null;
}
export default React.memo(LoginPasswordFormComponent);
