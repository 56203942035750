/*
* Description: Filesync API endpoints: RTK Hooks and types
* Author:      PreVeil, LLC
*/
import { DeviceBase, IFetchResponse } from "@preveil-api";
import { GlobalConstants, BaseAPI, EntryItem, PathInfo, DriveRoot, DriveDirectory, DriveDirectoryPaginated, Helpers, NodePermissionsName, UUID, form_headers, ACLInfo, DriveEntryTypes, SelectiveSyncRulesMap } from "src/common";
import { filesyncServerAPI as api } from "../base/base-api";
import _ from "lodash";

const base_url = `${process.env.REACT_APP_FILESYNC_SERVER}`;
// Description: Sanitize Ids for Filesync
const _getInitializedForm = (user_id: string, identifiers?: Record<string, string>): FormData => {
  const form_data: FormData = new FormData();
  form_data.append("uid", user_id);
  if (!!identifiers) {
    _.forOwn(identifiers, (id, key) => {
      form_data.append(key, Helpers.convertToURLSafeId(id));
    })
  }
  return form_data;
}

// --------------------------------------------------------------------------------------------
// RTK Queries Calls: 
// --------------------------------------------------------------------------------------------
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    // ------------- Browse methods
    browseDrive: build.mutation<DriveRoot, BrowseDriveApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/browse/drive`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as DriveRoot } : { error: result as IFetchResponse };
      }
    }),
    browseDirectoryPaginated: build.mutation<DriveDirectoryPaginated, BrowseDirectoryPaginatedApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("in_trash", JSON.stringify(params.in_trash || false));
        form_data.append("limit", "1000000"); /// NOTE: SET HIGH SO IT will return all for now
        // !!params.limit && form_data.append("limit", params.limit.toString());
        !!params.offset && form_data.append("offset", params.offset.toString());
        const url = `/browse/dir/paginated`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as DriveDirectoryPaginated } : { error: result as IFetchResponse };
      },
      invalidatesTags: ["download"]
    }),
    browseDirectory: build.mutation<DriveDirectory, BrowseDirectoryPaginatedApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("in_trash", JSON.stringify(params.in_trash || false));
        const url = `/browse/dir`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    browseLink: build.mutation<DriveRoot, BrowseLinkApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, linkid: params.link_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/browse/link`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as DriveRoot } : { error: result as IFetchResponse };
      }
    }),
    findLinkId: build.mutation<FindLinkApiResponse, FindLinkApiArg>({
      queryFn: async (params) => {
        const identifiers = { linked_collid: params.collection_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/link/find`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as FindLinkApiResponse } : { error: result as IFetchResponse };
      }
    }),
    browseCollection: build.mutation<DriveRoot, BrowseCollectionApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, linkid: params.link_id }
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/browse/link`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as DriveRoot } : { error: result as IFetchResponse };
      }
    }),
    browseTrash: build.query<BrowseTrashApiResponse, BrowseTrashApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/collections/trash/list`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    // -------------  END OF Browse methods
    getUserLogs: build.query<GetLogsApiResponse, GetUserLogsApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        form_data.append("notifications_only", "false");
        form_data.append("limit", GlobalConstants.ACTIVITY_PER_PAGE_COUNT.toString());
        form_data.append("offset", params.offset.toString());
        form_data.append("is_admin", false.toString());
        if (params.to_index !== undefined) {
          form_data.append("to_index", params.to_index.toString());
        }
        form_data.append("start_time", params.start_time);
        form_data.append("end_time", params.end_time);
        const url = `/logs/get`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError
          ? { data: result.data as GetLogsApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    getAdminLogs: build.query<GetLogsApiResponse, GetAdminLogsApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        form_data.append("notifications_only", "false");
        form_data.append("limit", GlobalConstants.ACTIVITY_PER_PAGE_COUNT.toString());
        form_data.append("offset", params.offset.toString());
        form_data.append("is_admin", true.toString());
        if (params.to_index !== undefined) {
          form_data.append("to_index", params.to_index.toString());
        }
        form_data.append("start_time", params.start_time);
        form_data.append("end_time", params.end_time);
        form_data.append("topic", params.topic);
        if (params.user_ids) {
          params.user_ids.forEach((usr: string | Blob) => form_data.append("user_ids", usr));
        }
        if (params.actions) {
          params.actions.forEach((act: string | Blob) => form_data.append("actions", act));
        }
        const url = `/logs/get`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError
          ? { data: result.data as GetLogsApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    downloadFile: build.mutation<DownloadFileApiResponse, DownloadFileApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, fileid: params.file_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        let url: string = "/browse/file";
        if (!params.for_viewer) {
          // NOTE: Preveil downloads
          form_data.append("name", params.file_name);
          // NOTE: to download a specific file version (under version history)
          !!params.version && form_data.append("version", params.version);
          url = "/download/file";
        }
        const result: IFetchResponse = await BaseAPI.put(
          `${base_url}${url}`,
          form_data,
          form_headers,
          { responseType: "blob" },
        );
        return !result.isError
          ? { data: result.data as DownloadFileApiResponse }
          : { error: result as IFetchResponse };
      }
    }),
    selectiveSync: build.mutation<SelectiveSyncApiResponse, SelectiveSyncApiArg>({
      queryFn: async (params) => {
        const json_data = {
          uid: params.uid,
          syncRulesMap: params.syncRulesMap,
          request_id: params.request_id,
        };

        const form_data = _getInitializedForm(params.uid);
        form_data.append("syncRulesMap", JSON.stringify(params.syncRulesMap));
        const url = `/local/sync/update`;
        const result: IFetchResponse = await BaseAPI.put(
          `${base_url}${url}`,
          json_data,
        );
        return !result.isError
          ? { data: result.data as SelectiveSyncApiResponse }
          : { error: result as IFetchResponse };
      },
    }),
    getSharedWithMe: build.mutation<GetSharedWithMeApiResponse, GetSharedWithMeApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/shared/list`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    shareRespond: build.mutation<ShareRespondApiResponse, ShareRespondApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.coll_id, shareId: params.share_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("accept", params.accept.toString());
        form_data.append("shareName", params.name);
        form_data.append("disableSync", (!params.sync).toString());
        const url = `/share/respond`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    getSharedFolders: build.mutation<GetSharedFoldersApiResponse, GetSharedWithMeApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/collections/shared/list`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    relink: build.mutation<RelinkApiResponse, RelinkApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("name", params.name);
        const url = `/collections/shared/add`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    createDirectory: build.mutation<CreateDirectoryApiResponse, CreateDirectoryApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("name", params.name);
        const url = `/create/dir`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    moveItem: build.mutation<MoveItemApiResponse, MoveItemApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id, eid: params.entity_id, new_parent_id: params.new_dir_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("type", params.type);
        form_data.append("name", params.name);
        const url = `/move`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    checkHandleExePresent: build.mutation<CheckHandleExePresentApiResponse, CheckHandleExePresentApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/edit/prerequisite/handleExe`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    editFile: build.mutation<EditFileApiResponse, EditFileApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, fileid: params.file_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("filename", params.file_name);
        const url = `/edit/session/start`;
        // NOTE: Pass params.request_id in params to subscribe to notification
        !!params.request_id && form_data.append("request_id", params.request_id);
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    // ------------------ Share for App ------------------ //
    // Description: Check if Sync is in progress and block action if true
    // https://github.com/PreVeil/drive/blob/dev/docs/APIs.md#promotedirprecheck
    promotePrecheck: build.mutation<PromotePrecheckApiResponse, PromotePrecheckApiArg>({
      queryFn: async (params) => {
        const identifiers = { dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/promote/dir/precheck`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    promoteDirectory: build.mutation<PromoteDirectoryApiResponse, PromoteDirectoryApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("dirname", params.share_name);
        const url = `/promote/dir`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    upgradeCollection: build.mutation<UpgradeCollectionApiResponse, UpgradeCollectionApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/collection/upgrade`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    share: build.mutation<ShareApiResponse, ShareApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        params.sharing_list.forEach((user) => form_data.append("uids", user));
        form_data.append("collpermission", params.permissions_name);
        form_data.append("collname", params.share_name);
        form_data.append("dirname", params.share_name);
        if (params.expiration) {
          form_data.append("expiration", params.expiration);
        }
        const url = `/share/coll`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    shareV2: build.mutation<ShareV2ApiResponse, ShareV2ApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        params.sharing_list.forEach((user) => form_data.append("uids", user));
        // NOTE: Pass params.request_id in params to subscribe to notification
        !!params.request_id && form_data.append("request_id", params.request_id);
        form_data.append("collpermission", params.permissions_name);
        form_data.append("collname", params.collection_name);
        form_data.append("dirname", params.share_name);
        if (params.expiration) {
          form_data.append("expiration", params.expiration);
        }
        const url = `/share/coll/v2`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
    }),
    getPendingShares: build.mutation<GetPendingSharesApiResponse, GetPendingSharesApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/share/pending/status`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    listFileVersions: build.mutation<ListFileVersionsApiResponse, ListFileVersionsApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, fileid: params.file_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = `/file/versions`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    restoreFileVersion: build.mutation<RestoreFileVersionApiResponse, RestoreFileVersionApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, fileid: params.file_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("version", params.version);
        const url = `/restore/version`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    // https://github.com/PreVeil/drive/blob/83fc5b08b35c154432784a47d20d1b42c04003e2/docs/APIs.md#download_folderinfo
    getDownloadFolderId: build.query<GetDownloadFolderIdApiResponse, GetDownloadFolderIdApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        const url = `/download_folder/info`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      },
      providesTags: ["download"]
    }),
    setSyncState: build.mutation<SetSyncStateApiResponse, SetSyncStateApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        // NOTE: Pass params.request_id in params to subscribe to notification
        !!params.request_id && form_data.append("request_id", params.request_id);
        const url = params.sync_state ? `/local/sync/start` : `/local/sync/stop`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    setLockState: build.mutation<SetLockStateApiResponse, SetLockStateApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.coll_id, eid: params.entity_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        const url = !!params.lock_state ? `/lock` : `/unlock`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    openInFileManager: build.mutation<OpenInFileManagerApiResponse, OpenInFileManagerApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        form_data.append("cdir_name", params.path);
        const url = "/rootdir";
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    getExportProgress: build.mutation<GetExportProgressApiResponse, GetExportProgressApiArg>({
      queryFn: async (params) => {
        const form_data = _getInitializedForm(params.user_id);
        form_data.append("exportid", params.export_id);
        const url = `/export/progress`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    renameItem: build.mutation<RenameItemApiResponse, RenameItemApiArg>({
      queryFn: async (params) => {
        const identifiers = { collid: params.collection_id, dirid: params.directory_id, eid: params.entity_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("type", params.type);
        form_data.append("new_name", params.new_name);
        form_data.append("request_id", params.request_id);
        const url = `/rename`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    downloadAttachment: build.mutation<DownloadAttachmentApiResponse, DownloadAttachmentApiArg>({
      queryFn: async (params) => {
        const identifiers = { uniqueID: params.unique_id, referenceID: params.reference_id };
        const form_data = _getInitializedForm(params.user_id, identifiers);
        form_data.append("name", params.name);
        const url = `/download/attachment`;
        const result: IFetchResponse = await BaseAPI.put(`${base_url}${url}`, form_data, form_headers);
        return !result.isError ? { data: result.data as any } : { error: result as IFetchResponse };
      }
    }),
    // Add more ...
  }),
});

// GENERAL EXPORTS OF TYPES AND HOOKS:
export { injectedRtkApi as filesyncApi };
// ------------------------------------------------------------
// Request and Response types:
// ------------------------------------------------------------
// export type BrowseDriveApiResponse = DriveNodeBase;
export type BrowseDriveApiArg = {
  user_id: string;
};
// export type BrowseDirectoryPaginatedApiResponse = DriveDirectoryPaginated;
export type BrowseDirectoryPaginatedApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  in_trash?: boolean;
  offset?: number;
  limit?: number;
};
// export type BrowseLinkApiResponse = DriveNodeBase;
export type BrowseLinkApiArg = {
  user_id: string;
  collection_id: string;
  link_id: string;
};
export type FindLinkApiArg = {
  user_id: string;
  collection_id: string;
};
export type FindLinkApiResponse = {
  id: string;
  defaultCollectionID: string;
};
// export type BrowseCollectionApiRespons = DriveNodeBase;
export type BrowseCollectionApiArg = {
  user_id: string;
  collection_id: string;
  link_id: string;
};
export type GetLogsApiResponse = {
  log_entries: {
    action: string;
    collection_id: string;
    entry_ts: string;
    global_idx: number;
    idx: number;
    metadata: {
      acl_changes: any[];
      added_data: {
        filename: string,
        is_shared: boolean,
        path: any,
        type: string
      }
      ag_request: any[];
      approval_group: any[];
      devices: DeviceBase[];
      info: {};
      list_info: {};
      message_info: any[];
      nodes: any[];
      updates: any[];
      users: any[];
      version: number;
    };
    notification: boolean;
    priority_level: number;
    topic: string;
    user_info: {
      account_version: number;
      admin: boolean;
      claimed: boolean;
      collection_id: string;
      default_collection_id: string;
      deleted: boolean;
      devices: DeviceBase[];
      display_name: string;
      entity_id: string;
      user_id: string;
      entity_metadata: {
        department: string;
        role: string;
      };
    };
  }[];
};
export type GetUserLogsApiArg = {
  user_id: string;
  offset: number;
  start_time: string;
  end_time: string;
  to_index?: number;
};
export type GetAdminLogsApiArg = {
  user_id: string;
  offset: number;
  start_time: string;
  end_time: string;
  user_ids: string[];
  topic: string;
  actions: string[];
  to_index?: number;
  user?: string;
};
export type DownloadFileApiResponse = Blob;
export type DownloadFileApiArg = {
  user_id: string;
  collection_id: string;
  file_id: string;
  for_viewer?: boolean;
  file_name: string;
  version?: string;
};
export type GetSharedWithMeApiResponse = {
  collid: string;
  expiration?: string;
  name: string;
  permissions: [string];
  shareid: string;
  sharerid: string;
  sync_by_default: boolean;
  has_acl_view_only: boolean;
  acl_roles: [string];
}[];
export type GetSharedWithMeApiArg = {
  user_id: string;
};
export type ShareRespondApiResponse = string; // ex: 'OK'
export type SelectiveSyncApiArg = {
  uid: string;
  syncRulesMap: SelectiveSyncRulesMap
  request_id: string;
};
export type SelectiveSyncApiResponse = string; // ex: 'OK'
export type ShareRespondApiArg = {
  user_id: string;
  coll_id: string;
  share_id: string;
  name: string;
  accept: boolean;
  sync: boolean;
};
export type GetSharedFoldersApiResponse = {
  id: string;
  linked: boolean;
  linkid: string;
  name: string;
  path: PathInfo[]
}[];
export type RelinkApiResponse = string; // ex: "OK"
export type RelinkApiArg = {
  user_id: string;
  collection_id: string;
  name: string;
};
export type CreateDirectoryApiResponse = EntryItem;
export type CreateDirectoryApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  name: string;
};
export type MoveItemApiResponse = string; // ex: 'OK'
export type MoveItemApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  new_dir_id: string;
  entity_id: string;
  type: string;
  name: string;
};
export type EditFileApiResponse = any; // needs to be typed
export type EditFileApiArg = {
  user_id: string;
  collection_id: string;
  file_id: string;
  file_name: string;
  request_id?: string;
};
export type CheckHandleExePresentApiResponse = string; // "OK"
export type CheckHandleExePresentApiArg = {
  user_id: string;
}
export type PromoteDirectoryApiResponse = string;
export type PromoteDirectoryApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  share_name: string;
};
export type UpgradeCollectionApiResponse = string;
export type UpgradeCollectionApiArg = {
  user_id: string;
  collection_id: string;
};
export type PromotePrecheckApiResponse = {
  can_be_promoted: boolean;
  reason: string;
};
export type PromotePrecheckApiArg = {
  user_id: string;
  directory_id: string;
};
export type ShareApiResponse = string; // ex: 'OK';
export type ShareApiArg = {
  user_id: string;
  share_name: string;
  collection_id: string;
  sharing_list: string[];
  permissions_name: NodePermissionsName;
  expiration?: string;
};
export type ShareV2ApiResponse = string; // ex: 'OK';
export type ShareV2ApiArg = {
  user_id: string;
  share_name: string;
  collection_name: string;
  collection_id: string;
  directory_id: string;
  sharing_list: string[];
  permissions_name: NodePermissionsName;
  expiration?: string;
  request_id?: string;
};
export type GetPendingSharesApiResponse = {
  coll_id: string;
  expiration: string;
  node_id: string;
  node_name: string;
  percentage: number;
  permission: string;
  sharees: string[];
} | null;
export type GetPendingSharesApiArg = {
  user_id: string;
};
export type ListFileVersionsApiResponse = {
  lastModificationDate: string,
  size: number,
  version: string,
}[];
export type ListFileVersionsApiArg = {
  user_id: string;
  collection_id: string;
  file_id: string;
};
export type RestoreFileVersionApiResponse = string; // ex: 'OK'
export type RestoreFileVersionApiArg = {
  user_id: string;
  collection_id: string;
  file_id: string;
  version: string;
};
export type SetLockStateApiResponse = string; // ex: 'OK'
export type SetLockStateApiArg = {
  user_id: string;
  coll_id: string;
  entity_id: string;
  lock_state: boolean;
};
export type GetDownloadFolderIdApiResponse = { id: string };
export type GetDownloadFolderIdApiArg = {
  user_id: string;
};
export type SetSyncStateApiResponse = string; // ex: 'OK'
export type SetSyncStateApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  sync_state: boolean;
  request_id?: string;
};
export type OpenInFileManagerApiResponse = string; // ex: 'OK'
export type OpenInFileManagerApiArg = {
  user_id: string;
  path: string;
};
export type RenameItemApiResponse = string; // ex: 'OK'
export type RenameItemApiArg = {
  user_id: string;
  collection_id: string;
  directory_id: string;
  entity_id: string;
  type: string;
  new_name: string;
  request_id: string;
};
export type DownloadAttachmentApiResponse = string; // ex: 'OK'
export type DownloadAttachmentApiArg = {
  user_id: string;
  unique_id: string;
  reference_id: string;
  name: string;
};
export type GetExportProgressApiResponse = {
  num_done: number;
  total_files: number;
};
export type GetExportProgressApiArg = {
  user_id: string;
  export_id: string;
};
export type BrowseTrashApiResponse = [
  {
    acl: ACLInfo;
    coll_id: string;
    id: string;
    name: string;
    type: DriveEntryTypes;
    parent_id: string;
    deleted_at: number;
    deleted_by: string;
  },
];
export type BrowseTrashApiArg = {
  user_id: string;
};

// Export hooks
export const {
  useBrowseDriveMutation,
  useBrowseDirectoryPaginatedMutation,
  useBrowseDirectoryMutation,
  useBrowseLinkMutation,
  useFindLinkIdMutation,
  useBrowseCollectionMutation,
  useGetUserLogsQuery,
  useGetSharedWithMeMutation,
  useGetAdminLogsQuery,
  useDownloadFileMutation,
  useShareRespondMutation,
  useGetSharedFoldersMutation,
  useRelinkMutation,
  useCreateDirectoryMutation,
  useMoveItemMutation,
  useSelectiveSyncMutation,
  useCheckHandleExePresentMutation,
  useEditFileMutation,
  usePromotePrecheckMutation,
  usePromoteDirectoryMutation,
  useUpgradeCollectionMutation,
  useShareMutation,
  useShareV2Mutation,
  useGetPendingSharesMutation,
  useListFileVersionsMutation,
  useRestoreFileVersionMutation,
  useGetDownloadFolderIdQuery,
  useSetSyncStateMutation,
  useSetLockStateMutation,
  useOpenInFileManagerMutation,
  useGetExportProgressMutation,
  useRenameItemMutation,
  useDownloadAttachmentMutation
} = injectedRtkApi;
