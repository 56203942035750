import React, { FocusEvent, FormEventHandler, Dispatch, SetStateAction } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Icon } from "src/components";

type AllProps = {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  new_user: string;
  setNewUser: Dispatch<SetStateAction<string>>;
};

/* Description: Form for adding users to the editing list for recovery group. */
function AddUserFormComponent(props: AllProps) {
  const { handleSubmit, setNewUser, new_user } = props;

  return (
    <Form onSubmit={handleSubmit} className="d-flex p-2">
      <Form.Group as={Col} xs="7" className="p-0 me-2">
        <Form.Control
          id="new_user_tb"
          type="text"
          name="new_user"
          value={new_user}
          placeholder="Enter contact name or email"
          onChange={(e: FocusEvent<HTMLInputElement>) => setNewUser(e.currentTarget.value)}
          aria-label="Add Recovery Group User"
        />
      </Form.Group>
      <Button variant="outline-secondary" className="btn-icon" type="submit">
        <Icon className="pv-icon-add" />
      </Button>
    </Form>
  );
}

export default React.memo(AddUserFormComponent);
