import React, { useState, useRef } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { CreateAccountUIActionTypes, usePostUsersVerifyMutation, PostUsersVerifyApiResponse, GlobalConstants } from "src/common";
import { Loading, CountdownDisplay, VerificationCode } from "src/components";

type AllProps = {
  user_id: string;
  expires: string;
  handleAction: ActionHandlerFunction;
}

function VerificationFormComponent(props: AllProps) {
  const { user_id, expires, handleAction } = props;
  const [expired, setExpired] = useState<boolean>(false);
  const [verifyUser, { isLoading }] = usePostUsersVerifyMutation();
  const formRef = useRef<HTMLFormElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  let secret = "";

  // Description: Handle form submit
  async function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    await verifyUser({ secret, user_id }).unwrap()
      .then((params: PostUsersVerifyApiResponse) => {
        handleAction({
          actionType: CreateAccountUIActionTypes.SubmitVerificationCode, params: Object.assign({}, params, { secret, user_id })
        });
      })
      .catch((msg) => {
        handleAction({
          actionType: CreateAccountUIActionTypes.SubmitVerificationCodeError,
          params: msg.status
        });
      });

    if (!!formRef.current) {
      formRef.current.reset();
    };
  }

  // Description: Validate and set secret code on finished
  function handleSetSecretCode(code: string) {
    secret = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  // Description: Expiration Message Panel
  function VerificationCodeExpired() {
    return <>
      <p>
        The verification code that was sent to <b>{user_id}</b> has expired. Please click <b>Back</b> to restart the process.
      </p>
      <div className="btn-panel">
        <Button variant="outline-secondary" type="button"
          onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Back</Button>
      </div>
    </>;
  }

  // Description: Render the enter code
  function VerificationForm() {
    return <>
      <h3 className="border-bottom">Check your mail inbox!</h3>
      <p>We sent <b>{user_id}</b> an email with the verification code.</p>
      <p>Please enter it below.</p>
      <p>It will expire in <b><CountdownDisplay expires={expires} handleTimeExpired={() => setExpired(true)} /></b>.</p>
      <Form onSubmit={handleSubmit} ref={formRef} noValidate >
        <Form.Group className="mb-4 col-sm-10">
          <Form.Label className="fs-6">Verification Code</Form.Label>
          <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleSetSecretCode} />
          <p className="disclaimer">Please check your spam folder if you cannot find this email in your inbox.</p>
        </Form.Group>
        <div className="btn-panel">
          <Button ref={buttonRef} type="submit" disabled={true} >Verify</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Back</Button>
        </div>
      </Form>
    </>;
  }

  return !isLoading ?
    <>
      <Card.Body>
        {
          expired ? <VerificationCodeExpired /> : <VerificationForm />
        }
      </Card.Body>
    </> : <Loading className="in-place" />;
}

export default React.memo(VerificationFormComponent);
