import React, { useState, ReactNode, MouseEvent, FocusEvent } from "react";
import { Collapse } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  MailTreeIcon, UIManagerActionTypes, useAppSelector, CheckboxStatesTypes, CheckboxStates, FolderSyncIcon, SELECTIVE_SYNC_STATES,
  TreeItem, getEntityIcon, DriveEntryType
} from "src/common";
import { Checkbox, Icon, Loading } from "src/components";

type AllProps = {
  folder: TreeItem;
  children: ReactNode;
  active?: boolean;
  fetching_children?: boolean;
  drive?: boolean;
  handleAction: ActionHandlerFunction;
};

const SYNC_STATUS_MAP = {
  [SELECTIVE_SYNC_STATES.OFF]: CheckboxStates.empty,
  [SELECTIVE_SYNC_STATES.ON]: CheckboxStates.checked,
  [SELECTIVE_SYNC_STATES.PARTIAL]: CheckboxStates.indeterminate,
  [SELECTIVE_SYNC_STATES.Pending]: CheckboxStates.empty,
  [SELECTIVE_SYNC_STATES.InheritedON]: CheckboxStates.checked,
  [SELECTIVE_SYNC_STATES.InheritedOFF]: CheckboxStates.empty,
};

const SELECTIVE_SYNC_LABELS = {
  [SELECTIVE_SYNC_STATES.OFF]: "Folder Not Synced",
  [SELECTIVE_SYNC_STATES.ON]: "Folder Synced",
  [SELECTIVE_SYNC_STATES.PARTIAL]: "Folder Partially Synced",
  [SELECTIVE_SYNC_STATES.Pending]: "Sync Job in Progress",
  [SELECTIVE_SYNC_STATES.InheritedON]: "Folder Synced",
  [SELECTIVE_SYNC_STATES.InheritedOFF]: "Folder Not Synced",
};

function CheckboxTreeFolder(props: AllProps) {
  const { folder, active, children, handleAction, fetching_children, drive } = props;
  const expanded = useAppSelector((state) => state.mail.expanded);
  const [open, setOpen] = useState<boolean>(expanded.includes(folder?.id || "") || false);

  // Description: Handles click to icon
  function handleOnClickFolder(e: MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    e.preventDefault();
    if (!!folder.children) {
      setOpen(!open);
      !drive && handleAction({ actionType: UIManagerActionTypes.TreeNodeActiveState, params: { id: folder.id, expanded: !open } });
    } else if (folder.children_loading) {
      setOpen(!open);
      handleAction({ actionType: UIManagerActionTypes.FetchTreeNodeChildren, params: folder });
    }
  }

  function getSelectedState(): CheckboxStatesTypes {
    return SYNC_STATUS_MAP[folder.should_sync as SELECTIVE_SYNC_STATES];
  }

  function onCheckboxChange(folder: TreeItem, sync: boolean) {
    handleAction({ actionType: UIManagerActionTypes.SyncCheckboxChange, params: { folder, sync } });
  }

  return <div className={`folder-item${folder.disabled ? " disabled" : ""}`}>
    <div id={folder.id}
      className={`folder-label ${folder.active || active ? " active" : ""}`}>

      <span className="item-row">
        <span className={`folder-icon${!folder.children && !drive ? " no-children" : ""}`}
          onClick={handleOnClickFolder}>
          <Icon className={
            (!folder.children && !folder.children_loading && !!drive) ? "sm-icon-placeholder" :
              (open ? MailTreeIcon.opened : MailTreeIcon.closed)} />
        </span>

        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => { onCheckboxChange(folder, e.target.checked); }}
          value={getSelectedState()}
          label="Select All"
          selected={getSelectedState()}
        />

        <Icon
          className={`${getEntityIcon(!!folder.linked_collection_id ? DriveEntryType.LINK : DriveEntryType.DIR, folder.current_sync_status)}`}
          tooltip={SELECTIVE_SYNC_LABELS[folder.current_sync_status || SELECTIVE_SYNC_STATES.OFF]}
        />

        <span className={`folder-icon${!folder.children && !drive ? " no-children" : ""}`}
          onClick={handleOnClickFolder}>
          {!(folder.current_sync_status && !!drive) && <Icon className={FolderSyncIcon.open} />}
        </span>
      </span>

      <span className="folder-name" onClick={(e) => {
        setOpen(!open);
        handleAction({ actionType: UIManagerActionTypes.FetchTreeNodeChildren, params: folder });
      }}>{folder.name}</span>

    </div>
    {folder.children_loading && open && fetching_children && <Loading className="in-place" noLogo={true} />}
    {
      !!folder.children &&
      <Collapse in={open} className="folder-children">
        <div>
          {children}
        </div>
      </Collapse>
    }
  </div >;
}

export default React.memo(CheckboxTreeFolder);
